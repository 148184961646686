import React, { useEffect, useState } from "react";
import { Button, Spin, notification, Tabs } from "antd";
import Main from "../components/layout/Main";
import {
  getAssignmentByIdForClient,
  getAssignmentRequestsForClient,
  getAssignmentResponseById,
} from "../Network/MyAssignments";
import { useDispatch, useSelector } from "react-redux";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const { TabPane } = Tabs;

const MyAssignments = () => {
  const nav = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [assignments, setAssignments] = useState({
    new: [],
    inprogress: [],
    submitted: [],
  });
  const [activeKey, setActiveKey] = useState("new");
  const authorization = useSelector(
    (state) => state.combined.auth.authorization
  );

  const addNotification = (type, message) => {
    notification.open({
      message: type === "success" ? "Success" : "Failure",
      description: message,
      icon:
        type === "success" ? (
          <CheckCircleOutlined style={{ color: "#52c41a" }} />
        ) : (
          <CloseCircleOutlined style={{ color: "#f5222d" }} />
        ),
      duration: 3,
    });
  };

  const fetchAssignments = async () => {
    setIsLoading(true);
    try {
      const res = await getAssignmentRequestsForClient(authorization);
      const newAssignmentIds = [];
      const inProgressAssignmentIds = [];
      const submittedAssignmentIds = [];

      res.forEach((assignment) => {
        switch (assignment.status) {
          case "new":
            newAssignmentIds.push(assignment.assignmentId);
            break;
          case "inProgress":
            inProgressAssignmentIds.push(assignment.assignmentId);
            break;
          case "submitted":
            submittedAssignmentIds.push(assignment.assignmentId);
            break;
          default:
            break;
        }
      });

      const fetchAssignmentContent = async (assignmentIds) => {
        return Promise.all(
          assignmentIds.map(async (assignmentId) => {
            return await getAssignmentByIdForClient(
              authorization,
              assignmentId
            );
          })
        );
      };

      
      const fetchFeedbackForSubmittedAssignments = async (
        submittedAssignments
      ) => {
        return Promise.all(
          submittedAssignments.map(async (assignment) => {
            const response = await getAssignmentResponseById(
              authorization,
              assignment._id
            );
            return {
              ...assignment,
              feedback: response?.feedback,
            };
          })
        );
      };

      const [newAssignments, inProgressAssignments, submittedAssignments] =
        await Promise.all([
          fetchAssignmentContent(newAssignmentIds),
          fetchAssignmentContent(inProgressAssignmentIds),
          fetchAssignmentContent(submittedAssignmentIds),
        ]);

      const submittedAssignmentsWithFeedback =
        await fetchFeedbackForSubmittedAssignments(submittedAssignments);

      setAssignments({
        new: newAssignments,
        inprogress: inProgressAssignments,
        submitted: submittedAssignmentsWithFeedback.map((assignment) => ({
          ...assignment,
          reviewStatus: res.find((a) => a.assignmentId === assignment._id)
            ?.reviewStatus,
        })),
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAssignments();
  }, []);

  const FeedbackWithReadMore = ({ feedback }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const charLimit = 100; 

    const toggleExpanded = () => setIsExpanded(!isExpanded);

    return (
      <div className="text-md">
        <b>Feedback: </b>
        <span className="text-lg">
          {isExpanded ? feedback : `${feedback.slice(0, charLimit)}`}
        </span>
        {feedback.length > charLimit && (
          <button
            onClick={toggleExpanded}
            className="text-blue-500 ml-2 underline"
          >
            {isExpanded ? "Show Less" : "Read More"}
          </button>
        )}
      </div>
    );
  };
  return (
    <Main>
      {isLoading ? (
        <div className="h-full content-center">
          <Spin tip="Loading" size="large">
            <div style={{ borderRadius: 4 }} />
          </Spin>
        </div>
      ) : (
        <div className="layout-content flex w-96 md:w-9/12 h-152 m-auto relative overflow-x-hidden bg-white shadow-lg rounded-xl p-8">
          <div className="flex flex-col items-start gap-6 w-full">
            <div className="flex">
              <span className="text-black items-center font-semibold text-4xl mr-6">
                My Assignments
              </span>
            </div>

            <Tabs
              activeKey={activeKey}
              onChange={setActiveKey}
              className="w-full"
            >
              <TabPane tab="New" key="new">
                {assignments.new?.length > 0 ? (
                  assignments.new.map((assignment) => (
                    <div
                      className="w-full max-w-screen-lg mx-auto p-4 bg-white shadow-lg rounded-lg mb-4 flex justify-between"
                      key={assignment._id}
                    >
                      <div>
                        <div className="text-md">
                          <b>Title: </b>
                          <span className="text-lg">{assignment.title}</span>
                        </div>
                        <div className="text-md">
                          <b>Description: </b>
                          <span className="text-lg">
                            {assignment.description}
                          </span>
                        </div>
                      </div>
                      <Button
                        type="primary"
                        onClick={() => {
                          nav(`/viewAssignment/${assignment._id}`);
                        }}
                      >
                        View Assignment
                      </Button>
                    </div>
                  ))
                ) : (
                  <div className="text-center text-gray-500">
                    No new assignments available.
                  </div>
                )}
              </TabPane>
              <TabPane tab="In Progress" key="inprogress">
                {assignments.inprogress?.length > 0 ? (
                  assignments.inprogress.map((assignment) => (
                    <div
                      className="w-full max-w-screen-lg mx-auto p-4 bg-white shadow-lg rounded-lg mb-4 flex justify-between"
                      key={assignment._id}
                    >
                      <div>
                        <div className="text-md">
                          <b>Title: </b>
                          <span className="text-lg">{assignment.title}</span>
                        </div>
                        <div className="text-md">
                          <b>Description: </b>
                          <span className="text-lg">
                            {assignment.description}
                          </span>
                        </div>
                      </div>
                      <Button
                        type="primary"
                        onClick={() => {
                          nav(`/viewAssignment/${assignment._id}`);
                        }}
                      >
                        View Progress
                      </Button>
                    </div>
                  ))
                ) : (
                  <div className="text-center text-gray-500">
                    No in-progress assignments available.
                  </div>
                )}
              </TabPane>

              <TabPane tab="Submitted" key="submitted">
                {assignments.submitted?.length > 0 ? (
                  assignments.submitted.map((assignment) => (
                    <div
                      className="w-full max-w-screen-lg mx-auto p-4 bg-white shadow-lg rounded-lg mb-4 flex justify-between"
                      key={assignment._id}
                    >
                      <div
                        className="cursor-pointer"
                        onClick={() => {
                          nav(`/viewSubmittedAssignment/${assignment._id}`);
                        }}
                      >
                        <div className="text-md">
                          <b>Title: </b>
                          <span className="text-lg">{assignment.title}</span>
                        </div>
                        <div className="text-md">
                          <b>Description: </b>
                          <span className="text-lg">
                            {assignment.description}
                          </span>
                        </div>

                        <div className="text-md">
                          <b>Review Status: </b>
                          <span className="text-lg ">
                            {assignment.reviewStatus == "reviewed"
                              ? "Reviewed"
                              : "Pending"}
                          </span>
                        </div>
                        {assignment.feedback && (
                          <FeedbackWithReadMore
                            feedback={assignment.feedback}
                          />
                        )}
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="text-center text-gray-500">
                    No submitted assignments available.
                  </div>
                )}
              </TabPane>
            </Tabs>
          </div>
        </div>
      )}
    </Main>
  );
};

export default MyAssignments;
