import { clientCookieName } from '../constants';
import Cookies from 'js-cookie';
import { setAuthorization } from '../redux/combinedSlice';
import { handleApiErrors } from '../ErrorHandling/handleApiErrors';
const logout = async (authorization, dispatch, nav) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_HOST}/api/auth/logout`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${authorization}`,
      },
    }
  );

  if (!response.ok) {
    throw new Error('Logout failed');
  }

  Cookies.remove(clientCookieName, { path: '/' });
  dispatch(setAuthorization(null));
  nav('/login');
};

const genOtp = async (mobileNumber, userType) => {
  const phoneNumber = Number(mobileNumber);

  if (isNaN(phoneNumber)) {
    throw new Error('Invalid phone number provided');
  }

  const response = await fetch(
    `${process.env.REACT_APP_API_HOST}/api/auth/generateOTPForSignup`,
    {
      credentials: 'include',
      method: 'POST',
      withCredentials: true,
      body: JSON.stringify({
        mobileNumber: phoneNumber,
        userType: userType,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  const res = await response.json();
  if (!response.ok) {
    await handleApiErrors(response);
  }
  return res;
};

const loginWithOtp = async (mobileNumber, userType) => {
  const phoneNumber = Number(mobileNumber);

  if (isNaN(phoneNumber)) {
    throw new Error('Invalid phone number provided');
  }

  const response = await fetch(
    `${process.env.REACT_APP_API_HOST}/api/auth/generateOTP`,
    {
      credentials: 'include',
      method: 'POST',
      withCredentials: true,
      body: JSON.stringify({
        mobileNumber: phoneNumber,
        userType: userType,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
  const res = await response.json();
  if (!response.ok) {
    await handleApiErrors(response);
  }
  return res;
};

const verifyOtp = async (mobileNumber, userType, firstName, lastName, otp) => {
  const phoneNumber = Number(mobileNumber);
  if (isNaN(phoneNumber)) {
    throw new Error('Invalid phone number provided');
  }

  const response = await fetch(
    `${process.env.REACT_APP_API_HOST}/api/auth/signUpWithOTP`,
    {
      credentials: 'include',
      method: 'POST',
      body: JSON.stringify({
        mobileNumber: phoneNumber,
        userType: userType,
        givenName: firstName,
        familyName: lastName,
        otp: otp,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  if (!response.ok) {
    await handleApiErrors(response);
  }
  return await response.json();
};

const verifyLoginOtp = async (otp, mobileNumber, userType) => {
  const phoneNumber = Number(mobileNumber);
  if (isNaN(phoneNumber)) {
    throw new Error('Invalid phone number provided');
  }

  const response = await fetch(
    `${process.env.REACT_APP_API_HOST}/api/auth/verifyOTPForLogin`,
    {
      credentials: 'include',
      method: 'POST',
      body: JSON.stringify({
        otp: otp,
        mobileNumber: phoneNumber,
        userType: userType,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
  const res = await response.json();

  if (!response.ok) {
    await handleApiErrors(res);
  }

  return res;
};

export { logout, loginWithOtp, genOtp, verifyLoginOtp, verifyOtp };
