import React, { useEffect, useState } from "react";
import Main from "../components/layout/Main";
import Calendar from "../components/Calendar";
import { useNavigate, useParams } from "react-router-dom";
import { CaretLeftOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Spin } from "antd";
import { fetchTherapistByHandle, fetchTherapistById } from "../Network/Therapist";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserDetails } from "../Network/Client";
import { handleError } from "../ErrorHandling/handleError";

function TherapistDetails() {
  const nav = useNavigate();
  const dispatch = useDispatch();
  let { therapistId } = useParams();
  const [isLoading, setisLoading] = useState(true);
  const [therapistDetails, setTherapistDetails] = useState({});
  const [userDetails, setUserDetails] = useState();
  const authorization = useSelector(
    (state) => state?.combined?.auth?.authorization
  );

  // const fetchtherapist = async () => {
  //   try {
  //     setisLoading(true);
  //     const therapist = await fetchTherapistById(therapistId);
  //     setTherapistDetails(therapist);
  //   } catch (error) {
  //     setisLoading(false);
  //     await handleError(error, nav, dispatch);
  //   } finally {
  //     setisLoading(false);
  //   }
  // };
  const fetchtherapist = async () => {
    try {
      let therapist;
      therapist = await fetchTherapistByHandle(therapistId);
      setTherapistDetails(therapist);
    } catch (error) {
      await handleError(error, nav, dispatch);
    } finally {
      setisLoading(false);
    }
  };

  const fetchUser = async () => {
    try {
      const allDetails = await fetchUserDetails(authorization);
      setUserDetails(allDetails);
    } catch (error) {
      await handleError(error, nav, dispatch);
    }
  };

  useEffect(() => {
    fetchtherapist();

    if (authorization) {
      fetchUser();
    }
  }, [authorization, therapistId]);

  return (
    <Main>
      {isLoading ? (
        <div className="h-full content-center">
          <Spin tip="Loading" size="large">
            <div
              style={{
                borderRadius: 4,
              }}
            />
          </Spin>
        </div>
      ) : (
        <div className="md:grid grid-cols-2 gap-4">
          <div className=" p-6 bg-white rounded-xl h-152 overflow-scroll">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <div className="flex justify-center md:justify-start">
                {therapistDetails?.profilePictureUrl?.s3Url ? (
                  <img
                    className="w-40 h-40 rounded-2xl object-cover"
                    src={therapistDetails?.profilePictureUrl?.s3Url}
                    alt="Therapist"
                  ></img>
                ) : (
                  <Avatar size={150} shape="square" icon={<UserOutlined />} />
                )}
              </div>
              <div className="col-span-2 flex flex-col justify-center">
                <div className="text-3xl font-bold">
                  {therapistDetails?.givenName || ''}
                  {' '}
                  {therapistDetails?.familyName || ''}
                </div>
                <div className="text-sm font-light text-gray-700">
                  {therapistDetails?.experience ? `${therapistDetails.experience} Years Experience` : ''}
                </div>
                <div className="text-gray-600 font-bold">
                  {therapistDetails?.degree || ''}
                </div>
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-6">
              <div>
                <div className="text-gray-600 font-bold">Business Name:</div>
                <div className="text-lg text-gray-600">
                  {therapistDetails?.businessName || ''}
                </div>
              </div>
              <div>
                <div className="text-gray-600 font-bold">Specialty:</div>
                <div className="text-lg text-gray-600">
                  {therapistDetails?.specialty || ''}
                </div>
              </div>
              <div>
                <div className="text-gray-600 font-bold">Languages:</div>
                <div className="text-lg text-gray-600">
                  {therapistDetails?.languages?.join(", ") || ''}
                </div>
              </div>
            </div>
            <div className="mt-6 text-gray-600 font-bold">
              Profile Description:
            </div>
            <div className="text-justify text-lg text-gray-600">
              {therapistDetails?.profileDescription || ''}
            </div>
            <div className="mt-6 text-gray-600 font-bold">Profile Summary:</div>
            <div className="text-justify text-lg text-gray-600">
              {therapistDetails?.profileSummary || ''}
            </div>
          </div>
          <div className="relative mt-6 lg:mt-0 bg-white rounded-xl w-90 md:w-116 h-144 md:h-152 m-auto ">
            <Calendar
              therapistId={therapistDetails._id}
              userDetails={userDetails}
              therapistName={`${therapistDetails?.givenName} ${therapistDetails?.familyName}`}
            />
          </div>
        </div>
      )}
    </Main>
  );
}

export default TherapistDetails;
