import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import googleLogo from '../assets/images/googleLogo.png';
import verified from '../assets/images/verified.png';
import { notification } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import { setAuthorization } from '../redux/combinedSlice';
import {
  genOtp,
  loginWithOtp,
  verifyLoginOtp,
  verifyOtp,
} from '../Network/Auth';
import { handleError } from '../ErrorHandling/handleError';
import moment from 'moment';

const Login = () => {
  const nav = useNavigate();
  const dispatch = useDispatch();
  const [loginPhoneNumber, setLoginPhoneNumber] = useState('');
  const [loginOtp, setLoginOtp] = useState('');
  const [loginPhoneError, setLoginPhoneError] = useState('');
  const [isLoginPhoneVerified, setIsLoginPhoneVerified] = useState(false);
  const [otpSentLogin, setOtpSentLogin] = useState(false);
  const [isResendEnabledLogin, setIsResendEnabledLogin] = useState(false);
  const [loginTimer, setLoginTimer] = useState(60);

  useEffect(() => {
    let countdown;
    if (otpSentLogin && loginTimer > 0) {
      countdown = setInterval(() => {
        setLoginTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (loginTimer === 0) {
      setIsResendEnabledLogin(true);
      clearInterval(countdown);
    }
    return () => clearInterval(countdown);
  }, [otpSentLogin, loginTimer]);

  const addNotification = (type, message) => {
    notification.open({
      message: type === 'success' ? 'Success' : 'Failure',
      description: message,
      icon:
        type === 'success' ? (
          <CheckCircleOutlined style={{ color: '#52c41a' }} />
        ) : (
          <CloseCircleOutlined style={{ color: '#f5222d' }} />
        ),
      duration: 3,
    });
  };

  const setCookie = (name, value, expires, path = '/', sameSite = 'Lax') => {
    document.cookie = `${name}=${encodeURIComponent(
      value
    )}; expires=${moment(expires)}; path=${path}; SameSite=${sameSite}`;
  };

  const handleVerificationSuccess = (response) => {
    if (response.success) {
      setCookie(
        response.cookieName,
        response.authToken,
        response.cookieDetails.expires,
        response.cookieDetails.path,
        response.cookieDetails.sameSite
      );
      dispatch(setAuthorization(response.authToken));
      nav('/search');
    }
  };

  const validateFields = () => {
    let isValid = true;

    if (!loginPhoneNumber.trim()) {
      setLoginPhoneError('Phone number is required');
      isValid = false;
    } else {
      setLoginPhoneError('');
    }

    return isValid;
  };

  const handleSendLoginOtp = async () => {
    try {
      if (validateFields()) {
        const res = await loginWithOtp(loginPhoneNumber, 'client');
        setOtpSentLogin(true);
        addNotification('success', 'OTP Sent to your mobile number');
      }
    } catch (err) {
      handleError(err, nav, dispatch);
    }
  };

  const handleResendLoginOtp = async () => {
    try {
      setOtpSentLogin(true);
      setIsResendEnabledLogin(false);
      setLoginTimer(60);
      if (validateFields()) {
        const res = await loginWithOtp(loginPhoneNumber, 'client');
        setOtpSentLogin(true);
        addNotification('success', 'OTP Sent to your mobile number');
      }
    } catch (err) {
      handleError(err, nav, dispatch);
    }
  };

  const handleVerifyLoginOtp = async () => {
    try {
      const res = await verifyLoginOtp(loginOtp, loginPhoneNumber, 'client');
      handleVerificationSuccess(res);
      setIsLoginPhoneVerified(true);
      addNotification('success', 'Logged in successfully');
    } catch (err) {
      handleError(err, nav, dispatch);
    }
  };
  return (
    <div className='self-center px-0 md:px-4 m-0 md:mt-0 flex flex-col justify-center items-center w-full'>
      <div className='md:w-8/12 w-full justify-center items-center'>
        <div className='flex flex-col  justify-center items-center'>
          <label
            htmlFor='loginPhone'
            className='libre-baskerville-bold mt-4 mb-3 block text-sm font-medium text-gray-700'
          >
            Enter Your Mobile Number
          </label>
          <PhoneInput
            country={'in'}
            className='phone-input'
            value={loginPhoneNumber}
            onChange={(value) => setLoginPhoneNumber(value)}
            disabled={isLoginPhoneVerified}
          />
          {loginPhoneError && (
            <p className='errors mt-1 text-xs'>{loginPhoneError}</p>
          )}

          {isLoginPhoneVerified && (
            <p className='mt-3 flex libre-baskerville-bold items-center gap-2 text-green-600 text-[12px] font-medium'>
              <span>
                <img className='h-4' src={verified} alt='Verified' />
              </span>
              Phone number verified successfully!
            </p>
          )}

          {!otpSentLogin && !isLoginPhoneVerified && (
            <button
              onClick={handleSendLoginOtp}
              className='transition-all w-full libre-baskerville-bold duration-300 mt-3  rounded-lg bg-green-700 text-white py-2 px-4 text-sm hover:bg-blue-700'
            >
              Continue
            </button>
          )}

          {otpSentLogin && !isLoginPhoneVerified && (
            <>
              <label
                htmlFor='loginOtp'
                className='block text-sm libre-baskerville-regular   font-medium text-gray-700 mt-4'
              >
                Enter OTP
              </label>
              <input
                type='text'
                id='loginOtp'
                value={loginOtp}
                onChange={(e) => setLoginOtp(e.target.value)}
                className='mt-3 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none sm:text-sm'
                placeholder='Enter OTP'
              />
              <button
                onClick={handleVerifyLoginOtp}
                className='transition-all  duration-300 mt-4 w-full rounded-lg bg-green-600 text-white py-2 px-4 text-sm font-semibold hover:bg-green-500'
              >
                Verify OTP
              </button>
              {isResendEnabledLogin && (
                <button
                  onClick={handleResendLoginOtp}
                  className='transition-all  duration-300 mt-3 w-full rounded-lg bg-blue-900 text-white py-2 px-4 text-sm font-semibold hover:bg-blue-700'
                >
                  Resend OTP
                </button>
              )}
              <div className='text-center  text-sm mt-4'>
                <p>Resend OTP in: {loginTimer} seconds</p>
              </div>
            </>
          )}

          <div className='mt-4 w-full flex flex-col gap-3 text-center'>
            <div className='flex gap-2 items-center justify-center'>
              <div className='flex-1'>
                <hr className='border-gray-300 w-full' />
              </div>
              <span className='px-2 text-gray-500'>Or</span>
              <div className='flex-1'>
                <hr className='border-gray-300 w-full' />
              </div>
            </div>
            <a
              href={`${process.env.REACT_APP_API_HOST}/login/google/client`}
              className='transition-all  duration-300 w-full text-black flex justify-center items-center bg-white hover:text-black  text-black py-2 px-4 rounded-lg hover:border-black'
            >
              <img src={googleLogo} alt='Google Icon' className='h-5 mr-2' />
              Login with Google
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
