import React, { useState } from 'react';
import { EditOutlined, SaveOutlined, CloseOutlined } from '@ant-design/icons';
import PhoneInput from 'react-phone-input-2';
import { Input, Select, Tag } from 'antd';

function ProfileDetails({
  authorization,
  handleModalVisibility,
  userDetails,
  updatedDetails,
  setUpdatedDetails,
  isProfileEditing,
  setisProfileEditing,
  handleProfileUpdate,
}) {
  const [errors, setErrors] = useState({});

  const validate = () => {
    const newErrors = {};

    if (!updatedDetails?.email) {
      newErrors.email = 'Required';
    }
    if (!updatedDetails?.givenName) {
      newErrors.givenName = 'Required';
    }
    if (!updatedDetails?.gender) {
      newErrors.gender = 'Required';
    }
    if (!updatedDetails?.dateOfBirth) {
      newErrors.dateOfBirth = 'Required';
    }
    if (!updatedDetails?.currentAddress?.addressLine1) {
      newErrors.currentAddress = newErrors.currentAddress || {};
      newErrors.currentAddress.addressLine1 = 'Required';
    }
    if (!updatedDetails?.currentAddress?.pincode) {
      newErrors.currentAddress = newErrors.currentAddress || {};
      newErrors.currentAddress.pincode = 'Required';
    }
    if (!updatedDetails?.currentAddress?.city) {
      newErrors.currentAddress = newErrors.currentAddress || {};
      newErrors.currentAddress.city = 'Required';
    }
    if (!updatedDetails?.currentAddress?.state) {
      newErrors.currentAddress = newErrors.currentAddress || {};
      newErrors.currentAddress.state = 'Required';
    }
    if (!updatedDetails?.currentAddress?.country) {
      newErrors.currentAddress = newErrors.currentAddress || {};
      newErrors.currentAddress.country = 'Required';
    }
    if (!updatedDetails?.phoneNumber?.phone) {
      newErrors.phoneNumber = 'Required';
    }
    if (!updatedDetails?.emergencyContactDetails?.phoneNumber?.phone) {
      newErrors.phoneNumber = 'Required';
    }
    if (!updatedDetails?.emergencyContactDetails?.relationship) {
      newErrors.relationship = 'Required';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = () => {
    if (validate()) {
      handleProfileUpdate();
    }
  };
  const [inputPronoun, setInputPronoun] = useState();

  const maritalStatus = ['married', 'single', 'divorced', 'widowed', 'others'];
  const gender = ['Male', 'Female', 'LGBTQIA+'];

  const handleAddPronoun = () => {
    setUpdatedDetails((prevDetails) => {
      return {
        ...prevDetails,
        pronouns: [...prevDetails.pronouns, inputPronoun],
      };
    });
  };

  return (
    <div>
      <div className='flex justify-between mt-4'>
        <h6 className='font-black text-xl text-gray-700'>
          Personal Information
        </h6>
        <div className='flex justify-end flex-wrap gap-4'>
          {isProfileEditing ? (
            <>
              <button
                type='primary'
                className='flex items-center text-sm border border-gray-300 px-4 py-2 rounded-md bg-blue-500 text-white mx-2 hover:bg-blue-600'
                onClick={handleSave}
              >
                <SaveOutlined className='mr-2' /> Save
              </button>
              <button
                className='flex items-center text-sm border border-gray-300 px-4 py-2 rounded-md bg-red-500 text-white mx-2 hover:bg-red-600'
                onClick={() => {
                  setisProfileEditing(false);
                  setUpdatedDetails(userDetails);
                }}
              >
                <CloseOutlined className='mr-2' />
                Cancel
              </button>
            </>
          ) : (
            <button
              className='flex items-center text-sm border border-gray-300 px-4 py-2 rounded-md bg-blue-500 text-white hover:bg-blue-600'
              onClick={() => {
                setisProfileEditing(true);
              }}
            >
              <EditOutlined className='mr-2' /> Edit
            </button>
          )}
        </div>
      </div>
      <div className='profile-details'>
        <div className='flex items-center flex-wrap gap-4'>
          <div className='profile-field mt-2'>
            {isProfileEditing ? (
              <div className='flex'>
                <span>
                  <label className='text-gray-600 font-bold'>First Name</label>
                  <input
                    style={{ width: '98%' }}
                    className=' mt-1 p-2 border border-gray-300 rounded-md'
                    defaultValue={updatedDetails?.givenName}
                    onChange={(e) => {
                      setUpdatedDetails((prevDetails) => {
                        return {
                          ...prevDetails,
                          givenName: e.target.value,
                        };
                      });
                    }}
                  />
                  {errors.givenName && (
                    <p className='text-red-500'>{errors.givenName}</p>
                  )}
                </span>
                <span>
                  <label className='text-gray-600 font-bold'>Family Name</label>
                  <input
                    style={{ width: '98%' }}
                    className=' mt-1 p-2 border border-gray-300 rounded-md'
                    defaultValue={updatedDetails?.familyName}
                    onChange={(e) => {
                      setUpdatedDetails((prevDetails) => {
                        return {
                          ...prevDetails,
                          familyName: e.target.value,
                        };
                      });
                    }}
                  />
                </span>
              </div>
            ) : updatedDetails?.givenName ? (
              <>
                <label className='text-gray-600 font-bold'>Name</label>
                <p className='text-lg text-gray-600'>
                  {updatedDetails?.givenName} {updatedDetails?.familyName}
                </p>
              </>
            ) : (
              <>
                <label className='text-gray-600 font-bold'>Name</label>
                <p className='text-gray-300'>Fill out this section</p>
              </>
            )}
          </div>

          {isProfileEditing ? (
            <span className='flex my-2 flex-col items-start'>
              <label className='text-gray-600 font-bold'>Current Email</label>
              {updatedDetails?.email ? (
                <span className='mt-3 cursor-not-allowed p-2 bg-gray-100 rounded-lg border-gray-300 border-[1px] '>
                  {updatedDetails.email}{' '}
                </span>
              ) : (
                <a
                  href={`${
                    process.env.REACT_APP_API_HOST
                  }/sync/google/client?authToken=${encodeURIComponent(
                    String(authorization)
                  )}`}
                  className='text-left mt-2 self-center mx-auto hover:text-white rounded-lg bg-blue-900 text-white py-3 px-6 text-sm'
                >
                  Add Email
                </a>
              )}
            </span>
          ) : updatedDetails?.email ? (
            <>
              <div className='flex flex-col justify-center my-4'>
                <label className='text-gray-600 font-bold'>Current Email</label>
                <p className='text-lg mt-1 text-gray-600'>
                  {updatedDetails?.email}
                </p>
              </div>
            </>
          ) : (
            <span className='flex flex-col gap-1'>
              <label className='text-gray-600 font-bold'>Email</label>
              <p className='text-gray-300'>Fill out this section</p>
            </span>
          )}
        </div>

        <div className='flex flex-wrap justify-start gap-8 items-center '>
          <div className='profile-field mt-4'>
            {isProfileEditing ? (
              <span>
                <label className='text-gray-600 font-bold mb-1 block'>
                  Gender
                </label>
                <Select
                  showSearch
                  style={{ width: 150 }}
                  defaultValue={updatedDetails?.gender}
                  onChange={(e) => {
                    setUpdatedDetails((prevDetails) => {
                      return {
                        ...prevDetails,
                        gender: e,
                      };
                    });
                  }}
                >
                  {errors.gender && (
                    <p className='text-red-500'>{errors.gender}</p>
                  )}
                  {gender.map((status) => {
                    return (
                      <Select.Option key={status} value={status}>
                        {status}
                      </Select.Option>
                    );
                  })}
                </Select>
              </span>
            ) : updatedDetails?.gender ? (
              <>
                <label className='text-gray-600 font-bold'>Gender</label>
                <p className='text-lg text-gray-600'>
                  {updatedDetails?.gender}
                </p>
              </>
            ) : (
              <>
                <label className='text-gray-600 font-bold'>Gender</label>
                <p className='text-gray-300'>Fill out this section</p>
              </>
            )}
          </div>
          <div className='profile-field flex col mt-8'>
            {isProfileEditing ? (
              <span>
                <label className='text-gray-600 font-bold'>Pronuouns</label>
                <div className='mt-2 h-8 flex'>
                  <Input
                    className='!h-9 !rounded-r-none'
                    onChange={(e) => setInputPronoun(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' && e.target.value.trim()) {
                        handleAddPronoun();
                      }
                    }}
                    placeholder='Enter values'
                  />
                  <button
                    className='flex w-24 ml-2 items-center justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-9 w-12'
                    onClick={handleAddPronoun}
                  >
                    Add
                  </button>
                </div>
                <div style={{ marginTop: '16px' }}>
                  {updatedDetails?.pronouns.map((value, index) => (
                    <Tag
                      key={index}
                      closable
                      onClose={() =>
                        setUpdatedDetails((prevDetails) => {
                          return {
                            ...prevDetails,
                            pronouns: prevDetails.pronouns.filter((pronoun) => {
                              if (pronoun != value) {
                                return pronoun;
                              }
                            }),
                          };
                        })
                      }
                    >
                      {value}
                    </Tag>
                  ))}
                </div>
              </span>
            ) : updatedDetails?.pronouns.length != 0 ? (
              <>
                <div className='flex flex-col justify-center mt-8'>
                  <label className='text-gray-600 font-bold'>Pronouns</label>
                  <p className='text-lg text-gray-600'>
                    {updatedDetails?.pronouns.map((value, index) => (
                      <Tag key={index}>{value}</Tag>
                    ))}
                  </p>
                </div>
              </>
            ) : (
              <>
                <div className='flex flex-col justify-center'>
                  <label className='text-gray-600 font-bold'>Pronouns</label>
                  <p className='text-gray-300'>Fill out this section</p>
                </div>
              </>
            )}
          </div>
        </div>
        <div className='flex flex-wrap justfiy-start gap-8'>
          <div className='profile-field mt-4'>
            {isProfileEditing ? (
              <>
                <label className='text-gray-600 font-bold block mb-1'>
                  Date of Birth
                </label>
                <span>
                  <input
                    type='date'
                    style={{ width: 150 }}
                    defaultValue={userDetails?.dateOfBirth}
                    onChange={(e) => {
                      setUpdatedDetails((prevDetails) => {
                        return {
                          ...prevDetails,
                          dateOfBirth: e.target.value,
                        };
                      });
                    }}
                    className='border border-gray-300 h-8 pl-2'
                  />
                  {errors.dateOfBirth && (
                    <p className='text-red-500'>{errors.dateOfBirth}</p>
                  )}
                </span>
              </>
            ) : userDetails?.dateOfBirth ? (
              <>
                <label className='text-gray-600 font-bold'>Date of Birth</label>
                <p className='mt-1 text-gray-600'>
                  {userDetails?.dateOfBirth?.split('T')[0]}
                </p>
              </>
            ) : (
              <>
                <label className='text-gray-600 font-bold'>Date of Birth</label>
                <p className='text-gray-300'>Fill out this section</p>
              </>
            )}
          </div>
          <div className='profile-field mt-4'>
            {isProfileEditing ? (
              <>
                <label className='text-gray-600 font-bold block mb-1'>
                  Marital Status
                </label>
                <Select
                  showSearch
                  style={{ width: 150 }}
                  defaultValue={updatedDetails?.maritalStatus}
                  onChange={(e) => {
                    setUpdatedDetails((prevDetails) => {
                      return {
                        ...prevDetails,
                        maritalStatus: e,
                      };
                    });
                  }}
                >
                  {maritalStatus.map((status) => {
                    return (
                      <Select.Option key={status} value={status}>
                        {status}
                      </Select.Option>
                    );
                  })}
                </Select>
              </>
            ) : userDetails?.maritalStatus ? (
              <>
                <label className='text-gray-600 font-bold block mb-1'>
                  Marital Status
                </label>
                <p className='mt-1 text-gray-600'>
                  {userDetails?.maritalStatus}
                </p>
              </>
            ) : (
              <>
                <label className='text-gray-600 font-bold'>
                  Marital Status
                </label>
                <p className='text-gray-300'>Fill out this section</p>
              </>
            )}
          </div>
        </div>
        <div className='flex flex-wrap justify-start gap-8'>
          <div className='profile-field mt-4'>
            {isProfileEditing ? (
              <>
                <label className='text-gray-600 font-bold'>Mobile</label>
                <div className='flex'>
                  <span>
                    <PhoneInput
                      country={'in'}
                      value={`${updatedDetails?.phoneNumber?.isdCode}${updatedDetails?.phoneNumber?.phone}`}
                      containerStyle={{ height: '2.6rem' }}
                      inputStyle={{
                        height: '2.6rem',
                        fontSize: '.9rem',
                        width: '100%',
                      }}
                      onChange={(value, data) => {
                        setUpdatedDetails((prevDetails) => {
                          return {
                            ...prevDetails,
                            phoneNumber: {
                              isdCode: data.dialCode,
                              phone: value.slice(data.dialCode.length),
                            },
                          };
                        });
                      }}
                    />
                    {errors.phoneNumber && (
                      <p className='text-red-500'>{errors.phoneNumber}</p>
                    )}
                  </span>
                  {userDetails && !userDetails.phoneNumber && (
                    <button
                      className='ml-2 px-4 py-2 text-white bg-blue-500 rounded'
                      onClick={() => handleModalVisibility(userDetails)}
                    >
                      verify
                    </button>
                  )}
                </div>
              </>
            ) : updatedDetails?.phoneNumber ? (
              <>
                <label className='text-gray-600 font-bold'>Mobile</label>
                <p className='mt-1 text-gray-600'>
                  +{updatedDetails?.phoneNumber?.isdCode}{' '}
                  {updatedDetails?.phoneNumber?.phone}
                </p>
              </>
            ) : (
              <>
                <label className='text-gray-600 font-bold'>Mobile</label>
                <p className='text-gray-300'>Fill out this section</p>
              </>
            )}
          </div>
          <div className='profile-field mt-4'>
            {isProfileEditing ? (
              <>
                <label className='text-gray-600 font-bold'>
                  Emergency Mobile
                </label>
                <span>
                  <PhoneInput
                    country={'in'}
                    value={`${updatedDetails?.emergencyContactDetails?.phoneNumber?.isdCode}${updatedDetails?.emergencyContactDetails?.phoneNumber?.phone}`}
                    containerStyle={{ height: '2.6rem' }}
                    inputStyle={{
                      height: '2.6rem',
                      fontSize: '.9rem',
                      width: '98%',
                    }}
                    onChange={(value, data) => {
                      setUpdatedDetails((prevDetails) => {
                        return {
                          ...prevDetails,
                          emergencyContactDetails: {
                            ...prevDetails.emergencyContactDetails,
                            phoneNumber: {
                              isdCode: data.dialCode,
                              phone: value.slice(data.dialCode.length),
                            },
                          },
                        };
                      });
                    }}
                  />
                  {errors.phoneNumber && (
                    <p className='text-red-500'>{errors.phoneNumber}</p>
                  )}
                </span>
              </>
            ) : updatedDetails?.emergencyContactDetails?.phoneNumber ? (
              <>
                <label className='text-gray-600 font-bold'>
                  Emergency Mobile
                </label>
                <p className='mt-1 text-gray-600'>
                  +
                  {
                    updatedDetails?.emergencyContactDetails?.phoneNumber
                      ?.isdCode
                  }{' '}
                  {updatedDetails?.emergencyContactDetails?.phoneNumber?.phone}
                </p>
              </>
            ) : (
              <>
                <label className='text-gray-600 font-bold'>
                  Emergency Mobile
                </label>
                <p className='text-gray-300'>Fill out this section</p>
              </>
            )}
          </div>
          <div className='profile-field mt-4'>
            {isProfileEditing ? (
              <>
                <label className='text-gray-600 font-bold'>Relationship</label>
                <input
                  style={{ width: '98%' }}
                  className='p-2 border border-gray-300 rounded-md'
                  defaultValue={
                    updatedDetails?.emergencyContactDetails?.relationship
                  }
                  onChange={(e) => {
                    setUpdatedDetails((prevDetails) => {
                      return {
                        ...prevDetails,
                        emergencyContactDetails: {
                          ...prevDetails.emergencyContactDetails,
                          relationship: e.target.value,
                        },
                      };
                    });
                  }}
                />
                {errors.relationship && (
                  <p className='text-red-500'>{errors.relationship}</p>
                )}
              </>
            ) : updatedDetails?.emergencyContactDetails?.relationship ? (
              <>
                <label className='text-gray-600 font-bold'>Relationship</label>
                <p className='text-lg text-gray-600'>
                  {updatedDetails?.emergencyContactDetails?.relationship}
                </p>
              </>
            ) : (
              <>
                <label className='text-gray-600 font-bold'>Relationship</label>
                <p className='text-gray-300'>Fill out this section</p>
              </>
            )}
          </div>
        </div>
        <h6 className='font-black mt-8 text-xl text-gray-700'>
          Permanent Address
        </h6>
        <div className=''>
          <div className='profile-field mt-2'>
            {isProfileEditing ? (
              <>
                <label className='text-gray-600 font-bold'>
                  Address Line 1
                </label>
                <span>
                  <input
                    style={{ width: '98%' }}
                    className=' mt-1 p-2 border border-gray-300 rounded-md'
                    defaultValue={updatedDetails?.currentAddress?.addressLine1}
                    onChange={(e) => {
                      setUpdatedDetails((prevDetails) => {
                        return {
                          ...prevDetails,
                          currentAddress: {
                            ...prevDetails.permanentAddress,
                            addressLine1: e.target.value,
                          },
                        };
                      });
                    }}
                  />
                  {errors.currentAddress?.addressLine1 && (
                    <p className='text-red-500'>
                      {errors.currentAddress.addressLine1}
                    </p>
                  )}
                </span>
              </>
            ) : updatedDetails?.currentAddress?.addressLine1 ? (
              <>
                <label className='text-gray-600 font-bold'>Address</label>
                <p className='text-lg text-gray-600'>
                  {updatedDetails?.currentAddress?.addressLine1}
                  {', '}
                  {updatedDetails?.currentAddress?.addressLine2}
                </p>
              </>
            ) : (
              <>
                <label className='text-gray-600 font-bold'>Address</label>
                <p className='text-gray-300'>Fill out this section</p>
              </>
            )}
          </div>
          <div className='profile-field mt-2'>
            {isProfileEditing ? (
              <>
                <label className='text-gray-600 font-bold'>
                  Address Line 2
                </label>

                <input
                  style={{ width: '98%' }}
                  className=' mt-1 p-2 border border-gray-300 rounded-md'
                  defaultValue={updatedDetails?.currentAddress?.addressLine2}
                  onChange={(e) => {
                    setUpdatedDetails((prevDetails) => {
                      return {
                        ...prevDetails,
                        currentAddress: {
                          ...prevDetails.currentAddress,
                          addressLine2: e.target.value,
                        },
                      };
                    });
                  }}
                />
              </>
            ) : (
              <></>
            )}
          </div>
        </div>

        <div className='flex justify-start flex-wrap gap-8'>
          <div className='profile-field mt-2'>
            <label className='text-gray-600 font-bold'>City</label>
            {isProfileEditing ? (
              <span>
                <input
                  style={{ width: '98%' }}
                  className=' mt-1 p-2 border border-gray-300 rounded-md'
                  defaultValue={updatedDetails?.currentAddress?.city}
                  onChange={(e) => {
                    setUpdatedDetails((prevDetails) => {
                      return {
                        ...prevDetails,
                        currentAddress: {
                          ...prevDetails.currentAddress,
                          city: e.target.value,
                        },
                      };
                    });
                  }}
                />
                {errors.currentAddress?.city && (
                  <p className='text-red-500'>{errors.currentAddress.city}</p>
                )}
              </span>
            ) : updatedDetails?.currentAddress?.city ? (
              <p className='text-lg text-gray-600'>
                {updatedDetails?.currentAddress?.city}
              </p>
            ) : (
              <p className='text-gray-300'>Fill out this section</p>
            )}
          </div>
          <div className='profile-field mt-2'>
            <label className='text-gray-600 font-bold'>State</label>
            {isProfileEditing ? (
              <span>
                <input
                  style={{ width: '98%' }}
                  className=' mt-1 p-2 border border-gray-300 rounded-md'
                  defaultValue={updatedDetails?.currentAddress?.state}
                  onChange={(e) => {
                    setUpdatedDetails((prevDetails) => {
                      return {
                        ...prevDetails,
                        currentAddress: {
                          ...prevDetails.currentAddress,
                          state: e.target.value,
                        },
                      };
                    });
                  }}
                />
                {errors.currentAddress?.state && (
                  <p className='text-red-500'>{errors.currentAddress.state}</p>
                )}
              </span>
            ) : updatedDetails?.currentAddress?.state ? (
              <p className='text-lg text-gray-600'>
                {updatedDetails?.currentAddress?.state}
              </p>
            ) : (
              <p className='text-gray-300'>Fill out this section</p>
            )}
          </div>
        </div>
        <div className='flex justify-start flex-wrap gap-8'>
          <div className='profile-field mt-2'>
            <label className='text-gray-600 font-bold'>Country</label>
            {isProfileEditing ? (
              <span>
                <input
                  style={{ width: '98%' }}
                  className=' mt-1 p-2 border border-gray-300 rounded-md'
                  defaultValue={updatedDetails?.currentAddress?.country}
                  onChange={(e) => {
                    setUpdatedDetails((prevDetails) => {
                      return {
                        ...prevDetails,
                        currentAddress: {
                          ...prevDetails.currentAddress,
                          country: e.target.value,
                        },
                      };
                    });
                  }}
                />
                {errors.currentAddress?.country && (
                  <p className='text-red-500'>
                    {errors.currentAddress.country}
                  </p>
                )}
              </span>
            ) : updatedDetails?.currentAddress?.country ? (
              <p className='text-lg text-gray-600'>
                {updatedDetails?.currentAddress?.country}
              </p>
            ) : (
              <p className='text-gray-300'>Fill out this section</p>
            )}
          </div>
          <div className='profile-field mt-2'>
            <label className='text-gray-600 font-bold'>Pincode</label>
            {isProfileEditing ? (
              <span>
                <input
                  style={{ width: '98%' }}
                  className=' mt-1 p-2 border border-gray-300 rounded-md'
                  defaultValue={updatedDetails?.currentAddress?.pincode}
                  onChange={(e) => {
                    setUpdatedDetails((prevDetails) => {
                      return {
                        ...prevDetails,
                        currentAddress: {
                          ...prevDetails.currentAddress,
                          pincode: e.target.value,
                        },
                      };
                    });
                  }}
                />
                {errors.currentAddress?.pincode && (
                  <p className='text-red-500'>
                    {errors.currentAddress.pincode}
                  </p>
                )}
              </span>
            ) : updatedDetails?.currentAddress?.pincode ? (
              <p className='text-lg text-gray-600'>
                {updatedDetails?.currentAddress?.pincode}
              </p>
            ) : (
              <p className='text-gray-300'>Fill out this section</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileDetails;
