import React, { useEffect, useState } from "react";
import { Card, Rate, Button, Input, Checkbox, notification, Spin, Radio, Select, DatePicker, TimePicker, Upload, Space, Avatar, Typography } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { getFormById, saveResponse, uploadFile } from "../Network/IntakeForm";
import { CheckCircleOutlined, CloseCircleOutlined, UploadOutlined, LeftOutlined, RightOutlined, UserOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserDetails } from "../Network/Client";
import { fetchTherapistById } from "../Network/Therapist";
import Cookies from "js-cookie";
import { handleError } from "../ErrorHandling/handleError";
import PracflowLogo from "../assets/images/PracflowLogo.svg";

const { Title, Paragraph } = Typography;
const { TextArea } = Input;
const { Option } = Select;

function FeedbackForm() {
  const nav = useNavigate();
  const dispatch = useDispatch();
  const { formId } = useParams();
  const authorization = useSelector((state) => state.combined.auth.authorization);

  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({ questions: [] });
  const [responses, setResponses] = useState({});
  const [userDetails, setUserDetails] = useState({});
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [therapistDetails, setTherapistDetails] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [countdown, setCountdown] = useState(30);

  useEffect(() => {
    const fetchFormData = async () => {
      try {
        setIsLoading(true);
        const res = await getFormById(formId);
        setFormData(res);
        const therapistDetails = await fetchTherapistById(res.therapistId);
        setTherapistDetails(therapistDetails);
      } catch (error) {
        setIsLoading(false);
        await handleError(error, nav, dispatch);
      } finally {
        setIsLoading(false);
      }
    };
    fetchFormData();
  }, [formId, nav, dispatch]);

  useEffect(() => {
    if (!authorization) {
      // Cookies.set("redirectTo", `intakeFrom/${formId}`, { path: "/" });
      // nav("/login");
    } else {
      const fetchUser = async () => {
        try {
          setIsLoading(true);
          const res = await fetchUserDetails(authorization);
          setUserDetails(res);
        } catch (error) {
          setIsLoading(false);
          await handleError(error, nav, dispatch);
        } finally {
          setIsLoading(false);
        }
      };
      fetchUser();
    }
  }, [formId, nav, dispatch, authorization]);

  useEffect(() => {
    let timer;
    if (isSubmitted && countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else if (countdown === 0) {
      nav("/profile");
    }
    return () => clearInterval(timer);
  }, [isSubmitted, countdown, nav]);

  const handleChange = async (key, value, shouldAutoSave = false) => {
    setResponses((prev) => ({ ...prev, [key]: value }));

    if (shouldAutoSave) {
      await saveAllResponses();
    }
  };

  const addNotification = (type, message) => {
    notification.open({
      message: type === "success" ? "Success" : "Failure",
      description: message,
      icon: type === "success" ? <CheckCircleOutlined style={{ color: "#52c41a" }} /> : <CloseCircleOutlined style={{ color: "#f5222d" }} />,
      duration: 3,
    });
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const formResponses = Object.entries(responses).map(([key, value]) => {
        const question = formData.questions.find(q => q.key === key);
        return {
          key,
          response: value,
          label: question?.label || "",
          componentType: question?.componentType,
          placeholder: question?.placeholder,
          options: question?.options
        };
      });

      const formResponse = {
        formId: formId,
        therapistId: formData.therapistId,
        clientId: userDetails._id,
        responses: formResponses,
      };

      await saveResponse(formResponse.formId, formResponse.therapistId, formResponse.clientId, formResponse);
      addNotification("success", "Response saved successfully");
      setIsSubmitted(true);
    } catch (err) {
      addNotification("fail", "Response not saved");
      await handleError(err, nav, dispatch);
    } finally {
      setIsLoading(false);
    }
  };

  const saveAllResponses = async () => {
    const formResponses = formData.questions
      .slice(0, currentQuestionIndex + 1)
      .map((question) => ({
        key: question.key,
        response: responses[question.key],
        label: question.label,
        componentType: question?.componentType,
        placeholder: question?.placeholder,
        options: question?.options,
      }));

    const formResponse = {
      formId: formId,
      therapistId: formData.therapistId,
      clientId: userDetails._id,
      responses: formResponses,
    };
    await saveResponse(formResponse.formId, formResponse.therapistId, formResponse.clientId, formResponse);
  };

  const handleNext = async () => {
    try {
      await saveAllResponses();
      if (currentQuestionIndex < formData.questions.length - 1) {
        setCurrentQuestionIndex((prev) => prev + 1);
      } else {
        handleSubmit();
      }
    } catch (err) {
      addNotification("fail", "Response not saved");
      await handleError(err, nav, dispatch);
    }
  };

  const renderQuestion = () => {
    const question = formData.questions[currentQuestionIndex];
    if (!question) return null;

    switch (question.componentType) {
      case "rating":
        return (
          <div className="text-center">
            <Rate
              tooltips={question.options}
              value={question.options.indexOf(responses[question.key]) + 1 || 0}
              onChange={(value) => handleChange(question.key, question.options[value - 1], true)}
              count={question.options.length}
            />
          </div>
        );
      case "multiple":
        return (
          <div>
            {question.options.map((option) => (
              <Button
                key={option}
                type={responses[question.key]?.includes(option) ? "primary" : "default"}
                onClick={() => {
                  const currentValues = responses[question.key] || [];
                  const newValues = currentValues.includes(option)
                    ? currentValues.filter((v) => v !== option)
                    : [...currentValues, option];
                  handleChange(question.key, newValues, true);
                }}
                style={{ margin: "0 8px 8px 0" }}
              >
                {option}
              </Button>
            ))}
          </div>
        );
      case "text":
        return (
          <TextArea
            value={responses[question.key] || ""}
            onChange={(e) => handleChange(question.key, e.target.value)}
            placeholder={question.placeholder}
            rows={4}
          />
        );
      case "checkbox":
        return (
          <div className="text-center">
            <Radio.Group
              value={responses[question.key]}
              onChange={(e) => handleChange(question.key, e.target.value, true)}
            >
              <div className="flex flex-wrap gap-2">
                {question.options.map((option, idx) => (
                  <Radio key={idx} value={option}>
                    {option}
                  </Radio>
                ))}
              </div>
            </Radio.Group>
          </div>
        );
      case "linearScale":
        return (
          <Radio.Group
            value={responses[question.key]}
            onChange={(e) => handleChange(question.key, e.target.value)}
          >
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((value) => (
              <Radio.Button key={value} value={value}>
                {value}
              </Radio.Button>
            ))}
          </Radio.Group>
        );
      case "dropdown":
        return (
          <Select
            style={{ width: "100%" }}
            value={responses[question.key]}
            onChange={(value) => handleChange(question.key, value)}
            placeholder="Select an option"
          >
            {question.options.map((option, idx) => (
              <Option key={idx} value={option}>
                {option}
              </Option>
            ))}
          </Select>
        );
      case "date":
        return (
          <DatePicker
            style={{ width: "100%" }}
            value={responses[question.key]}
            onChange={(date) => handleChange(question.key, date)}
          />
        );
      case "time":
        return (
          <TimePicker
            style={{ width: "100%" }}
            value={responses[question.key]}
            onChange={(time) => handleChange(question.key, time)}
          />
        );
      case "fileUpload":
        return (
          <Upload
            beforeUpload={(file) => {
              handleChange(question.key, file);
              return false;
            }}
          >
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload>
        );
      default:
        return null;
    }
  };

  const handleBack = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex((prev) => prev - 1);
    }
  };

  const ThankYouComponent = () => (
    <Card className="w-full flex flex-col max-w-sm bg-white rounded-lg shadow-lg overflow-hidden mb-4">
      <div className="text-center p-4">
        <CheckCircleOutlined style={{ fontSize: '48px', color: '#52c41a' }} />
        <Title level={3} className="mt-4">Thank You!</Title>
        <Paragraph>
          Your feedback has been successfully submitted. We appreciate your time and input.
        </Paragraph>
        <Paragraph>
          You will be redirected to your profile page in <span className="font-bold text-blue-600">{countdown}</span> seconds.
        </Paragraph>
      </div>
    </Card>
  );

  return (
    <>
      {isLoading ? (
        <div className='min-h-screen border border-gray-500 flex justify-center items-center'>
          <div className="self-center text-center">
            <Spin tip='Loading' size='large'>
            </Spin>
          </div>
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center min-h-screen bg-gradient-to-r from-blue-300 to-blue-100">
          {isSubmitted ? (
            <ThankYouComponent />
          ) : (
            <Card className="w-full flex flex-col max-w-sm bg-white rounded-lg shadow-lg overflow-hidden mb-4">
              <>
                <div className="text-center m-1">
                  <Space>
                    {therapistDetails?.profilePictureUrl?.s3Url ? (
                      <Avatar
                        size={74}
                        shape="circle"
                        src={therapistDetails.profilePictureUrl.s3Url}
                      />
                    ) : (
                      <Avatar size={74} shape="circle" icon={<UserOutlined />} />
                    )}
                  </Space>
                  <div className="text-center mt-2">
                    <p className="font-bold" style={{ fontSize: '20px', margin: '0' }}>{therapistDetails?.givenName} {therapistDetails?.familyName}</p>
                  </div>
                </div>
                <div className="p-4">
                  {formData.questions.length > 0 ? (
                    <div className="flex flex-col items-center">
                      <div className="mb-6">
                        <h3 className="text-lg text-center font-semibold">
                          {formData.questions[currentQuestionIndex]?.label}
                        </h3>
                        {renderQuestion()}
                      </div>
                      <div className="flex justify-between w-full">
                        {currentQuestionIndex > 0 ? (
                          <>
                            <Button
                              type="default"
                              onClick={handleBack}
                              size="large"
                            >
                              Back
                            </Button>
                            <Button
                              type="primary"
                              onClick={handleNext}
                              size="large"
                              className="w-full ml-2"
                            >
                              {currentQuestionIndex === formData.questions.length - 1
                                ? "Submit"
                                : "Next"}
                            </Button>
                          </>
                        ) : (
                          <div className="flex justify-center w-full">
                            <Button
                              type="primary"
                              onClick={handleNext}
                              size="large"
                              className="w-full text-center"
                            >
                              Next
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <p className="text-gray-600">No questions available in this form.</p>
                  )}
                </div>
              </>
            </Card>
          )}
          <Card className="w-full flex flex-col max-w-sm bg-white rounded-lg shadow-lg overflow-hidden mb-4">
            <div className="flex flex-col items-center text-white">
              <Title level={4} className="text-white mb-2">Want another session?</Title>
              <Paragraph className="text-center mb-6">
                Book a followup session with {therapistDetails?.givenName} {therapistDetails?.familyName} to further discuss your therapy goals.
              </Paragraph>
              {therapistDetails?.profileHandle ? (
                <Button onClick={() =>
                  nav(`/therapist/${therapistDetails.profileHandle}`)
                } type="primary" size="large" className="bg-white text-blue-600 hover:bg-gray-100 hover:text-blue-700 border-none">
                  Book Followup
                </Button>
              ) : (
                <Button onClick={() =>
                  nav(`/therapist/${therapistDetails._id}`)
                } type="primary" size="large" className="bg-white text-blue-600 hover:bg-gray-100 hover:text-blue-700 border-none">
                  Book Followup
                </Button>
              )}
            </div>
          </Card>
          <div className="mt-4 flex flex-col">
            <span className="ml-2 text-xs font-bold" style={{color: "#270949"}}>Powered by</span>
            <img
              className="mx-auto w-auto bg-transparent"
              style={{height: '50px', width: 'auto'}}
              src={PracflowLogo}
              alt="Your Company"
            />
          </div>
        </div>
      )}
    </>
  );
}

export default FeedbackForm;