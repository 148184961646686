import { useEffect, useState, useRef } from 'react';
import Main from '../components/layout/Main';
import { useDispatch, useSelector } from 'react-redux';
import {
  Row,
  Col,
  Card,
  Avatar,
  Spin,
  notification,
  Dropdown,
  Space,
  Modal,
} from 'antd';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  EyeOutlined,
  UploadOutlined,
  UserOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
import ProfileDetails from '../components/ProfileDetails';
import { useNavigate } from 'react-router-dom';
import {
  fetchUserDetails,
  updateProfile,
  uploadProfilePhoto,
} from '../Network/Client';
import { handleError } from '../ErrorHandling/handleError';
import { genOtp, verifyOtp } from '../Network/Auth';
import Footer from '../components/layout/Footer';
import { clientCookieName } from '../constants';
import { setAuthorization, setClientDetails } from '../redux/combinedSlice';
import Cookies from 'js-cookie';

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

function Profile() {
  const fileInputRef = useRef(null);
  const nav = useNavigate();
  const dispatch = useDispatch();
  const [userDetails, setUserDetails] = useState();
  const [updatedDetails, setUpdatedDetails] = useState();
  const [isProfileEditing, setisProfileEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isViewProfile, setisViewProfile] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [mobile, setMobile] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [buttonText, setButtonText] = useState('Generate OTP');
  const [timer, setTimer] = useState(0);
  const [isModalVisible1, setIsModalVisible1] = useState(false);

  const getCookie = (data) => {
    const cookies = document.cookie.split('; ');
    let decodedCookie;
    cookies.forEach((cookie) => {
      if (cookie.startsWith(data)) {
        decodedCookie = decodeURIComponent(cookie.split('=')[1]);
      }
    });
    return decodedCookie;
  };
  useEffect(() => {
    const authCookie = getCookie(clientCookieName);
    const redirectCookie = getCookie('redirectTo');
    if (authCookie) {
      dispatch(setAuthorization(authCookie));
      if (redirectCookie) {
        Cookies.remove('redirectTo', { path: '/' });
        nav(`/${redirectCookie}`);
      }
    }
    if (!authCookie) {
      nav('/login');
    }
    const getUserDetails = async () => {
      try {
        setIsLoading(true);
        const allDetails = await fetchUserDetails(authCookie);
        setUserDetails(allDetails);
        setUpdatedDetails(allDetails);
        dispatch(setClientDetails({ clientId: allDetails._id }));
        skipDeleteModal.current = true;
        handleModalVisibility(allDetails);
      } catch (error) {
        setIsLoading(false);
        await handleError(error, nav, dispatch);
      } finally {
        setIsLoading(false);
      }
    };

    getUserDetails();
  }, []);

  const authorization = useSelector(
    (state) => state.combined.auth.authorization
  );

  const addNotification = (type, message) => {
    notification.open({
      message: type == 'success' ? 'Success' : 'Failure',
      description: message,
      icon:
        type == 'success' ? (
          <CheckCircleOutlined style={{ color: '#52c41a' }} />
        ) : (
          <CloseCircleOutlined style={{ color: '#f5222d' }} />
        ),
      duration: 3,
    });
  };

  const times = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 30) {
      let amPm = hour >= 12 ? 'PM' : 'AM';
      let displayHour = hour % 12 === 0 ? 12 : hour % 12;
      let displayMinute = minute === 0 ? '00' : minute;
      let timeValue = `${displayHour}:${displayMinute} ${amPm}`;
      times.push(timeValue);
    }
  }

  const handleProfileUpdate = async () => {
    if (userDetails != updatedDetails) {
      setIsLoading(true);

      try {
        const res = await updateProfile(authorization, updatedDetails);

        setIsLoading(true);
        const allDetails = await fetchUserDetails(authorization);
        setUserDetails(allDetails);
        setUpdatedDetails(allDetails);
        setIsLoading(false);
        addNotification('success', 'Profile Details Updated Successfully');
      } catch (err) {
        setUpdatedDetails(userDetails);
        setIsLoading(false);
        await handleError(err, nav, dispatch);
      }
      setIsLoading(false);
    }
    setisProfileEditing(false);
  };

  const handleProfilePhotoChange = async (e) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('media', e.target.files[0]);

    try {
      const response = await uploadProfilePhoto(authorization, formData);
      setIsLoading(true);
      const allDetails = await fetchUserDetails(authorization);
      setUserDetails(allDetails);
      setUpdatedDetails(allDetails);
      setIsLoading(false);
      addNotification('success', 'Profile Picture Updated Successfully');
    } catch (error) {
      setIsLoading(false);
      await handleError(error, nav, dispatch);
    }
  };

  function handleModalVisibility(userDetails) {
    if (userDetails && userDetails.phoneNumber) {
      setIsModalVisible(false);
    } else {
      setIsModalVisible(true);
    }
  }

  const skipDeleteModal = useRef(true);
  useEffect(() => {
    if (skipDeleteModal.current) {
      skipDeleteModal.current = false;
    } else {
      if (updatedDetails?.profilePictureUrl?.s3Url === '') {
        showDeleteConfirm();
      }
    }
  }, [updatedDetails?.profilePictureUrl?.s3Url]);

  const profilePhotoItems = [
    {
      label: 'View Profile Photo',
      key: 'view',
      icon: <EyeOutlined />,
    },
    {
      label: 'Upload new Profile photo',
      key: 'upload',
      icon: <UploadOutlined />,
    },
    {
      label: 'Delete Profile photo',
      key: 'delete',
      icon: <DeleteOutlined />,
    },
  ];

  const handleMenuOnClick = (e) => {
    e.domEvent.stopPropagation();
    const { key } = e;
    if (key == 'view') {
      setisViewProfile(true);
    } else if (key == 'upload') {
      fileInputRef.current.click();
    } else if (key == 'delete') {
      setUpdatedDetails((prevDetails) => ({
        ...prevDetails,
        profilePictureUrl: { s3Url: '' },
      }));
    }
  };

  const showDeleteConfirm = () => {
    Modal.confirm({
      title: 'Are you sure you want to delete this item?',
      content: 'This action cannot be undone.',
      okText: 'Yes, delete it',
      cancelText: 'No, cancel',
      onCancel() {
        setUpdatedDetails(userDetails);
      },
      onOk() {
        handleProfileUpdate();
      },
      okButtonProps: {
        className:
          'bg-red-600 border-red-600 text-white hover:bg-red-700 hover:border-red-700',
      },
      cancelButtonProps: {
        className:
          'bg-blue-600 border-blue-600 text-white hover:bg-blue-700 hover:border-blue-700',
      },
    });
  };

  const handleGenerateOtp = async () => {
    try {
      const response = await genOtp(authorization, mobile, 'client');
      // console.log(response);
      if (response.success) {
        setOtpSent(true);
        setButtonText('Resend OTP');
        setIsButtonDisabled(true);
        setTimer(60); // Start the 60-second timer
        addNotification('success', 'Otp sent successfully!');
      } else {
        addNotification('fail', 'Failed to send OTP. Please try again.');
      }
    } catch (error) {
      addNotification('fail', 'Failed to send OTP. Please try again.');
      console.error('failed to generate OTP:', error);
    }
  };

  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else {
      setIsButtonDisabled(false);
    }
    return () => clearInterval(interval);
  }, [timer]);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleCancel1 = () => {
    setIsModalVisible1(false);
  };

  const handleVerification = () => {
    setIsModalVisible(false);

    // Set a flag in localStorage to indicate that the modal should be visible after reload
    localStorage.setItem('showModalAfterReload', 'true');

    // Reload the window
    window.location.reload();
  };

  useEffect(() => {
    const shouldShowModal = localStorage.getItem('showModalAfterReload');

    if (shouldShowModal === 'true') {
      setIsModalVisible1(true);

      localStorage.removeItem('showModalAfterReload');
    }
  }, []);

  const handleVerifyOtp = async () => {
    try {
      const response = await verifyOtp(authorization, otp, mobile, 'client');
      // console.log(response);
      if (response.success) {
        addNotification('success', 'OTP verified successfully!');
        setUpdatedDetails(response.updatedClient);
        handleVerification();
      } else {
        addNotification(
          'fail',
          'OTP verification failed. Please check and try again.'
        );
      }
    } catch (error) {
      addNotification('fail', 'Failed to verify OTP. Please try again.');
      console.error('failed to verify OTP:', error);
    }
  };

  const handleAddEmergencyNumber = async () => {
    try {
      // Assuming `mobile` contains the full number with ISD code
      const isdCode = mobile.slice(0, 2);
      const phone = mobile.slice(2);

      // Create the emergency contact details object with just the phone number
      const emergencyContactDetails = {
        phoneNumber: {
          isdCode,
          phone,
        },
      };

      // Update the client's emergency contact using the updateProfile function
      const updatedDetailsWithEmergencyNumber = {
        ...updatedDetails,
        emergencyContactDetails,
      };

      // Call updateProfile with updated client details
      const res = await updateProfile(
        authorization,
        updatedDetailsWithEmergencyNumber
      );

      // Fetch the latest user details after the update
      const allDetails = await fetchUserDetails(authorization);
      setUserDetails(allDetails);
      setUpdatedDetails(allDetails);
      setIsModalVisible1(false);
      // Notify the user of success
      addNotification('success', 'Emergency Number Added Successfully');
    } catch (err) {
      // In case of an error, revert to the original user details and handle the error
      setUpdatedDetails(userDetails);
      await handleError(err, nav, dispatch);
    }
  };

  return (
    <>
      <Main isViewProfile={isViewProfile}>
        {isLoading ? (
          <div className='h-full content-center'>
            <Spin tip='Loading' size='large'>
              <div
                style={{
                  borderRadius: 4,
                }}
              />
            </Spin>
          </div>
        ) : (
          <div
            className='relative top-12 p-4 min-h-screen'
            onClick={(e) => {
              e.stopPropagation();
              setisViewProfile(false);
            }}
          >
            <Card
              className='card-profile-head mb-8'
              bodyStyle={{ display: 'none' }}
              title={
                <Row justify='space-between' align='middle' gutter={[24, 0]}>
                  <Col span={24} md={12} className='col-info'>
                    <Avatar.Group>
                      <Dropdown
                        className='hover:cursor-pointer'
                        menu={{
                          items: profilePhotoItems,
                          onClick: (e) => handleMenuOnClick(e),
                        }}
                        trigger={['click']}
                      >
                        <Space>
                          {userDetails?.profilePicture?.s3Url ? (
                            <Avatar
                              size={74}
                              shape='square'
                              src={userDetails.profilePicture.s3Url}
                            />
                          ) : (
                            <Avatar
                              size={74}
                              shape='square'
                              icon={<UserOutlined />}
                            />
                          )}
                        </Space>
                      </Dropdown>
                      {isViewProfile ? (
                        <div className='fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50'>
                          <div
                            className='relative bg-white rounded-3xl shadow-lg h-96 w-96 overflow-scroll overflow-x-hidden'
                            onClick={(e) => e.stopPropagation()}
                          >
                            {userDetails?.profilePicture?.s3Url ? (
                              <img src={userDetails.profilePicture.s3Url}></img>
                            ) : (
                              <Avatar
                                size={400}
                                shape='square'
                                icon={<UserOutlined />}
                              />
                            )}
                          </div>
                        </div>
                      ) : (
                        <div>
                          <input
                            type='file'
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                            onChange={handleProfilePhotoChange}
                          />
                        </div>
                      )}

                      <div className='avatar-info ml-4'>
                        <h4 className='font-semibold m-0 text-2xl'>
                          {updatedDetails?.givenName}{' '}
                          {updatedDetails?.familyName}
                        </h4>
                      </div>
                    </Avatar.Group>
                  </Col>
                </Row>
              }
            ></Card>
            <Row gutter={[24, 0]}>
              <Col span={48} md={24} className='mb-24'>
                <Card
                  bordered={false}
                  className='header-solid h-full card-profile-information text-lg'
                  bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}
                >
                  <ProfileDetails
                    authorization={authorization}
                    handleModalVisibility={handleModalVisibility}
                    userDetails={userDetails}
                    updatedDetails={updatedDetails}
                    setUpdatedDetails={setUpdatedDetails}
                    isProfileEditing={isProfileEditing}
                    setisProfileEditing={setisProfileEditing}
                    handleProfileUpdate={handleProfileUpdate}
                  />
                </Card>
              </Col>
            </Row>
          </div>
        )}
        <Modal
          title='Enter Your Mobile Number'
          open={isModalVisible}
          onCancel={handleCancel}
          footer={null}
          closeIcon={<span>X</span>}
        >
          <div className='mt-2 flex items-center'>
            <div className='flex-grow'>
              <PhoneInput
                country={'in'}
                value={mobile}
                maxLength='10'
                containerStyle={{ height: '2.5rem' }}
                inputStyle={{
                  height: '2.5rem',
                  fontSize: '1rem',
                  width: '100%',
                  borderRadius: '0',
                }}
                onChange={(value, data) => {
                  setMobile(
                    `${data.dialCode}${value.slice(data.dialCode.length)}`
                  );
                }}
              />
            </div>
            <button
              className='ml-2 px-4 py-2 text-white bg-blue-500 rounded'
              onClick={handleGenerateOtp}
              disabled={isButtonDisabled}
            >
              {buttonText}
            </button>
          </div>
          {timer > 0 && (
            <div className='mt-2 text-gray-500'>
              Didn't receive OTP? Try again in {timer} seconds.
            </div>
          )}
          {otpSent && (
            <div className='mt-4 flex items-center'>
              <input
                type='text'
                placeholder='Enter OTP'
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                className='flex-grow border rounded p-2'
              />
              <button
                className='ml-2 px-4 py-2 text-white bg-green-500 rounded'
                onClick={handleVerifyOtp}
              >
                Verify OTP
              </button>
            </div>
          )}
        </Modal>
        <Modal
          title='Enter Your Emergency Number'
          open={isModalVisible1}
          onCancel={handleCancel1}
          footer={null}
          closeIcon={<span>X</span>}
        >
          <div className='mt-2 flex items-center'>
            <div className='flex-grow'>
              <PhoneInput
                country={'in'}
                value={mobile}
                maxLength='10'
                containerStyle={{ height: '2.5rem' }}
                inputStyle={{
                  height: '2.5rem',
                  fontSize: '1rem',
                  width: '100%',
                  borderRadius: '0',
                }}
                onChange={(value, data) => {
                  setMobile(
                    `${data.dialCode}${value.slice(data.dialCode.length)}`
                  );
                }}
              />
            </div>
            <button
              className='ml-2 px-4 py-2 text-white bg-blue-500 rounded'
              onClick={handleAddEmergencyNumber}
            >
              Add
            </button>
          </div>
        </Modal>
      </Main>
      <Footer />
    </>
  );
}

export default Profile;
