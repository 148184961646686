import React from 'react';
import CountUp from 'react-countup';
import { stats } from '../assets/data/LPData';

const StatsSection = () => {
  return (
    <section className='pt-[-4]'>
      <div className='flex justify-around w-full'>
        <div className='grid grid-cols-1 md:grid-cols-4 gap-12 text-center'>
          {stats.map((stat, index) => (
            <div key={index} className='p-2 rounded-lg'>
              <p className='text-left tajawal-bold text-gray-600 text-base'>
                {stat.label}
              </p>
              <h2 className=' libre-baskerville-bold text-left text-orange-800 tajawal-bold text-4xl font-bold text-gray-800 mt-2'>
                <CountUp
                  start={0}
                  end={stat.value}
                  duration={3}
                  separator=','
                />
                {'  '}
                {stat.unit}
              </h2>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default StatsSection;
