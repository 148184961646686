import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Main from '../components/layout/Main';
import Footer from '../components/layout/Footer';
import {
  Spin,
  Table,
  Collapse,
  notification,
  Dropdown,
  Space,
  Menu,
  Tooltip,
  Badge,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAppointments,
  getTherapistsOfLoggedInUser,
} from '../Network/Appointment';
import { handleError } from '../ErrorHandling/handleError';
import moment from 'moment';
import {
  getFormResponseForClient,
  getFeedbackFormRequestsForClient,
  getIntakeFormRequestsForClient,
} from '../Network/IntakeForm';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  EllipsisOutlined,
  FormOutlined,
  BookOutlined,
  FileTextOutlined,
  MessageOutlined,
} from '@ant-design/icons';
import { fetchUserDetails } from '../Network/Client';

const { Panel } = Collapse;

function MyTherapist() {
  const dispatch = useDispatch();
  const nav = useNavigate();
  const [user, setUser] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalMessagecount, setTotalMessageCount] = useState(null)
  const authorization = useSelector(
    (state) => state.combined.auth.authorization
  );
  const [therapists, setTherapists] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const updateCount = useSelector(
    (state) => state.combined.updateCount
  );

  useEffect(() => {
  if (!authorization) return;
  const fetchUnreadCount = async () => {
      setTotalMessageCount(updateCount);
  };
  fetchUnreadCount();
}, [authorization, updateCount]);

  const fetchTherapists = useCallback(async () => {
    if (!authorization) return;
    try {
      const getTherapists = await getTherapistsOfLoggedInUser(authorization);
      const getAppointment = await getAppointments(authorization);
      setTherapists(getTherapists || []);
      setAppointments(getAppointment || []);
    } catch (error) {
      await handleError(error, nav, dispatch);
    } finally {
      setIsLoading(false);
    }
  }, [authorization, dispatch, nav]);

  const addNotification = (type, message) => {
    notification.open({
      description: message,
      icon:
        type === 'success' ? (
          <CheckCircleOutlined style={{ color: '#52c41a' }} />
        ) : (
          <CloseCircleOutlined style={{ color: '#f5222d' }} />
        ),
      duration: 5,
    });
  };

  const fetchUser = async () => {
    if (!authorization) return;
    const allDetails = await fetchUserDetails(authorization);
    setUser(allDetails);
  };

  const getIntakeForm = async (therapistId) => {
    try {
      const res = await getIntakeFormRequestsForClient(
        authorization,
        therapistId
      );
      if (res.length > 0) {
        nav(`/intakeFrom/${res[ res.length -1 ].formId}`);
      } else {
        addNotification(
          'fail',
          "You don't have any intake form assigned by your therapist, for more information please contact your therapist."
        );
      }
    } catch (error) {
      await handleError(error, nav, dispatch);
    }
  };
  const getFeedbackForm = async (therapistId) => {
    try {
      const res = await getFeedbackFormRequestsForClient(
        authorization,
        therapistId
      );
      if (res.length > 0) {
        nav(`/feedbackForm/${res[ res.length -1 ].formId}`);
      } else {
        addNotification(
          'fail',
          "You don't have any intake form assigned by your therapist, for more information please contact your therapist."
        );
      }
    } catch (error) {
      await handleError(error, nav, dispatch);
    }
  };
  const viewIntakeForm = async (therapistId) => {
    try {
      // const res = await getFormResponseForClient(
      //   authorization,
      //   therapistId,
      //   user._id
      // );
      // if (res.length > 0) {
        nav(`/myResponse/${therapistId}/${user._id}`);
      // } else {
      //   addNotification(
      //     'fail',
      //     "You haven't submitted any form for this therapist, for more information please contact your therapist."
      //   );
      // }
    } catch (error) {
      await handleError(error, nav, dispatch);
    }
  };

  useEffect(() => {
    const initializeData = async () => {
      setIsLoading(true);
      await fetchTherapists();
      await fetchUser();
    };
    initializeData();
  }, [fetchTherapists]);

  const handleMenuClick = (therapistId, { key }) => {
    switch (key) {
      case 'bookFollowUp':
        nav(`/therapist/${therapistId}`);
        break;
      case 'intakeForm':
        getIntakeForm(therapistId);
        break;
      case 'viewResponses':
        viewIntakeForm(therapistId);
        break;
      case 'viewConversations':
        nav(`/conversations/${therapistId}`);
        break;
      case 'feedbackForm':
        getFeedbackForm(therapistId);
        break;
      default:
        break;
    }
  };

    const menu = (therapistId) => (
        <Menu onClick={(e) => handleMenuClick(therapistId, e)}>
            <Menu.Item key="bookFollowUp">
                Book Follow Up
            </Menu.Item>
            <Menu.Item key="intakeForm">
                Intake Form
            </Menu.Item>
            <Menu.Item key="viewResponses">
                View Responses
            </Menu.Item>
            <Menu.Item key="viewConversations">
                View Conversations
            </Menu.Item>
        </Menu>
    );

  const columns = [
    {
      title: 'Appointment Type',
      dataIndex: 'appointmentType',
      key: 'appointmentType',
      render: (text) => text.charAt(0).toUpperCase() + text.slice(1),
    },
    {
      title: 'Date & Time',
      dataIndex: 'startTime',
      key: 'startTime',
      render: (text) => moment(text).format('MMMM Do YYYY, h:mm a'),
      sorter: (a, b) => moment(b.startTime).unix() - moment(a.startTime).unix(),
    },
    {
      title: 'Duration',
      dataIndex: 'appointmentLength',
      key: 'appointmentLength',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
  ];

  return (
    <>
      <Main>
        {isLoading ? (
          <div className='h-full content-center'>
            <Spin tip='Loading' size='large'>
              <div
                style={{
                  borderRadius: 4,
                }}
              />
            </Spin>
          </div>
        ) : therapists.length === 0 ? (
          <p>No therapists found.</p>
        ) : (
          <>
            <div className='layout-content flex flex-col w-96 md:w-9/12 h-152 m-auto relative overflow-x-hidden bg-white shadow-lg rounded-xl p-8'>
              <div className='flex flex-col items-start gap-6 w-full'>
                <span className='text-black items-center font-semibold text-4xl mr-6'>
                  My Therapists
                </span>
                <Collapse accordion className='mb-4 w-full'>
                  {therapists.map((therapist) => (
                    <Panel
                      header={
                        <div className='flex justify-between items-center'>
                          <span className='flex-grow'>
                            {therapist.givenName} {therapist.familyName}
                          </span>
                          <div className='flex items-center gap-4'>
                            <Tooltip title='Book Follow Up'>
                              <BookOutlined
                                onClick={() =>
                                  handleMenuClick(therapist._id, {
                                    key: 'bookFollowUp',
                                  })
                                }
                              />
                            </Tooltip>
                            <Tooltip title='Intake Form'>
                              <FormOutlined
                                onClick={() =>
                                  handleMenuClick(therapist._id, {
                                    key: 'intakeForm',
                                  })
                                }
                              />
                            </Tooltip>
                            <Tooltip title='Feedback Form'>
                              <FormOutlined
                                onClick={() =>
                                  handleMenuClick(therapist._id, {
                                    key: 'feedbackForm',
                                  })
                                }
                              />
                            </Tooltip>
                            <Tooltip title='View Responses'>
                              <FileTextOutlined
                                onClick={() =>
                                  handleMenuClick(therapist._id, {
                                    key: 'viewResponses',
                                  })
                                }
                              />
                            </Tooltip>
                            <Tooltip title='View Conversations'>
                            <Badge
                            count={
                              totalMessagecount?.unreadCountBySender?.find(
                                (sender) => sender._id === therapist._id
                              )?.unreadCount || 0
                            }
                            offset={[10, 0]}
                            size="small"
                          >
                            <MessageOutlined
                              style={{ fontSize: '', cursor: 'pointer' }}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleMenuClick(therapist._id, { key: "viewConversations" });
                              }}
                            />
                          </Badge>
                            </Tooltip>
                            <Dropdown
                              overlay={menu(therapist._id)}
                              trigger={['click']}
                              placement='bottomRight'
                            >
                              <Space>
                                <EllipsisOutlined
                                  style={{ fontSize: '28px' }}
                                />
                              </Space>
                            </Dropdown>
                          </div>
                        </div>
                      }
                      key={therapist._id}
                    >
                      {appointments.length > 0 ? (
                        <Table
                          dataSource={appointments.filter(
                            (i) => i.therapistId === therapist._id
                          )}
                          columns={columns}
                          rowKey='_id'
                          pagination={false}
                        />
                      ) : (
                        <div>No Appointments</div>
                      )}
                    </Panel>
                  ))}
                </Collapse>
              </div>
            </div>
          </>
        )}
      </Main>
      <Footer />
    </>
  );
}

export default MyTherapist;
