import React, { useEffect, useState } from "react";
import { notification, Spin, Rate, Slider } from "antd"; // Import Ant Design's Rate component
import { useNavigate, useParams } from "react-router-dom";
import { getFormById, saveResponse, uploadFile } from "../Network/IntakeForm";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserDetails } from "../Network/Client";
import Cookies from "js-cookie";
import { handleError } from "../ErrorHandling/handleError";
import Main from "../components/layout/Main";

function QuestionnaireForm() {
  const nav = useNavigate();
  const dispatch = useDispatch();
  const { formId } = useParams();
  const authorization = useSelector(
    (state) => state.combined.auth.authorization
  );

  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [responses, setResponses] = useState([]);
  const [userDetails, setUserDetails] = useState({});

  const MAX_FILE_SIZE = 10 * 1024 * 1024;

  useEffect(() => {
    const fetchFormData = async () => {
      try {
        setIsLoading(true);
        const res = await getFormById(formId);
        setFormData(res);

        const initialResponses = res.questions.map((question) => ({
          componentType: question.componentType,
          key: question.key,
          label: question.label,
          placeholder: question.placeholder,
          options: question.options,
          response: "",
          isRequired: question.isRequired,
        }));
        setResponses(initialResponses);
      } catch (error) {
        setIsLoading(false);
        await handleError(error, nav, dispatch);
      } finally {
        setIsLoading(false);
      }
    };
    fetchFormData();
  }, [formId]);

  useEffect(() => {
    if (!authorization) {
      Cookies.set("redirectTo", `intakeFrom/${formId}`, { path: "/" });
      nav("/login");
    } else {
      const fetchUser = async () => {
        try {
          setIsLoading(true);
          const res = await fetchUserDetails(authorization);
          setUserDetails(res);
        } catch (error) {
          setIsLoading(false);
          await handleError(error, nav, dispatch);
        } finally {
          setIsLoading(false);
        }
      };
      fetchUser();
    }
  }, [authorization]);

  const handleChange = (key, value) => {
    setResponses((prevResponses) =>
      prevResponses.map((response) =>
        response.key === key ? { ...response, response: value } : response
      )
    );
  };

  const handleFileUpload = (key, file) => {
    if (file?.size > MAX_FILE_SIZE) {
      addNotification("fail", "File size exceeds 10 MB");
      return;
    }
    handleChange(key, file);
  };

  const addNotification = (type, message) => {
    notification.open({
      message: type === "success" ? "Success" : "Failure",
      description: message,
      icon:
        type === "success" ? (
          <CheckCircleOutlined style={{ color: "#52c41a" }} />
        ) : (
          <CloseCircleOutlined style={{ color: "#f5222d" }} />
        ),
      duration: 3,
    });
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);

      const updatedResponses = await Promise.all(
        responses.map(async (response) => {
          if (response.isRequired && !response.response) {
            addNotification("fail", "Response missing: " + response.label);
            throw new Error(
              "response missing on mandatory question: " + response.label
            );
          }

          if (response.componentType === "fileUpload" && response.response) {
            const formData = new FormData();
            formData.append("media", response.response);
            const uploadedFile = await uploadFile(formData, authorization);
            return { ...response, response: uploadedFile[0] };
          } else {
            return response;
          }
        })
      );

      const formResponse = {
        formId: formId,
        keyName: formData.keyName,
        therapistId: formData.therapistId,
        clientId: userDetails._id,
        responses: updatedResponses,
      };
      console.log(formResponse, "FORMRESPONSE");
      await saveResponse(
        formResponse.formId,
        formResponse.therapistId,
        formResponse.clientId,
        formResponse
      );
      addNotification("success", "Response saved successfully");
    } catch (err) {
      addNotification("fail", "Response not saved");
      await handleError(err, nav, dispatch);
    } finally {
      setIsLoading(false);
    }
  };

  const renderInputField = (question) => {
    const currentResponse = responses.find(
      (response) => response.key === question.key
    );
    if (!currentResponse) return null;

    switch (question.componentType) {
      case "text":
        return (
          <input
            type="text"
            value={currentResponse.response}
            onChange={(e) => handleChange(question.key, e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        );
      case "multiple":
        return question.options.map((option, idx) => (
          <label key={idx} className="inline-flex items-center mt-3">
            <input
              type="checkbox"
              value={option}
              checked={currentResponse.response?.includes(option) || false}
              onChange={(e) => {
                const newValues = currentResponse.response?.includes(option)
                  ? currentResponse.response.filter((val) => val !== option)
                  : [...(currentResponse.response || []), option];
                handleChange(question.key, newValues);
              }}
              className="hover:cursor-pointer form-checkbox h-5 w-5 text-gray-600"
            />
            <span className="mx-2 text-gray-700">{option}</span>
          </label>
        ));
      case "linearScale":
        return (
          <input
            type="range"
            min="1"
            max="10"
            value={currentResponse.response}
            onChange={(e) => handleChange(question.key, e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        );
      case "dropdown":
        return (
          <select
            value={currentResponse.response}
            onChange={(e) => handleChange(question.key, e.target.value)}
            className="block hover:cursor-pointer appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
          >
            <option value="" disabled>
              Select an option
            </option>
            {question.options.map((option, idx) => (
              <option key={idx} value={option}>
                {option}
              </option>
            ))}
          </select>
        );
      case "checkbox":
        return question.options.map((option, idx) => (
          <label key={idx} className="inline-flex items-center mt-3">
            <input
              type="checkbox"
              value={option}
              checked={currentResponse.response?.includes(option) || false}
              onChange={(e) => {
                const newValues = currentResponse.response?.includes(option)
                  ? currentResponse.response.filter((val) => val !== option)
                  : [...(currentResponse.response || []), option];
                handleChange(question.key, newValues);
              }}
              className="hover:cursor-pointer form-checkbox h-5 w-5 text-gray-600"
            />
            <span className="ml-2 text-gray-700">{option}</span>
          </label>
        ));
      case "date":
        return (
          <input
            type="date"
            value={currentResponse.response}
            onChange={(e) => handleChange(question.key, e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        );
      case "time":
        return (
          <input
            type="time"
            value={currentResponse.response}
            onChange={(e) => handleChange(question.key, e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        );
      case "fileUpload":
        return (
          <input
            type="file"
            onChange={(e) => handleFileUpload(question.key, e.target.files[0])}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        );
      case "rating":
        return (
          <div className="flex items-center">
            <span className="mr-2 font-bold">{question.label}:</span>
            <Rate
              tooltips={question.options}
              value={
                currentResponse.response
                  ? question.options.indexOf(currentResponse.response) + 1
                  : 0
              }
              onChange={(value) =>
                handleChange(question.key, question.options[value - 1])
              }
              count={question.options.length}
            />
          </div>
        );
      case "slider":
        return (
          <div className="px-6">
            <Slider
              min={0}
              max={question.options.length - 1}
              marks={question.options.reduce((acc, option, index) => {
                acc[index] = option;
                return acc;
              }, {})}
              step={1}
              value={question.options.indexOf(currentResponse.response)}
              onChange={(value) =>
                handleChange(question.key, question.options[value])
              }
            />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <Main>
      {isLoading ? (
        <div className="h-full content-center">
          <Spin tip="Loading" size="large">
            <div
              style={{
                borderRadius: 4,
              }}
            />
          </Spin>
        </div>
      ) : (
        <>
          <div className="layout-content flex flex-col w-96 md:w-9/12 h-152 m-auto relative overflow-x-hidden bg-white shadow-lg rounded-xl p-8">
            <h3 className="mb-4 text-xl">Questionnaire Form</h3>

            {formData.questions?.map((question, index) => (
              <div key={index} className="mb-1">
                {question.componentType !== "rating" && (
                  <label className="block mb-1 mt-1 text-gray-700 text-sm font-bold">
                    {question.label}
                  </label>
                )}
                {renderInputField(question)}
              </div>
            ))}

            <button
              onClick={handleSubmit}
              className="bg-blue-500 text-white px-4 py-2 rounded mt-4 hover:bg-blue-600"
            >
              Submit
            </button>
          </div>
        </>
      )}
    </Main>
  );
}

export default QuestionnaireForm;
