import React from 'react';
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaWhatsapp,
} from 'react-icons/fa';

import logoOriginalTransparent from '../../assets/images/logoOriginalTransparent.png';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const navigate = useNavigate();
  return (
    <footer id='footer' className=' bg-gray-100 text-black py-8'>
      <div className='container justify-start lg:justify-around items-start mx-auto px-4 flex flex-wrap gap-8 lg:gap-12 '>
        <div className='flex justify-start flex-col'>
          <img
            className='h-16 w-24'
            src={logoOriginalTransparent}
            alt='Your Company'
          />
          <h3 className='text-lg mt-4 text-black font-semibold libre-baskerville-bold '>
            KASICARE
          </h3>
          <div className='mt-2'>
            <p className='mb-1'>Sector 81, Gurugram, Haryana, 122004</p>
            <p className='mb-1'>support@kasi.care</p>
            <p className='p-0 m-0'>7042707775</p>
          </div>
        </div>
        <div>
          <h4 className='text-lg text-black font-bold mb-4 libre-baskerville-bold '>
            Company
          </h4>
          <ul className='space-y-2'>
            <li>
              <a
                href='https://www.kasicare.in/privacy-policy'
                className='text-black hover:font-bold hover:text-black hover:underline'
              >
                Privacy Policy
              </a>
            </li>
            <li>
              <a
                href='https://www.kasicare.in/terms-of-use'
                className='text-black  hover:font-bold hover:text-black  hover:underline'
              >
                Terms of Use
              </a>
            </li>
            <li>
              <a
                href='https://www.kasicare.in/copy-of-therapist-refund-and-cancella'
                className='text-black  hover:font-bold hover:text-black  hover:underline'
              >
                Refund & Cancellation Policy
              </a>
            </li>{' '}
            <li>
              <a
                href='mailto:support@kasi.care?subject=Hello&body=I%20need%20help%20regarding.'
                className='text-black hover:font-bold hover:text-black decoration-black hover:underline'
              >
                Help
              </a>
            </li>
          </ul>
        </div>

        {/* Get Started */}
        <div>
          <h4 className='text-lg text-black font-semibold mb-4 libre-baskerville-bold'>
            Get Started
          </h4>
          <ul className='space-y-2'>
            <li>
              <a
                href='https://api.whatsapp.com/send?phone=%2B917042707775'
                className='text-black hover:font-bold hover:text-black hover:underline'
              >
                Contact on WhatsApp
              </a>
            </li>
            {/* <li>
              <a
                href='https://www.kasicare.in/pricing'
                className='text-black hover:font-bold hover:text-black hover:underline'
              >
                Start a Free Trial
              </a>
            </li> */}
            <li>
              <button
                onClick={() => {
                  navigate('/about');
                }}
                href='https://www.kasicare.in/pricing'
                className='text-black hover:font-bold hover:text-black hover:underline'
              >
                About Us
              </button>
            </li>
            <li>
              <a
                href='#faq'
                className='text-black hover:font-bold hover:text-black hover:underline'
              >
                FAQ
              </a>
            </li>
          </ul>
        </div>
      </div>

      {/* Social Icons */}
      <div className='mt-8 border-t border-black pt-4 px-4 md:px-6'>
        <div className='container mx-auto px-0 md:px-4 flex justify-between items-center'>
          <div className='text-sm libre-baskerville-bold'>
            &copy; {new Date().getFullYear()} by KasiCare. All rights reserved.
          </div>
          <div className='flex space-x-4'>
            <a
              href='https://www.facebook.com/people/Kasicare/61565055515178/'
              className='text-black hover:text-blue-500'
            >
              <FaFacebookF />
            </a>
            <a
              href='https://www.instagram.com/kasi.care_official/'
              className='text-black hover:text-pink-500'
            >
              <FaInstagram />
            </a>
            <a
              href='https://www.linkedin.com/company/kasicare/posts/?feedView=all'
              className='text-black hover:text-blue-700'
            >
              <FaLinkedinIn />
            </a>
            <a
              href='https://api.whatsapp.com/send?phone=%2B917042707775'
              className='text-black hover:text-green-500'
            >
              <FaWhatsapp />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
