import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Spin, notification } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

import { getAppointments } from '../Network/Appointment';
import { handleError } from '../ErrorHandling/handleError';
import Main from '../components/layout/Main';
import AppointmentsHistory from '../components/AppointmentsHistory'; // Assuming this is the correct path
import Footer from '../components/layout/Footer';

function Appointments() {
  const dispatch = useDispatch();
  const nav = useNavigate();
  const authorization = useSelector(
    (state) => state.combined.auth.authorization
  );
  const [appointments, setAppointments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState(false); // Added for refreshing appointments history
  useEffect(() => {
    const fetchAppointments = async () => {
      try {
        const res = await getAppointments(authorization);
        setAppointments(res);
      } catch (error) {
        setIsLoading(false);
        await handleError(error, nav, dispatch);
      } finally {
        setIsLoading(false);
      }
    };
    setIsLoading(true);
    fetchAppointments();
  }, [authorization, nav, dispatch, refresh]);

  const addNotification = (type, message) => {
    notification.open({
      message: type === 'success' ? 'Success' : 'Failure',
      description: message,
      icon:
        type === 'success' ? (
          <CheckCircleOutlined style={{ color: '#52c41a' }} />
        ) : (
          <CloseCircleOutlined style={{ color: '#f5222d' }} />
        ),
      duration: 3,
    });
  };

  return (
    <div>
      <Main>
        {isLoading ? (
          <div className='spinner-container'>
            <Spin size='large' />
          </div>
        ) : (
          <div className='appointments-container'>
            <Card
              bordered={false}
              className='header-solid h-full card-profile-information text-lg'
              bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}
            >
              <AppointmentsHistory
                appointments={appointments}
                isLoading={isLoading}
                authorization={authorization}
                setRefresh={setRefresh} // Allow refreshing the appointments
                addNotification={addNotification}
              />
            </Card>
          </div>
        )}
      </Main>
      <Footer />
    </div>
  );
}

export default Appointments;
