import { handleApiErrors } from "../ErrorHandling/handleApiErrors";

const getFormById = async (formId) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_HOST}/api/forms/getFormById`,
    {
      credentials: "include",
      method: "POST",
      withCredentials: true,
      body: JSON.stringify({ formId: formId }),
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  if (!response.ok) {
    await handleApiErrors(response);
  }
  return await response.json();
};

const saveResponse = async (formId, therapistId, clientId, formResponse) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_HOST}/api/forms/saveResponse`,
    {
      credentials: "include",
      method: "POST",
      withCredentials: true,
      body: JSON.stringify({
        formId: formId,
        therapistId: therapistId,
        clientId: clientId,
        responseData: formResponse,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  if (!response.ok) {
    await handleApiErrors(response);
  }
  return await response.json();
};

const uploadFile = async (media, authorization) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_HOST}/api/forms/uploadFile`,
    {
      credentials: "include",
      method: "POST",
      withCredentials: true,
      body: media,
      headers: {
        authorization: `Bearer ${authorization}`,
      },
    }
  );
  if (!response.ok) {
    await handleApiErrors(response);
  }
  return await response.json();
};

const getIntakeFormRequestsForClient = async (authorization, therapistId) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_HOST}/api/forms/getIntakeFormRequestsForClient`,
    {
      credentials: "include",
      method: "POST",
      withCredentials: true,
      body: JSON.stringify({ therapistId: therapistId }),
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${authorization}`,
      },
    }
  );
  if (!response.ok) {
    await handleApiErrors(response);
  }
  return await response.json();
};

const getFeedbackFormRequestsForClient = async (authorization, therapistId) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_HOST}/api/forms/getFeedbackFormRequestsForClient`,
    {
      credentials: "include",
      method: "POST",
      withCredentials: true,
      body: JSON.stringify({ therapistId: therapistId }),
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${authorization}`,
      },
    }
  );
  if (!response.ok) {
    await handleApiErrors(response);
  }
  return await response.json();
};
const getFormResponseForClient = async (authorization, data) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_HOST}/api/forms/getFormResponse`,
    {
      credentials: "include",
      method: "POST",
      withCredentials: true,
      body: JSON.stringify({
        therapistId: data.therapistId,
        clientId: data.clientId,
        // page: data.page,       // Include page number
        // limit: data.limit,     // Include limit for pagination
        // filter: filter,    // Optional filter
        formType: "Intake",
      }),
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${authorization}`,
      },
    }
  );
  if (!response.ok) {
    await handleApiErrors(response);
  }
  return await response.json();
};
const getIntakeFormRequestById = async (authorization, formreqId) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_HOST}/api/forms/getIntakeFormRequestById`,
    {
      credentials: "include",
      method: "POST",
      withCredentials: true,
      body: { requestId: formreqId },
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${authorization}`,
      },
    }
  );
  if (!response.ok) {
    await handleApiErrors(response);
  }
  return await response.json()
};
// const getFormResponseForClient = async (authorization, therapistId, clientId) => {
//   const response = await fetch(
//     `${process.env.REACT_APP_API_HOST}/api/forms/getFormResponse`,
//     {
//       credentials: "include",
//       method: "POST",
//       withCredentials: true,
//       body: JSON.stringify({ therapistId: therapistId, clientId: clientId }),
//       headers: {
//         "Content-Type": "application/json",
//         authorization: `Bearer ${authorization}`,
//       },
//     }
//   );
//   if (!response.ok) {
//     await handleApiErrors(response)
//   }
//   return await response.json();
// };
const getQuestionnaireFormRequestsForClient = async (
  authorization,
  therapistId
) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_HOST}/api/forms/getQuestionnaireFormRequestsForClient`,
    {
      credentials: "include",
      method: "POST",
      withCredentials: true,
      body: JSON.stringify({ therapistId: therapistId }),
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${authorization}`,
      },
    }
  );
  if (!response.ok) {
    await handleApiErrors(response);
  }
  return await response.json();
};
const getFormResponseById = async (authorization, clientId, formId) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_HOST}/api/forms/getFormResponseById`,
    {
      credentials: "include",
      method: "POST",
      withCredentials: true,
      body: JSON.stringify({ clientId: clientId, formId: formId }),
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${authorization}`,
      },
    }
  );
  if (!response.ok) {
    await handleApiErrors(response);
  }
  return await response.json();
};

export {
  getFormById,
  saveResponse,
  getIntakeFormRequestsForClient,
  getIntakeFormRequestById,
  getFeedbackFormRequestsForClient,
  uploadFile,
  getQuestionnaireFormRequestsForClient,
  getFormResponseById,
  getFormResponseForClient
};
