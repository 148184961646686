import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import verified from '../assets/images/verified.png';
import { notification } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import { genOtp, verifyOtp } from '../Network/Auth';
import { setAuthorization } from '../redux/combinedSlice';
import Cookies from 'js-cookie';
import { handleError } from '../ErrorHandling/handleError';
import moment from 'moment';

function SignUp() {
  const nav = useNavigate();
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [auth, setAuth] = useState(null);
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [isPhoneVerified, setIsPhoneVerified] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [isResendEnabled, setIsResendEnabled] = useState(false);
  const [timer, setTimer] = useState(30);
  const [isCalendarSynced, setIsCalendarSynced] = useState(false);

  useEffect(() => {
    let countdown;
    if (otpSent && timer > 0) {
      countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      setIsResendEnabled(true);
      clearInterval(countdown);
    }
    return () => clearInterval(countdown);
  }, [otpSent, timer]);

  const setCookie = (name, value, expires, path = '/', sameSite = 'Lax') => {
    document.cookie = `${name}=${encodeURIComponent(
      value
    )}; expires=${moment(expires)}; path=${path}; SameSite=${sameSite}`;
  };

  const getCookie = (cookieName) => {
    const cookies = document.cookie.split('; ');
    let decodedCookie;
    cookies.forEach((cookie) => {
      if (cookie.startsWith(cookieName)) {
        decodedCookie = decodeURIComponent(cookie.split('=')[1]);
      }
    });
    return decodedCookie;
  };

  const handleVerificationSuccess = (response) => {
    if (response.success) {
      setCookie(
        response.cookieName,
        response.authToken,
        2,
        response.cookieDetails.path,
        response.cookieDetails.sameSite
      );

      dispatch(setAuthorization(response.authToken));

      setAuth(response.authToken);

      const redirectCookie = getCookie('redirectTo');
      if (redirectCookie) {
        Cookies.remove('redirectTo', { path: '/' });
        nav(`/${redirectCookie}`);
      } else {
        nav('/login');
      }
    }
  };

  const validateFields = () => {
    let isValid = true;

    if (!firstName.trim()) {
      setFirstNameError('First name is required');
      isValid = false;
    } else {
      setFirstNameError('');
    }

    if (!lastName.trim()) {
      setLastNameError('Last name is required');
      isValid = false;
    } else {
      setLastNameError('');
    }

    if (!phoneNumber.trim()) {
      setPhoneError('Phone number is required');
      isValid = false;
    } else {
      setPhoneError('');
    }

    return isValid;
  };

  const addNotification = (type, message) => {
    notification.open({
      message: type === 'success' ? 'Success' : 'Failure',
      description: message,
      icon:
        type === 'success' ? (
          <CheckCircleOutlined style={{ color: '#52c41a' }} />
        ) : (
          <CloseCircleOutlined style={{ color: '#f5222d' }} />
        ),
      duration: 3,
    });
  };

  const handleSendOtp = async () => {
    try {
      if (validateFields()) {
        const res = await genOtp(phoneNumber, 'client');
        setOtpSent(true);
        addNotification('success', 'OTP Sent to your mobile number');
      }
    } catch (error) {
      handleError(error, nav, dispatch);
    }
  };

  const handleResendOtp = async () => {
    try {
      if (validateFields()) {
        const res = await genOtp(phoneNumber, 'client');
        setOtpSent(true);
        setTimer(60);
        setIsResendEnabled(false);
        addNotification('success', 'OTP Sent to your mobile number');
      }
    } catch (error) {
      handleError(error, nav, dispatch);
    }
  };

  const handleVerifyOtp = async () => {
    try {
      const res = await verifyOtp(
        phoneNumber,
        'client',
        firstName,
        lastName,
        otp
      );
      handleVerificationSuccess(res);
      setIsPhoneVerified(true);
      addNotification('success', 'OTP Verified successfully');
    } catch (error) {
      handleError(error, nav, dispatch);
    }
  };

  return (
    <div className='flex flex-row justify-center items-center'>
      <div className='md:w-8/12 w-full'>
        <div className='mt-5 flex flex-col'>
          <label
            htmlFor='firstName'
            className='poppins-regular mb-1 block text-[12px] font-medium text-gray-700'
          >
            First Name
          </label>
          <input
            type='text'
            id='firstName'
            value={firstName}
            disabled={isPhoneVerified}
            placeholder='Enter First Name'
            onChange={(e) => setFirstName(e.target.value)}
            className='mt-1 block w-full px-3 py-2  rounded-md border-none focus:outline-none sm:text-sm'
          />
          {firstNameError && (
            <p className='errors mt-1 text-xs'>{firstNameError}</p>
          )}

          <label
            htmlFor='lastName'
            className='poppins-regular mt-4 mb-1 block text-[12px] font-medium text-gray-700'
          >
            Last Name
          </label>
          <input
            type='text'
            id='lastName'
            value={lastName}
            placeholder='Enter Last Name'
            disabled={isPhoneVerified}
            onChange={(e) => setLastName(e.target.value)}
            className='mt-1 block w-full px-3 py-2 border-none rounded-mdfocus:outline-none sm:text-sm'
          />
          {lastNameError && (
            <p className='errors mt-1 text-xs'>{lastNameError}</p>
          )}
          <label
            htmlFor='phone'
            className='poppins-regular mt-4 mb-3 block text-[12px] font-medium text-gray-700'
          >
            Enter Your Mobile Number
          </label>
          <PhoneInput
            country={'in'}
            className='phone-input'
            value={phoneNumber}
            placeholder='10 Digit Mobile Number'
            onChange={(value) => setPhoneNumber(value)}
            disabled={isPhoneVerified}
          />
          {phoneError && <p className='errors mt-1 text-xs'>{phoneError}</p>}
          {!otpSent && !isPhoneVerified && (
            <button
              onClick={handleSendOtp}
              className='mt-3 w-full rounded-lg bg-green-700 text-white py-2 px-4 text-sm hover:bg-blue-700'
            >
              Continue
            </button>
          )}

          {otpSent && !isPhoneVerified && (
            <>
              <label
                htmlFor='otp'
                className='transition-all duration-300 block text-sm font-medium text-gray-700 mt-4'
              >
                Enter OTP
              </label>
              <input
                type='text'
                id='otp'
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                className='transition-all duration-300 mt-3 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none sm:text-sm'
                placeholder='Enter OTP'
              />
              <div>
                <button
                  onClick={handleVerifyOtp}
                  className='transition-all duration-300 mt-4 w-full rounded-lg bg-green-600 text-white py-2 px-4 text-sm font-semibold hover:bg-green-500'
                >
                  Verify OTP
                </button>
                <p className='mt-4 text-center text-[12px] text-gray-500'>
                  By clicking “Verify”, you agree to PracFlow’s Terms of Service
                  and Privacy Policy.
                </p>
              </div>
              {isResendEnabled && (
                <button
                  onClick={handleResendOtp}
                  className='transition-all duration-300 mt-3 w-full rounded-lg bg-blue-900 text-white py-2 px-4 text-sm font-semibold hover:bg-blue-700'
                >
                  Resend OTP
                </button>
              )}
              <div className='text-center text-sm mt-4'>
                <p>Resend OTP in: {timer} seconds</p>
              </div>
            </>
          )}

          {isPhoneVerified && (
            <p className='mt-3 mb-3 flex items-center gap-2 text-green-600 text-[12px] font-medium'>
              <span>
                <img className='h-4' src={verified} alt='Verified' />
              </span>
              Phone number verified successfully!
            </p>
          )}

          {isPhoneVerified && !isCalendarSynced && (
            <a
              href={`${
                process.env.REACT_APP_API_HOST
              }/sync/google/client?authToken=${encodeURIComponent(
                String(auth)
              )}`}
              className='w-full text-center self-center mx-auto hover:text-white rounded-lg bg-blue-900 text-white py-2 px-4 text-sm'
            >
              Sync with Google Calender
            </a>
          )}
        </div>
      </div>
    </div>
  );
}

export default SignUp;
