import React, { useEffect, useState } from "react";
import { Spin, Modal, notification } from "antd";
import {
  getAssignmentByIdForClient,
  getAssignmentResponseById,
} from "../Network/MyAssignments";
import { getResourceByIdForClient } from "../Network/Resource";
import {
  EyeOutlined,
  DownloadOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { handleError } from "../ErrorHandling/handleError";
import { getFormById, getFormResponseById } from "../Network/IntakeForm"; 
import { fetchUserDetails } from "../Network/Client";
function ViewSubmittedAssignment() {
  const [isLoading, setIsLoading] = useState(false);
  const [assignment, setAssignment] = useState(null);
  const [resources, setResources] = useState([]);
  const [clientAnswer, setClientAnswer] = useState("");
  const [mediaList, setMediaList] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewContent, setPreviewContent] = useState(null);
  const [feedback, setFeedback] = useState();
  const [formTitles, setFormTitles] = useState({}); 
  const [user, setUser] = useState([]);
  const [selectedFormResponses, setSelectedFormResponses] = useState([]); 
  const [isResponseModalVisible, setIsResponseModalVisible] = useState(false);
  const authorization = useSelector(
    (state) => state.combined.auth.authorization
  );
  const nav = useNavigate();
  const dispatch = useDispatch();
  const { assignmentId } = useParams();

  useEffect(() => {
    if (assignmentId) {
      const fetchAssignmentDetails = async () => {
        setIsLoading(true);
        try {
          const res = await getAssignmentByIdForClient(
            authorization,
            assignmentId
          );

          if (res) {
            setAssignment(res);

            const formTitlesTemp = {}; 
            const fetchedResources = await Promise.all(
              res.resourceIds.map(async (resourceId) => {
                const resource = await getResourceByIdForClient(
                  authorization,
                  resourceId
                );
              
                if (resource?.formIds?.length > 0) {
                  const titles = await Promise.all(
                    resource.formIds.map(async (formId) => {
                      const form = await getFormById(formId);
                      return { formId, title: form.title };
                    })
                  );
                  formTitlesTemp[resourceId] = titles;
                }

                return resource;
              })
            );

            setResources(fetchedResources);
            setFormTitles(formTitlesTemp);

            const assignmentResponse = await getAssignmentResponseById(
              authorization,
              assignmentId
            );
            if (assignmentResponse) {
              setClientAnswer(assignmentResponse.clientAnswer || "");
              setMediaList(
                (assignmentResponse.media || []).map((file) => ({
                  uid: file._id,
                  name: file.name,
                  status: "done",
                  url: file.s3Url,
                  thumbUrl: file.s3Url,
                  mediaType: file.mediaType,
                }))
              );
              setFeedback(assignmentResponse.feedback);
            }
          }
        } catch (error) {
          await handleError(error, nav, dispatch);
        } finally {
          setIsLoading(false);
        }
      };
      fetchAssignmentDetails();
    }
  }, [assignmentId, authorization, nav, dispatch]);

  const addNotification = (type, message) => {
    notification.open({
      message: type === "success" ? "Success" : "Failure",
      description: message,
      icon:
        type === "success" ? (
          <CheckCircleOutlined style={{ color: "#52c41a" }} />
        ) : (
          <CloseCircleOutlined style={{ color: "#f5222d" }} />
        ),
      duration: 3,
    });
  };

  const handleViewResponse = async (clientId, formId) => {
    try {
      const res = await getFormResponseById(authorization, clientId, formId);
      if (res && res.formResponse) {
        setSelectedFormResponses(res.formResponse.responses);
        setIsResponseModalVisible(true);
      } else {
        addNotification("fail", "No responses found for this form.");
      }
    } catch (error) {
      await handleError(error, nav, dispatch);
    }
  };

  const handlePreview = (file) => {
    setPreviewContent(file);
    setPreviewVisible(true);
  };

  const closePreviewModal = () => {
    setPreviewVisible(false);
    setPreviewContent(null);
  };
  const fetchUser = async () => {
    if (!authorization) return;
    const allDetails = await fetchUserDetails(authorization);
    setUser(allDetails);
  };
  useEffect(() => {
    const initializeData = async () => {
      await fetchUser();
    };
    initializeData();
  }, []);

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100 p-6">
      {isLoading ? (
        <Spin tip="Loading" size="large">
          <div style={{ borderRadius: 4 }} />
        </Spin>
      ) : assignment ? (
        <div className="w-full max-w-2xl bg-white shadow-md rounded-lg p-6">
          <h1 className="text-gray-800 text-xl font-bold mb-4">
            Assignment Details
          </h1>

          <h2 className="text-black text-lg mt-4">Title</h2>
          <p>{assignment.title}</p>

          <h2 className="text-black text-lg mt-4">Description</h2>
          <p>{assignment.description}</p>

          <h2 className="text-black text-lg mt-4">Resources</h2>
          {resources.length > 0 ? (
            resources.map((resource, index) => (
              <div key={index} className="mb-4">
                <h3 className="text-black text-lg mt-4">{resource.title}</h3>
                <p className="text-gray-700">{resource.description}</p>
                {resource.linkUrls?.length > 0 && resource.linkUrls != [""] && (
                  <div className="mb-4">
                    <h2 className="text-black text-lg">Resource Link URLs</h2>
                    <ul>
                      {resource.linkUrls.map((link, index) => (
                        <li key={index}>
                          <a
                            href={link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-500 underline"
                          >
                            {link}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}

                {resource.media?.length > 0 &&
                  resource.media.map((file, fileIndex) => (
                    <div
                      key={fileIndex}
                      className="flex items-center justify-between mb-2"
                    >
                      <div className="flex items-center">
                        {file.mediaType?.startsWith("image/") && (
                          <img
                            src={file.s3Url}
                            alt={file.name}
                            className="w-16 h-16 mr-2 rounded-md border"
                          />
                        )}
                        {file.mediaType?.startsWith("video/") && (
                          <video className="w-16 h-16 mr-2 rounded-md border">
                            <source src={file.s3Url} type={file.mediaType} />
                          </video>
                        )}
                        <span className="truncate w-40">{file.name}</span>
                      </div>
                      <div className="flex items-center space-x-2">
                        <button
                          type="primary"
                          className="flex items-center justify-center w-10 h-10 border border-blue-300 rounded-md bg-blue-500 text-white hover:bg-blue-600"
                          onClick={() => handlePreview(file)}
                        >
                          <EyeOutlined />
                        </button>
                        <button
                          type="primary"
                          className="flex items-center justify-center w-10 h-10 border border-blue-300 rounded-md bg-blue-500 text-white hover:bg-blue-600"
                          onClick={() => {
                            const link = document.createElement("a");
                            link.href = file.s3Url;
                            link.setAttribute("download", file.name);
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                          }}
                        >
                          <DownloadOutlined />
                        </button>
                      </div>
                    </div>
                  ))}
                {resource?.formIds?.length > 0 && formTitles[resource._id] && (
                  <div className="mt-2">
                    <h3 className="text-md font-bold">Forms:</h3>
                    <ul className="list-disc pl-5">
                      {formTitles[resource._id].map(
                        ({ formId, title }, index) => (
                          <li
                            key={index}
                            className="text-gray-700 flex justify-between items-center"
                          >
                            <span>{title}</span>
                            <button
                              type="button"
                              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                              onClick={() =>
                                handleViewResponse(user._id, formId)
                              } 
                            >
                              View Response
                            </button>
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                )}
              </div>
            ))
          ) : (
            <p>No resources available</p>
          )}

          <h2 className="text-black text-lg mt-4">Your Answer</h2>
          <div className="border p-4 rounded bg-gray-100 mb-2">
            {clientAnswer}
          </div>
          {mediaList.length > 0
            ? mediaList.map((file, fileIndex) => (
                <div
                  key={fileIndex}
                  className="flex items-center justify-between mb-2"
                >
                  <div className="flex items-center">
                    {file.mediaType?.startsWith("image/") && (
                      <img
                        src={file.url}
                        alt={file.name}
                        className="w-16 h-16 mr-2 rounded-md border"
                      />
                    )}
                    {file.mediaType?.startsWith("video/") && (
                      <video className="w-16 h-16 mr-2 rounded-md border">
                        <source src={file.url} type={file.mediaType} />
                      </video>
                    )}
                    {file.mediaType === "application/pdf" && (
                      <div className="w-16 h-16 mr-2 bg-gray-200 flex items-center justify-center">
                        <span className="text-gray-600">PDF</span>
                      </div>
                    )}
                    {file.mediaType === "text/plain" && (
                      <div className="w-16 h-16 mr-2 bg-gray-200 flex items-center justify-center">
                        <span className="text-gray-600">Text</span>
                      </div>
                    )}
                    <span className="truncate w-40">{file.name}</span>
                  </div>
                  <div className="flex items-center space-x-2">
                    <button
                      type="primary"
                      className="flex items-center justify-center w-10 h-10 border border-blue-300 rounded-md bg-blue-500 text-white hover:bg-blue-600"
                      onClick={() => handlePreview(file)}
                    >
                      <EyeOutlined />
                    </button>
                  </div>
                </div>
              ))
            : ""}
          {feedback && (
            <div>
              <h2 className="text-black text-lg mt-4">Your Answer</h2>
              <span className="text-lg">{feedback}</span>
            </div>
          )}
          <Modal
            title="Form Responses"
            visible={isResponseModalVisible}
            onCancel={() => setIsResponseModalVisible(false)}
            footer={null}
          >
            {selectedFormResponses.length > 0 ? (
              selectedFormResponses.map((response, index) => (
                <div key={index} className="mb-4 break-words">
                  <p>
                    <b>Question: </b> {response.label}
                  </p>
                  <p>
                    <b>Answer: </b>{" "}
                    {Array.isArray(response.response)
                      ? response.response.join(", ")
                      : response.response}
                  </p>
                </div>
              ))
            ) : (
              <p>No responses available.</p>
            )}
          </Modal>
          <Modal
            visible={previewVisible}
            onCancel={closePreviewModal}
            footer={null}
            width={800}
          >
            {previewContent && (
              <>
                {previewContent.mediaType.startsWith("image/") && (
                  <img
                    src={previewContent.s3Url}
                    alt={previewContent.name}
                    style={{ width: "60%" }}
                  />
                )}
                {previewContent.mediaType.startsWith("video/") && (
                  <video controls style={{ width: "100%" }}>
                    <source
                      src={previewContent.s3Url}
                      type={previewContent.mediaType}
                    />
                  </video>
                )}
                {previewContent.mediaType.startsWith("audio/") && (
                  <audio controls style={{ width: "100%" }}>
                    <source
                      src={previewContent.s3Url}
                      type={previewContent.mediaType}
                    />
                  </audio>
                )}
                {previewContent.mediaType === "application/pdf" && (
                  <iframe
                    src={previewContent.s3Url}
                    title={previewContent.name}
                    style={{ width: "100%", height: "500px" }}
                    frameBorder="0"
                  />
                )}
              </>
            )}
          </Modal>
        </div>
      ) : (
        <p>No assignment found</p>
      )}
    </div>
  );
}

export default ViewSubmittedAssignment;
