import React from 'react';

const ServiceCard = ({ title, description, image, bgColor, imageHeight }) => {
  return (
    <div
      className={`flex w-full flex-col md:flex-row justify-between items-center p-6 px-4 ${bgColor} rounded-2xl`}
    >
      <div className='text-left'>
        <h3 className='libre-baskerville-bold text-2xl font-bold text-gray-800'>
          {title}
        </h3>
        <p className='text-black w-9/12 md:text-[12px] text-sm leading-normal mb-4 md:mb-12'>
          {description}
        </p>
      </div>
      <img
        src={image}
        className={`h-${imageHeight} self-start md:self-end mt-4 md:mt-0 md:ml-2`}
        alt={title.toLowerCase()}
      />
    </div>
  );
};

export default ServiceCard;
