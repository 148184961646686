import { useState, useEffect } from 'react';
import {
  CalendarOutlined,
  CaretLeftOutlined,
  CarryOutOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import '../assets/styles/Calendar.css';
import { notification, Select, Spin } from 'antd';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useNavigate } from 'react-router-dom';
import {
  fetchAvailDaysOfMonth,
  fetchAvailOnDate,
  fetchConfigByTherapistId,
} from '../Network/Availability';
import { setBookingDetails } from '../redux/combinedSlice';
import { useDispatch, useSelector } from 'react-redux';
import { handleError } from '../ErrorHandling/handleError';

function Calendar({ therapistId, userDetails, therapistName }) {
  const nav = useNavigate();
  const dispatch = useDispatch();
  const currentBookingDetails = useSelector(
    (state) => state.combined.bookingDetails
  );
  const authorization = useSelector(
    (state) => state.combined.auth.authorization
  );

  const [currentDate, setCurrentDate] = useState(new Date());
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [consent, setConsent] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedSessionLen, setSelectedSessionLen] = useState();
  const [selectedMode, setSelectedMode] = useState();
  const [avlSlotLength, setAvlSlotLength] = useState([]);
  const [avlConfig, setAvlConfig] = useState([]);
  const [avlTime, setAvlTime] = useState([]);
  const [avlDatesOfMonth, setAvlDatesOfMonth] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [isCalendarLoading, setIsCalendarLoading] = useState(false);
  const [isTimeLoading, setIsTimeLoading] = useState(true);
  const modeMap = {
    'in-person': 'In-Person',
    video: 'Video Call',
    audio: 'Phone Call',
    text: 'Chat',
  };

  const addNotification = (type, message) => {
    notification.open({
      description: message,
      icon:
        type == 'success' ? (
          <CheckCircleOutlined style={{ color: '#52c41a' }} />
        ) : (
          <CloseCircleOutlined style={{ color: '#f5222d' }} />
        ),
      duration: 3,
    });
  };

  const fetchTherapistConfig = async () => {
    try {
      setisLoading(true);
      const res = await fetchConfigByTherapistId(therapistId);
      let slotLength = [];
      let config = [];
      res?.slotTypes.map((slot) => {
        slotLength.push(slot.slotLength);
        let key = slot.slotLength;
        let mode = slot.appointmentTypes;
        let rate = slot.baseRate;
        config.push({
          [key]: {
            modes: mode,
            baseRate: rate,
          },
        });
      });
      setAvlConfig(config);
      setAvlSlotLength(slotLength);
    } catch (error) {
      setisLoading(false);
      await handleError(error, nav, dispatch);
    } finally {
      setisLoading(false);
    }
  };

  const getTimeSlots = async (date) => {
    try {
      setIsTimeLoading(true);
      const res = await fetchAvailOnDate(therapistId, date, selectedSessionLen);
      const istTimes = [];
      for (const key in res) {
        if (res[key].isAvailable) {
          istTimes.push(convertGMTtoIST(res[key].startTime));
        }
      }
      setAvlTime(istTimes);
    } catch (error) {
      setIsTimeLoading(false);
      await handleError(error, nav, dispatch);
    } finally {
      setIsTimeLoading(false);
    }
  };

  useEffect(async () => {
    if (therapistId) {
      fetchTherapistConfig();
    }
  }, [therapistId]);

  const startOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  const endOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  );
  const startDayOfWeek = (startOfMonth.getDay() + 6) % 7;

  const daysInMonth = [];
  for (let i = 1 - startDayOfWeek; i <= endOfMonth.getDate(); i++) {
    daysInMonth.push(i);
  }

  //Helper Functions
  function convertGMTtoIST(gmtTime) {
    const gmtDateTime = new Date(gmtTime);
    const istOffset = 5.5 * 60 * 60 * 1000;
    const gmtHours = gmtDateTime.getUTCHours();
    const gmtMinutes = gmtDateTime.getUTCMinutes();
    const gmtSeconds = gmtDateTime.getUTCSeconds();
    let istHours = gmtHours + 5;
    let istMinutes = gmtMinutes + 30;
    if (istMinutes >= 60) {
      istMinutes -= 60;
      istHours += 1;
    }
    let istHours12 = istHours % 12;
    istHours12 = istHours12 === 0 ? 12 : istHours12;
    const period = istHours < 12 ? 'AM' : 'PM';
    const istTimeString = `${istHours12}:${
      (istMinutes < 10 ? '0' : '') + istMinutes
    } ${period}`;
    return istTimeString;
  }

  const handleDateClick = async (date) => {
    let calendar = document.getElementById('calendarDiv');
    let time = document.getElementById('timeDiv');
    calendar.classList.remove(
      'slideCalendarRight',
      'slideCalendarLeft',
      'slideCalendarRightSecond'
    );
    calendar.classList.add('slideCalendarLeftSecond');
    time.classList.remove('slideTimeRight');
    time.classList.add('slideTimeLeft');
    setSelectedDate(date);
    getTimeSlots(date);
  };

  const validateMobileNumber = (number) => {
    const regex = /^[0-9]{10,13}$/;
    return regex.test(number);
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleInputClick = async () => {
    if (!selectedSessionLen && !selectedMode && !name && !email && !mobile) {
      addNotification('fail', 'Fill Required Details');
      return;
    } else if (!consent) {
      addNotification('fail', 'Give Consent');
      return;
    } else if (!validateEmail(email) && !validateEmail(userDetails.email)) {
      addNotification("fail", "Enter valid email");
      return;
    }

    let input = document.getElementById('inputDiv');
    let calendar = document.getElementById('calendarDiv');
    input.classList.remove('slideInputRight');
    input.classList.add('slideInputLeft');
    calendar.classList.remove(
      'slideCalendarRight',
      'slideCalendarRightSecond',
      'slideCalendarLeftSecond'
    );
    calendar.classList.add('slideCalendarLeft');

    const endDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    );

    try {
      setIsCalendarLoading(true);
      const res = await fetchAvailDaysOfMonth(
        therapistId,
        currentDate,
        endDate,
        selectedSessionLen
      );

      const avlDates = Object.entries(res)
        .filter(([key, value]) => value)
        .map(([key]) => {
          const date = new Date(key);
          return date.getDate();
        });

      setAvlDatesOfMonth(avlDates);
    } catch (error) {
      setIsCalendarLoading(false);
      await handleError(error, nav, dispatch);
    } finally {
      setIsCalendarLoading(false);
    }
  };

  const handleBackAtTime = () => {
    let calendar = document.getElementById('calendarDiv');
    let time = document.getElementById('timeDiv');
    calendar.classList.remove(
      'slideCalendarRight',
      'slideCalendarLeft',
      'slideCalendarLeftSecond'
    );
    calendar.classList.add('slideCalendarRightSecond');
    time.classList.remove('slideTimeLeft');
    time.classList.add('slideTimeRight');
    setSelectedTime(null);
  };

  const handleBackAtCalendar = () => {
    let calendar = document.getElementById('calendarDiv');
    let input = document.getElementById('inputDiv');
    calendar.classList.remove(
      'slideCalendarLeft',
      'slideCalendarRightSecond',
      'slideCalendarLeftSecond'
    );
    calendar.classList.add('slideCalendarRight');
    input.classList.remove('slideInputLeft');
    input.classList.add('slideInputRight');
  };

  const handlePrevMonth = async () => {
    const today = new Date();
    if (
      currentDate.getMonth() === today.getMonth() &&
      currentDate.getFullYear() === today.getFullYear()
    ) {
      return;
    }
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1)
    );

    const startDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() - 1,
      1
    );
    const endDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      0
    );

    try {
      setIsCalendarLoading(true);
      const res = await fetchAvailDaysOfMonth(
        therapistId,
        startDate,
        endDate,
        selectedSessionLen
      );

      const avlDates = Object.entries(res)
        .filter(([key, value]) => value)
        .map(([key]) => {
          const date = new Date(key);
          return date.getDate();
        });

      setAvlDatesOfMonth(avlDates);
    } catch (error) {
      setIsCalendarLoading(false);
      await handleError(error, nav, dispatch);
    } finally {
      setIsCalendarLoading(false);
    }
  };

  const handleNextMonth = async () => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1)
    );

    const startDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      1
    );
    const endDate = new Date(
      new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        1
      ).getFullYear(),
      new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        1
      ).getMonth() + 1,
      0
    );
    try {
      setIsCalendarLoading(true);
      const res = await fetchAvailDaysOfMonth(
        therapistId,
        startDate,
        endDate,
        selectedSessionLen
      );

      const avlDates = Object.entries(res)
        .filter(([key, value]) => value)
        .map(([key]) => {
          const date = new Date(key);
          return date.getDate();
        });

      setAvlDatesOfMonth(avlDates);
    } catch (error) {
      setIsCalendarLoading(false);
      await handleError(error, nav, dispatch);
    } finally {
      setIsCalendarLoading(false);
    }
  };

  const handleBooking = async () => {
    dispatch(
      setBookingDetails({
        ...currentBookingDetails,
        therapistId: therapistId,
        appointmentLength: selectedSessionLen,
        appointmentType: selectedMode,
        appointmentDate: selectedDate,
        amount: avlConfig.find((item) => item[selectedSessionLen])?.[
          selectedSessionLen
        ]?.baseRate,
        patientEmail: email,
        patientName: name,
        patientMobile: mobile,
        therapistName: therapistName,
        startTime: selectedTime,
      })
    );
    nav(`/payment/${therapistId}`);
  };

  return (
    <>
      {isLoading ? (
        <div className='h-full content-center'>
          <Spin tip='Loading' size='large'>
            <div
              style={{
                borderRadius: 4,
              }}
            />
          </Spin>
        </div>
      ) : (
        <div className='layout-content w-96 md:w-116 flex m-auto h-full relative overflow-x-hidden'>
          <div
            className='container mx-auto md:h-max absolute left-0 md:top-16 opacity-1 p-6 z-10'
            id='inputDiv'
          >
            <label className='block text-sm font-medium leading-6 text-gray-900 required-field'>
              Patient Name{' '}
            </label>
            <div className='mt-2'>
              <input
                id="name"
                name="name"
                value={userDetails?.givenName || name}
                type="text"
                disabled={!!userDetails?.givenName}
                required
                className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-3'
                onChange={(e) => {
                  if (!userDetails?.givenName) {
                    setName(e.target.value);
                  }
                }}
              />
            </div>
            <label className='block text-sm font-medium leading-6 text-gray-900 mt-2 required-field'>
              Patient Email
            </label>
            <div className='mt-2'>
              <input
                value={userDetails?.email || email}
                id="name"
                name="name"
                type="email"
                disabled={!!userDetails?.email}
                required
                className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-3'
                onChange={(e) => {
                  if (!userDetails?.email) {
                    setEmail(e.target.value);
                  }
                }}
              />
            </div>
            <div className='md:grid md:grid-cols-2 gap-4 pt-4'>
              <div>
                <label className='block text-sm font-medium leading-6 text-gray-900 required-field'>
                  Session Length
                </label>
                <div className='mt-2'>
                  <Select
                    showSearch
                    style={{ width: 200 }}
                    placeholder='Session Length'
                    onChange={(e) => {
                      setSelectedSessionLen(e);
                      setSelectedMode('');
                    }}
                  >
                    {avlSlotLength?.map((length) => {
                      return (
                        <Select.Option key={length} value={length}>
                          {length} min
                        </Select.Option>
                      );
                    })}
                  </Select>
                </div>
              </div>
              <div>
                <label className='block text-sm font-medium leading-6 text-gray-900 mt-4 md:mt-0 required-field'>
                  Select Mode
                </label>
                <div className='mt-2'>
                  <Select
                    showSearch
                    style={{ width: 200 }}
                    disabled={!selectedSessionLen}
                    placeholder='Session Mode'
                    value={selectedMode ? selectedMode : null}
                    onChange={(e) => setSelectedMode(e)}
                    title={
                      !selectedSessionLen ? 'Please select length first' : ''
                    }
                    className={`${
                      selectedSessionLen ? '' : 'bg-gray-200 cursor-not-allowed'
                    }`}
                  >
                    {avlConfig
                      .find((item) => item[selectedSessionLen])
                      ?.[selectedSessionLen]?.modes?.map((mode) => (
                        <Select.Option key={mode} value={mode}>
                          {modeMap[mode]}
                        </Select.Option>
                      ))}
                  </Select>
                </div>
              </div>
            </div>
            <div className='mt-6'>
              <input
                type='checkbox'
                checked={consent}
                onClick={() =>
                  setConsent((prev) => {
                    return !prev;
                  })
                }
              ></input>
              <label for='consent'>
                {' '}
                By signing below, I consent to the use of email and mobile
                communication between myself and kasicare.
              </label>
            </div>
            <div className='flex justify-center mt-5'>
              <button
                className='bg-blue-500 text-white px-4 py-2 rounded-lg w-36 m-auto mt-6'
                onClick={() => handleInputClick()}
              >
                Continue Booking
              </button>
            </div>
          </div>

          {isCalendarLoading ? (
            <div className='m-auto'>
              <Spin tip='Loading' size='large'>
                <div
                  style={{
                    borderRadius: 4,
                  }}
                />
              </Spin>
            </div>
          ) : (
            <></>
          )}
          <div
            className='container mx-auto h-max w-90 md:w-max absolute left-full opacity-0 p-6 hidden'
            id='calendarDiv'
          >
            <CaretLeftOutlined
              className='text-3xl'
              onClick={handleBackAtCalendar}
            />
            <span className='block w-max m-auto'>
              <h2 className='text-xl font-bold text-left opacity-70'>
                <CarryOutOutlined />
                {'  '}
                {selectedMode} Mode
              </h2>
              <h2 className='text-xl font-bold text-left opacity-70'>
                <ClockCircleOutlined />
                {'  '}
                {selectedSessionLen} Session
              </h2>
            </span>

            <div className='flex justify-around items-center mb-4 w-90'>
              <button
                className={`${
                  currentDate.getMonth() == new Date().getMonth() &&
                  currentDate.getFullYear() == new Date().getFullYear()
                    ? 'bg-gray-400 text-white px-4 py-2 rounded-lg cursor-not-allowed'
                    : 'bg-blue-500 text-white px-4 py-2 rounded-lg'
                }`}
                onClick={handlePrevMonth}
              >
                &lt;
              </button>
              <h2 className='text-xl font-bold'>
                {currentDate.toLocaleString('default', { month: 'long' })}{' '}
                {currentDate.getFullYear()}
              </h2>
              <button
                className='bg-blue-500 text-white px-4 py-2 rounded-lg'
                onClick={handleNextMonth}
              >
                &gt;
              </button>
            </div>
            <div
              className={`grid grid-cols-7 gap-3 w-90 md:w-max  ${
                isCalendarLoading ? '!opacity-20' : '!opacity-100'
              }`}
            >
              {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map((day) => (
                <div key={day} className='text-center font-bold'>
                  {day}
                </div>
              ))}
              {daysInMonth.map((day, index) => (
                <div
                  key={index}
                  className={`text-center flex items-center justify-center py-2 ${
                    day > 0
                      ? !avlDatesOfMonth.includes(day) ||
                        (currentDate.getFullYear() <=
                          new Date().getFullYear() &&
                          currentDate.getMonth() <= new Date().getMonth() &&
                          new Date().getDate() > day)
                        ? 'cursor-not-allowed bg-gray-200 rounded-lg w-10 h-10 md:w-12 md:h-12 font-bold'
                        : 'cursor-pointer bg-blue-100 rounded-lg w-10 h-10 md:w-12 md:h-12 text-blue-700 font-bold hover:bg-blue-200'
                      : ''
                  } ${
                    selectedDate?.getDate() === day
                      ? 'bg-blue-600 text-white'
                      : ''
                  }`}
                  onClick={() =>
                    avlDatesOfMonth.includes(day) &&
                    handleDateClick(
                      new Date(
                        currentDate.getFullYear(),
                        currentDate.getMonth(),
                        day
                      )
                    )
                  }
                >
                  {day > 0 ? day : ''}
                </div>
              ))}
            </div>
          </div>

          <div
            className='container mx-auto h-max absolute left-full opacity-0 p-6 hidden'
            id='timeDiv'
          >
            <CaretLeftOutlined
              className='text-3xl'
              onClick={handleBackAtTime}
            />
            <span className='block w-max m-auto mb-8'>
              <h2 className='text-xl font-bold text-left opacity-70'>
                <CarryOutOutlined />
                {'  '}
                {selectedMode} Mode
              </h2>
              <h2 className='text-xl font-bold text-left opacity-70'>
                <ClockCircleOutlined />
                {'  '}
                {selectedSessionLen} Session
              </h2>
              <h2 className='text-xl font-bold text-left opacity-70'>
                <CalendarOutlined />
                {'  '}
                {selectedDate?.toLocaleDateString('en-US', {
                  weekday: 'long',
                  month: 'long',
                  day: 'numeric',
                })}
              </h2>
            </span>

            {isTimeLoading ? (
              <div className='m-auto'>
                <Spin tip='Loading' size='large'>
                  <div
                    style={{
                      borderRadius: 4,
                    }}
                  />
                </Spin>
              </div>
            ) : (
              <></>
            )}
            {selectedTime ? (
              <div>
                <button
                  className='bg-blue-800 hover:bg-blue-600 text-white px-4 py-2 rounded-lg w-36 m-auto block mt-8'
                  onClick={handleBooking}
                >
                  Book Rs.{' '}
                  {
                    avlConfig.find((item) => item[selectedSessionLen])?.[
                      selectedSessionLen
                    ]?.baseRate
                  }
                </button>
              </div>
            ) : (
              <></>
            )}
            <div className='overflow-scroll grid grid-cols-3 m-auto'>
              {avlTime.map((time) => {
                return (
                  <div
                    className={`
                    ${isTimeLoading ? '!opacity-20' : '!opacity-100'}
              ${
                selectedTime == time
                  ? 'bg-blue-700 text-white'
                  : 'text-blue-700'
              }              
              px-2 py-2 text-blue-700 text-xl font-extrabold border-blue-700 border-2 w-32 h-30 text-center m-auto rounded my-5 hover:cursor-pointer`}
                    onClick={() => setSelectedTime(time)}
                  >
                    {time}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Calendar;
