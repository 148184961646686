import React from 'react';
import Header from '../components/layout/LPHeader';
import Footer from '../components/layout/LPFooter';
import About from '../assets/images/about.png';
import { useNavigate } from 'react-router-dom';
import WhyChooseUs from '../assets/images/whyUs.png'; 
const AboutUs = () => {
  const navigate = useNavigate();
  const handleSearchTherapists = () => {
    navigate('/search');
  };
  const handleHome = () => {
    navigate('/');
  };
  return (
    <div>
      <div className='bg-gradient-to-b from-green-100 to-slate-50'>
        <Header />

        {/* About Us Section */}
        <div className='py-16 px-4 md:px-8 lg:px-16 flex flex-col items-center justify-center'>
          <div className='max-w-6xl mx-auto flex flex-col lg:flex-row items-center lg:items-start'>
            {/* Left Section: Illustration */}
            <div className='w-full lg:w-1/2 mb-8 lg:mb-0 lg:pr-8'>
              <img
                src={About}
                alt='About Us Illustration'
                className='w-full h-auto max-w-md mx-auto lg:mx-0'
              />
            </div>
            {/* Right Section: Text Content */}
            <div className='w-full lg:w-1/2'>
              <h2 className='text-3xl md:text-4xl font-semibold libre-baskerville-bold text-black mb-6'>
                About Us
              </h2>
              <p className='text-lg md:text-xl text-gray-700 mb-6'>
                We are a team of passionate individuals dedicated to providing
                the best solutions for your needs. Our expertise spans across
                different domains, allowing us to deliver tailored services for
                individuals, couples, and families. With years of experience and
                a commitment to excellence, we strive to make a difference in
                every project we undertake.
              </p>
              <button
                onClick={handleHome}
                className='bg-orange-600 rounded-full hover:bg-orange-500 text-white font-semibold py-2 px-6 transition-all duration-300'
              >
                Learn More
              </button>
            </div>
          </div>
        </div>

        {/* Why Choose Us Section */}
        <div className='py-16 px-4 md:px-8 lg:px-16 flex flex-col items-center justify-center'>
          <div className='max-w-6xl mx-auto flex flex-col lg:flex-row items-center lg:items-start'>
            {/* Left Section: Text Content */}
            <div className='w-full lg:w-1/2'>
              <h2 className='text-3xl md:text-4xl font-semibold libre-baskerville-bold text-black mb-6'>
                Why Choose Us
              </h2>
              <p className='text-lg md:text-xl text-gray-700 mb-6'>
                Our mission is to help individuals and families find the best
                possible solutions to their unique challenges. We offer a
                personalized approach that combines industry expertise with a
                deep understanding of your specific needs. With a focus on
                innovation, quality, and long-term relationships, we ensure you
                receive services that make a real impact.
              </p>
              <ul className='list-disc list-inside mb-6 text-lg md:text-xl text-gray-700'>
                <li>Expertise across multiple domains</li>
                <li>Tailored services for individuals and families</li>
                <li>Commitment to quality and long-term relationships</li>
                <li>Innovative solutions that deliver real results</li>
              </ul>
              <button
                onClick={handleSearchTherapists}
                className='bg-orange-600 rounded-full hover:bg-orange-400 text-white font-semibold py-2 px-6 transition-all duration-300'
              >
                Discover Our Services
              </button>
            </div>

            {/* Right Section: Illustration */}
            <div className='w-full lg:w-1/2 mt-8 lg:mt-0 lg:pl-8'>
              <img
                src={WhyChooseUs}
                alt='Why Choose Us Illustration'
                className='w-full h-auto max-w-md mx-auto lg:mx-0'
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AboutUs;
