import React, { useState } from 'react';
import Logo from '../../assets/images/logoOriginalTransparent.png';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../Network/Auth';
import {
  UserOutlined,
  CalendarOutlined,
  TeamOutlined,
  LogoutOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import { Drawer, notification, Dropdown, Menu } from 'antd';
import '../../app.css';

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [drawerVisible, setDrawerVisible] = useState(false);
  const authorization = useSelector(
    (state) => state.combined.auth.authorization
  );
  const addNotification = (type, message) => {
    notification.open({
      message: type == 'success' ? 'Success' : 'Failure',
      description: message,
      icon:
        type == 'success' ? (
          <CheckCircleOutlined style={{ color: '#52c41a' }} />
        ) : (
          <CloseCircleOutlined style={{ color: '#f5222d' }} />
        ),
      duration: 3,
    });
  };

  const handleLogout = async () => {
    try {
      await logout(authorization, dispatch, navigate);
      addNotification('success', 'Logout Successfull');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };
  const showDrawer = () => setDrawerVisible(true);
  const closeDrawer = () => setDrawerVisible(false);

  const profileMenu = (
    <Menu className='custom-menu'>
      <Menu.Item
        className='custom-menu-item text-sm'
        onClick={() => navigate('/profile')}
        icon={<UserOutlined />}
      >
        Profile
      </Menu.Item>
      <hr />
      <Menu.Item
        className='custom-menu-item'
        onClick={() => navigate('/appointments')}
        icon={<CalendarOutlined />}
      >
        Appointments
      </Menu.Item>
      <hr />
      <Menu.Item
        className='custom-menu-item'
        onClick={() => navigate('/myTherapist')}
        icon={<TeamOutlined />}
      >
        My Therapists
      </Menu.Item>
      <hr />
      <Menu.Item
        className='custom-menu-item'
        onClick={handleLogout}
        icon={<LogoutOutlined />}
      >
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <div className='w-full pb-4 bg-transparent pt-5 px-8'>
      <div className='flex md:mx-4 justify-between items-center'>
        <div className='text-2xl font-bold text-blue-600'>
          <img src={Logo} alt='Logo' className='h-14 w-auto' />
        </div>

        <nav className='font-lg font-semibold hidden md:flex space-x-8'>
          <button
            onClick={() => navigate('/home')}
            className='text-gray-700 p-2 hover:font-bold transition duration-300'
          >
            Home
          </button>
          <button
            onClick={() => navigate('/about')}
            className='text-gray-700 p-2 hover:font-bold transition duration-300'
          >
            About
          </button>
          <button
            onClick={() => navigate('/search')}
            className='text-gray-700 p-2 hover:font-bold transition duration-300'
          >
            Search Therapists
          </button>
          <a
            href='https://pracflow.ai'
            className='text-gray-700 hover:text-black p-2 hover:font-bold transition duration-300'
          >
            For Therapists
          </a>
        </nav>

        {/* Desktop Right-side Buttons */}
        <div className='hidden font-normal md:flex space-x-4'>
          {authorization ? (
            <Dropdown overlay={profileMenu} trigger={['click']}>
              <button className='py-2 px-3 flex items-center hover:bg-green-300 rounded-full bg-green-200'>
                <UserOutlined style={{ fontSize: '24px' }} /> {'  '}{' '}
                <span className=' font-bold ml-2 text-[12px]'> My Portal</span>
              </button>
            </Dropdown>
          ) : (
            <>
              <button
                onClick={() => navigate('/login')}
                className='px-6 py-2 font-xl font-semibold border-black border-[1px] rounded-full text-gray-700 hover:bg-green-200 transition duration-300'
              >
                Log In
              </button>
              <button
                onClick={() => navigate('/signup')}
                className='px-6 py-2 font-xl border-black border-[1px] rounded-full bg-black hover:bg-gray-800 text-white transition duration-300'
              >
                Sign Up
              </button>
            </>
          )}
        </div>

        {/* Mobile Hamburger Menu */}
        <div className='md:hidden flex items-center'>
          <button
            onClick={showDrawer}
            className='text-gray-700 focus:outline-none focus:text-black'
          >
            <svg
              className='w-6 h-6'
              fill='none'
              stroke='currentColor'
              viewBox='0 0 24 24'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2'
                d='M4 6h16M4 12h16m-7 6h7'
              ></path>
            </svg>
          </button>
        </div>
      </div>

      <Drawer
        title={
          <img
            src={Logo}
            alt='Logo'
            className='h-10 self-center mx-auto w-auto'
          />
        }
        placement='right'
        onClose={closeDrawer}
        visible={drawerVisible}
        width='100%'
      >
        <nav className='flex flex-col space-y-6'>
          <button
            onClick={() => {
              navigate('/home');
              closeDrawer();
            }}
            className='text-lg text-gray-700 p-2 hover:font-bold transition duration-300'
          >
            Home
          </button>
          <button
            onClick={() => {
              navigate('/about');
              closeDrawer();
            }}
            className='text-lg text-gray-700 p-2 hover:font-bold transition duration-300'
          >
            About
          </button>
          <button
            onClick={() => {
              navigate('/search');
              closeDrawer();
            }}
            className='text-lg text-gray-700 p-2 hover:font-bold transition duration-300'
          >
            Search Therapists
          </button>
          <a
            href='https://pracflow.ai'
            className='text-lg text-gray-700 p-2 hover:text-black text-center hover:font-bold transition duration-300'
          >
            For Therapists
          </a>

          {authorization ? (
            <>
              <button
                onClick={() => {
                  navigate('/profile');
                  closeDrawer();
                }}
                className='text-lg text-gray-700 p-2 hover:font-bold transition duration-300'
              >
                Profile
              </button>
              <button
                onClick={() => {
                  navigate('/appointments');
                  closeDrawer();
                }}
                className='text-lg text-gray-700 p-2 hover:font-bold transition duration-300'
              >
                Appointments
              </button>
              <button
                onClick={() => {
                  navigate('/myTherapist');
                  closeDrawer();
                }}
                className='text-lg text-gray-700 p-2 hover:font-bold transition duration-300'
              >
                My Therapists
              </button>
              <button
                onClick={handleLogout}
                className='px-6 py-3 border-black rounded-full text-gray-700 bg-red-400 hover:bg-red-600 text-white transition duration-300'
              >
                Log Out
              </button>
            </>
          ) : (
            <>
              <button
                onClick={() => {
                  navigate('/login');
                  closeDrawer();
                }}
                className='px-6 py-3 border-black border-[1px] rounded-full text-gray-700 hover:bg-green-200 transition duration-300'
              >
                Login
              </button>
              <button
                onClick={() => {
                  navigate('/signup');
                  closeDrawer();
                }}
                className='px-6 py-3 border-black border-[1px] rounded-full bg-black hover:bg-gray-800 text-white transition duration-300'
              >
                Sign Up
              </button>
            </>
          )}
        </nav>
      </Drawer>
    </div>
  );
};

export default Header;
