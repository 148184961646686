import React, { useState, useEffect } from "react";
import Main from "../components/layout/Main";
import { useNavigate, useParams } from "react-router-dom";
import {
  fetchAvailDaysOfMonth,
  fetchAvailOnDate,
} from "../Network/Availability";
import { useDispatch, useSelector } from "react-redux";
import {
  getAppointmentById,
  rescheduleAppointment,
} from "../Network/Appointment";
import { Modal, notification, Spin } from "antd";
import {
  CalendarOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { handleError } from "../ErrorHandling/handleError";

function Reschedule() {
  const nav = useNavigate();
  const dispatch = useDispatch();
  const authorization = useSelector(
    (state) => state.combined.auth.authorization
  );
  const { id } = useParams();
  const [therapistId, setTherapistId] = useState();
  const [selectedDate, setSelectedDate] = useState();
  const [selectedTime, setSelectedTime] = useState("");
  const [currentDate, setCurrentDate] = useState(new Date());
  const [isCalendarLoading, setIsCalendarLoading] = useState(true);
  const [isTimeLoading, setIsTimeLoading] = useState(false);
  const [avlDatesOfMonth, setAvlDatesOfMonth] = useState([]);
  const [appointmentDetails, setAppointmentDetails] = useState({});
  const [avlTime, setAvlTime] = useState([]);
  const addNotification = (type, message) => {
    notification.open({
      message: type == "success" ? "Success" : "Failure",
      description: message,
      icon:
        type == "success" ? (
          <CheckCircleOutlined style={{ color: "#52c41a" }} />
        ) : (
          <CloseCircleOutlined style={{ color: "#f5222d" }} />
        ),
      duration: 3,
    });
  };

  useEffect(() => {
    const fetchAppointmentDetails = async () => {
      setIsCalendarLoading(true);
      try {
        const res = await getAppointmentById(authorization, id);
        setAppointmentDetails(res);
        setTherapistId(res.therapistId);
        const endDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() + 1,
          0
        );
        const resDays = await fetchAvailDaysOfMonth(
          res.therapistId,
          currentDate,
          endDate,
          res.appointmentLength
        );
        const avlDates = Object.entries(resDays)
          .filter(([key, value]) => value)
          .map(([key]) => {
            const date = new Date(key);
            return date.getDate();
          });
        setAvlDatesOfMonth(avlDates);
      } catch (error) {
        await handleError(error, nav, dispatch);
      } finally {
        setIsCalendarLoading(false);
      }
    };
    fetchAppointmentDetails();
  }, []);

  const handleDateClick = (date) => {
    setSelectedDate(new Date(date));
    getTimeSlots(date);
  };

  function convertGMTtoIST(gmtTime) {
    const gmtDateTime = new Date(gmtTime);
    const istOffset = 5.5 * 60 * 60 * 1000;
    const gmtHours = gmtDateTime.getUTCHours();
    const gmtMinutes = gmtDateTime.getUTCMinutes();
    const gmtSeconds = gmtDateTime.getUTCSeconds();
    let istHours = gmtHours + 5;
    let istMinutes = gmtMinutes + 30;
    if (istMinutes >= 60) {
      istMinutes -= 60;
      istHours += 1;
    }
    let istHours12 = istHours % 12;
    istHours12 = istHours12 === 0 ? 12 : istHours12;
    const period = istHours < 12 ? "AM" : "PM";
    const istTimeString = `${istHours12}:${
      (istMinutes < 10 ? "0" : "") + istMinutes
    } ${period}`;
    return istTimeString;
  }

  const getTimeSlots = async (date) => {
    setIsTimeLoading(true);
    try {
      const res = await fetchAvailOnDate(
        therapistId,
        date,
        appointmentDetails.appointmentLength
      );
      const istTimes = [];
      for (const key in res) {
        if (res[key].isAvailable) {
          istTimes.push(convertGMTtoIST(res[key].startTime));
        }
      }
      setAvlTime(istTimes);
    } catch (error) {
      await handleError(error, nav, dispatch);
    } finally {
      setIsTimeLoading(false);
    }
  };

  const handleTimeSelect = (time) => {
    setSelectedTime(time);
  };

  const startOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  const endOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  );
  const startDayOfWeek = (startOfMonth.getDay() + 6) % 7;
  const daysInMonth = [];
  for (let i = 1 - startDayOfWeek; i <= endOfMonth.getDate(); i++) {
    daysInMonth.push(i);
  }

  const handlePrevMonth = async () => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1)
    );

    const startDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() - 1,
      1
    );
    const endDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      0
    );
    setIsCalendarLoading(true);
    try {
      const res = await fetchAvailDaysOfMonth(
        therapistId,
        startDate,
        endDate,
        appointmentDetails.appointmentLength
      );

      const avlDates = Object.entries(res)
        .filter(([key, value]) => value)
        .map(([key]) => {
          const date = new Date(key);
          return date.getDate();
        });

      setAvlDatesOfMonth(avlDates);
    } catch (error) {
      await handleError(error, nav, dispatch);
    } finally {
      setIsCalendarLoading(false);
    }
  };

  const handleNextMonth = async () => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1)
    );

    const startDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      1
    );
    const endDate = new Date(
      new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        1
      ).getFullYear(),
      new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        1
      ).getMonth() + 1,
      0
    );
    setIsCalendarLoading(true);
    try {
      const res = await fetchAvailDaysOfMonth(
        therapistId,
        startDate,
        endDate,
        appointmentDetails.appointmentLength
      );

      const avlDates = Object.entries(res)
        .filter(([key, value]) => value)
        .map(([key]) => {
          const date = new Date(key);
          return date.getDate();
        });

      setAvlDatesOfMonth(avlDates);
    } catch (error) {
      await handleError(error, nav, dispatch);
    } finally {
      setIsCalendarLoading(false);
    }
  };

  const times = [];

  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 30) {
      let amPm = hour >= 12 ? "PM" : "AM";
      let displayHour = hour % 12 === 0 ? 12 : hour % 12;
      let displayMinute = minute === 0 ? "00" : minute;
      let timeValue = `${displayHour}:${displayMinute} ${amPm}`;
      times.push(timeValue);
    }
  }

  const handleReschedule = async () => {
    try {
      const date = new Date(selectedDate);
      const [time, modifier] = selectedTime.split(" ");
      let [hours, minutes] = time.split(":");
      if (modifier === "PM" && hours !== "12") {
        hours = parseInt(hours, 10) + 12;
      } else if (modifier === "AM" && hours === "12") {
        hours = 0;
      }
      date.setHours(parseInt(hours, 10));
      date.setMinutes(parseInt(minutes, 10));
      date.setSeconds(0);
      date.setMilliseconds(0);

      const data = {
        appointmentId: id,
        newstartTime: date,
      };
      const res = await rescheduleAppointment(authorization, data);
      addNotification("success", "Appointment is Rescheduled");
      nav("/");
    } catch (err) {
      addNotification("fail", "Appointment is Not Rescheduled");
      await handleError(err, nav, dispatch);
    }
  };

  const showRescheduleModal = () => {
    Modal.confirm({
      title: "Are you sure you want to Reschedule this appointment?",
      content: "This action cannot be undone.",
      okText: "Yes, Reschedule it",
      cancelText: "No, do not Reschedule",
      onOk() {
        handleReschedule();
      },
      okButtonProps: {
        className:
          "bg-red-600 border-red-600 text-white hover:bg-red-700 hover:border-red-700",
      },
      cancelButtonProps: {
        className:
          "bg-blue-600 border-blue-600 text-white hover:bg-blue-700 hover:border-blue-700",
      },
    });
  };

  return (
    <Main>
      <div className="md:grid md:grid-cols-2">
        <div className="container m-auto w-90 h-116 md:h-136 md:w-max p-6 bg-white shadow-lg rounded-xl">
          <div className="flex justify-around items-center mb-4 w-90">
            <button
              className={`${
                currentDate.getMonth() == new Date().getMonth() &&
                currentDate.getFullYear() == new Date().getFullYear()
                  ? "bg-gray-400 text-white px-4 py-2 rounded-full cursor-not-allowed"
                  : "bg-blue-500 text-white px-4 py-2 rounded-full"
              }`}
              onClick={handlePrevMonth}
            >
              &lt;
            </button>
            <h2 className="text-xl font-bold">
              {currentDate.toLocaleString("default", { month: "long" })}{" "}
              {currentDate.getFullYear()}
            </h2>
            <button
              className="bg-blue-500 text-white px-4 py-2 rounded-full"
              onClick={handleNextMonth}
            >
              &gt;
            </button>
          </div>

          {isCalendarLoading ? (
            <div className="m-auto mt-12">
              <Spin tip="Loading" size="large">
                <div
                  style={{
                    borderRadius: 4,
                  }}
                />
              </Spin>
            </div>
          ) : (
            <></>
          )}
          <div
            className={`grid grid-cols-7 gap-4 w-90 md:w-max  ${
              isCalendarLoading ? "!opacity-20" : "!opacity-100"
            }`}
          >
            {["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"].map((day) => (
              <div key={day} className="text-center font-bold">
                {day}
              </div>
            ))}
            {daysInMonth.map((day, index) => (
              <div
                key={index}
                className={`text-center flex items-center justify-center py-2 ${
                  day > 0
                    ? !avlDatesOfMonth.includes(day) ||
                      (currentDate.getFullYear() <= new Date().getFullYear() &&
                        currentDate.getMonth() <= new Date().getMonth() &&
                        new Date().getDate() > day)
                      ? "cursor-not-allowed bg-gray-200 rounded-full w-10 h-10 md:w-12 md:h-12 font-bold"
                      : "cursor-pointer bg-blue-100 rounded-full w-10 h-10 md:w-12 md:h-12 text-blue-700 font-bold hover:bg-blue-200"
                    : ""
                } ${
                  selectedDate?.getDate() === day
                    ? "bg-blue-600 text-white"
                    : ""
                }`}
                onClick={() =>
                  avlDatesOfMonth.includes(day) &&
                  handleDateClick(
                    new Date(
                      currentDate.getFullYear(),
                      currentDate.getMonth(),
                      day
                    )
                  )
                }
              >
                {day > 0 ? day : ""}
              </div>
            ))}
          </div>
        </div>
        <div className="container m-auto relative w-90 md:w-116 h-116 md:h-136 p-6 bg-white shadow-lg rounded-xl">
          {isTimeLoading ? (
            <div className="m-auto">
              <Spin tip="Loading" size="large">
                <div
                  style={{
                    borderRadius: 4,
                  }}
                />
              </Spin>
            </div>
          ) : (
            <></>
          )}
          {selectedDate ? (
            <span className="block w-max m-auto mb-8">
              <h2 className="text-xl font-bold text-left opacity-70">
                <CalendarOutlined />
                {"  "}
                {selectedDate?.toLocaleDateString("en-US", {
                  weekday: "long",
                  month: "long",
                  day: "numeric",
                })}
              </h2>
            </span>
          ) : (
            <span className="block w-max m-auto mb-8">
              <h2 className="text-xl font-bold text-left opacity-70">
                Select Date
              </h2>
            </span>
          )}

          <div className="overflow-scroll grid grid-cols-3">
            {avlTime.map((time) => {
              return (
                <div
                  className={`
                    ${isTimeLoading ? "!opacity-20" : "!opacity-100"}
              ${
                selectedTime == time
                  ? "bg-blue-700 text-white"
                  : "text-blue-700"
              }              
              px-2 py-2 text-blue-700 text-xl font-extrabold border-blue-700 border-2 w-32 h-12 text-center rounded my-3 hover:cursor-pointer`}
                  onClick={() => setSelectedTime(time)}
                >
                  {time}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="">
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded-lg w-36 m-auto block mt-6"
          onClick={showRescheduleModal}
        >
          Reschedule
        </button>
      </div>
    </Main>
  );
}

export default Reschedule;
