import React, { useEffect, useState } from "react";
import { Button, Input, Spin, Modal, Upload, notification } from "antd";
import {
  getAssignmentByIdForClient,
  saveProgress,
  submitAssignment,
  getAssignmentResponseById,
} from "../Network/MyAssignments";
import { getResourceByIdForClient } from "../Network/Resource";
import {
  getFormById,
  getQuestionnaireFormRequestsForClient,
} from "../Network/IntakeForm";
import {
  EyeOutlined,
  UploadOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  DownloadOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { handleError } from "../ErrorHandling/handleError";

function ViewAssignment() {
  const [isLoading, setIsLoading] = useState(false);
  const [assignment, setAssignment] = useState(null);
  const [resources, setResources] = useState([]);
  const [clientAnswer, setClientAnswer] = useState("");
  const [mediaList, setMediaList] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewContent, setPreviewContent] = useState(null);
  const [therapistId, setTherapistId] = useState();
  const [feedback, setFeedback] = useState();
  const authorization = useSelector(
    (state) => state.combined.auth.authorization
  );
  const nav = useNavigate();
  const dispatch = useDispatch();
  const [formTitles, setFormTitles] = useState({}); 

  const { assignmentId } = useParams();

  useEffect(() => {
    if (assignmentId) {
      const fetchAssignmentDetails = async () => {
        setIsLoading(true);
        try {
          const res = await getAssignmentByIdForClient(
            authorization,
            assignmentId
          );
          setTherapistId(res.therapistId);
          if (res) {
            setAssignment(res);
            const fetchFormTitles = async (formIds, resourceId) => {
              try {
                const titles = await Promise.all(
                  formIds.map(async (formId) => {
                    const form = await getFormById(formId);
                    return { formId, title: form.title };
                  })
                );
                setFormTitles((prev) => ({
                  ...prev,
                  [resourceId]: titles,
                }));
              } catch (error) {
                await handleError(error, nav, dispatch);
              }
            };
            const fetchedResources = await Promise.all(
              res.resourceIds.map(async (resourceId) => {
                const resource = await getResourceByIdForClient(
                  authorization,
                  resourceId
                );
                if (resource?.formIds?.length > 0) {
                  await fetchFormTitles(resource.formIds, resourceId);
                }

                return resource;
              })
            );
            setResources(fetchedResources);

            const assignmentResponse = await getAssignmentResponseById(
              authorization,
              assignmentId
            );
            if (assignmentResponse) {
              setClientAnswer(assignmentResponse.clientAnswer || "");
              setMediaList(
                (assignmentResponse.media || []).map((file) => ({
                  uid: file._id,
                  name: file.name,
                  status: "done",
                  url: file.s3Url,
                  thumbUrl: file.s3Url,
                  mediaType: file.mediaType,
                }))
              );
              setFeedback(assignmentResponse.feedback);
            }
          }
        } catch (error) {
          await handleError(error, nav, dispatch);
        } finally {
          setIsLoading(false);
        }
      };
      fetchAssignmentDetails();
    }
  }, [assignmentId, authorization, nav, dispatch]);

  const addNotification = (type, message) => {
    notification.open({
      message: type === "success" ? "Success" : "Failure",
      description: message,
      icon:
        type === "success" ? (
          <CheckCircleOutlined style={{ color: "#52c41a" }} />
        ) : (
          <CloseCircleOutlined style={{ color: "#f5222d" }} />
        ),
      duration: 3,
    });
  };

  const handlePreview = (file) => {
    setPreviewContent(file);
    setPreviewVisible(true);
  };

  const closePreviewModal = () => {
    setPreviewVisible(false);
    setPreviewContent(null);
  };

  const handleUploadChange = ({ fileList }) => {
    const newMedia = fileList.filter((file) => !file.url);
    setMediaList([...mediaList, ...newMedia]);
  };

  const handleSave = async () => {
    try {
      const formData = new FormData();
      formData.append("assignmentId", assignmentId);
      formData.append("therapistId", therapistId);
      formData.append("clientAnswer", clientAnswer);

      mediaList.forEach((file) => {
        if (file.originFileObj) {
          formData.append("media", file.originFileObj);
        }
      });

      const response = await saveProgress(authorization, formData);
      if (response) {
        addNotification("success", "Progress saved successfully");
        nav("/myAssignments");
      } else {
        addNotification("fail", "Failed to save progress");
      }
    } catch (error) {
      await handleError(error, nav, dispatch);
    }
  };

  const handleMarkAsCompleted = async () => {
    try {
      const formData = new FormData();
      formData.append("assignmentId", assignmentId);
      formData.append("therapistId", therapistId);
      formData.append("clientAnswer", clientAnswer);

      mediaList.forEach((file) => {
        if (file.originFileObj) {
          formData.append("media", file.originFileObj);
        }
      });

      const response = await submitAssignment(authorization, formData);
      if (response) {
        addNotification("success", "Assignment submitted successfully");
        nav("/myAssignments");
      } else {
        addNotification("fail", "Failed to submit");
      }
    } catch (error) {
      await handleError(error, nav, dispatch);
    }
  };
  const getIntakeForm = async (therapistId) => {
    try {
      const res = await getQuestionnaireFormRequestsForClient(
        authorization,
        therapistId
      );
      if (res.length > 0) {
        nav(`/viewAssignment/${assignmentId}/${res[res.length - 1].formId}`);
      } else {
        addNotification(
          "fail",
          "You don't have any intake form assigned by your therapist, for more information please contact your therapist."
        );
      }
    } catch (error) {
      await handleError(error, nav, dispatch);
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100 p-6">
      {isLoading ? (
        <Spin tip="Loading" size="large">
          <div style={{ borderRadius: 4 }} />
        </Spin>
      ) : assignment ? (
        <div className="w-full max-w-2xl bg-white shadow-md rounded-lg p-6">
          <h1 className="text-gray-800 text-xl font-bold mb-4">
            Assignment Details
          </h1>

          <h2 className="text-black text-lg mt-4">Title</h2>
          <p>{assignment.title}</p>

          <h2 className="text-black text-lg mt-4">Description</h2>
          <p>{assignment.description}</p>

          <h2 className="text-black text-lg mt-4">Resources</h2>
          {resources.length > 0 ? (
            resources.map((resource, index) => (
              <div key={index} className="mb-4">
                <h3 className="text-black text-lg mt-4">{resource.title}</h3>
                <p className="text-gray-700">{resource.description}</p>

                {resource.linkUrls?.length > 0 && (
                  <div className="mb-4">
                    <h2 className="text-black text-lg">Resource Link URLs</h2>
                    <ul>
                      {resource.linkUrls.map((link, index) => (
                        <li key={index}>
                          <a
                            href={link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-500 underline"
                          >
                            {link}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}

                {resource.media?.length > 0 &&
                  resource.media.map((file, fileIndex) => (
                    <div
                      key={fileIndex}
                      className="flex items-center justify-between mb-2"
                    >
                      <div className="flex items-center">
                        {file.mediaType?.startsWith("image/") && (
                          <img
                            src={file.s3Url}
                            alt={file.name}
                            className="w-16 h-16 mr-2 rounded-md border"
                          />
                        )}
                        {file.mediaType?.startsWith("video/") && (
                          <video className="w-16 h-16 mr-2 rounded-md border">
                            <source src={file.s3Url} type={file.mediaType} />
                          </video>
                        )}
                        <span className="truncate w-40">{file.name}</span>
                      </div>
                      <div className="flex items-center space-x-2">
                        <button
                          type="primary"
                          className="flex items-center justify-center w-10 h-10 border border-blue-300 rounded-md bg-blue-500 text-white hover:bg-blue-600"
                          onClick={() => handlePreview(file)}
                        >
                          <EyeOutlined />
                        </button>
                        <button
                          type="primary"
                          className="flex items-center justify-center w-10 h-10 border border-blue-300 rounded-md bg-blue-500 text-white hover:bg-blue-600"
                          onClick={() => {
                            const link = document.createElement("a");
                            link.href = file.s3Url;
                            link.setAttribute("download", file.name);
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                          }}
                        >
                          <DownloadOutlined />
                        </button>
                      </div>
                    </div>
                  ))}
                {resource?.formIds?.length > 0 && formTitles[resource._id] && (
                  <div className="mt-2">
                    <h3 className="text-md font-bold">Forms:</h3>
                    <ul className="list-disc pl-5">
                      {formTitles[resource._id].map(
                        ({ formId, title }, index) => (
                          <li
                            key={index}
                            className="text-gray-700 flex justify-between items-center"
                          >
                            <span>{title}</span>
                            <Button
                              type="primary"
                              icon={<EditOutlined />}
                              size="large"
                              className="ml-4 bg-blue-500 text-white hover:bg-blue-600"
                              onClick={() =>
                                getIntakeForm(assignment?.therapistId)
                              }
                            >
                              Fill Here
                            </Button>
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                )}
              </div>
            ))
          ) : (
            <p>No resources available</p>
          )}

          <h2 className="text-black text-lg mt-4">Your Answer</h2>
          <Input.TextArea
            rows={4}
            placeholder="Write your answer here..."
            value={clientAnswer}
            onChange={(e) => setClientAnswer(e.target.value)}
          />

          <h2 className="text-black text-lg mt-4">Upload Media</h2>
          <Upload
            listType="picture"
            fileList={mediaList}
            onChange={handleUploadChange}
            beforeUpload={() => false}
            multiple
          >
            <Button icon={<UploadOutlined />}>Upload Media</Button>
          </Upload>

          <Modal
            visible={previewVisible}
            onCancel={closePreviewModal}
            footer={null}
            width={800}
          >
            {previewContent && (
              <>
                {previewContent.mediaType.startsWith("image/") && (
                  <img
                    src={previewContent.s3Url}
                    alt={previewContent.name}
                    style={{ width: "60%" }}
                  />
                )}
                {previewContent.mediaType.startsWith("video/") && (
                  <video controls style={{ width: "100%" }}>
                    <source
                      src={previewContent.s3Url}
                      type={previewContent.mediaType}
                    />
                  </video>
                )}
                {previewContent.mediaType.startsWith("audio/") && (
                  <audio controls style={{ width: "100%" }}>
                    <source
                      src={previewContent.s3Url}
                      type={previewContent.mediaType}
                    />
                  </audio>
                )}
                {previewContent.mediaType === "application/pdf" && (
                  <iframe
                    src={previewContent.s3Url}
                    title={previewContent.name}
                    style={{ width: "100%", height: "500px" }}
                    frameBorder="0"
                  />
                )}
              </>
            )}
          </Modal>
          {feedback && (
            <div>
              <h2 className="text-black text-lg mt-4">Feedback</h2>
              <p>{feedback}</p>
            </div>
          )}
          <div className="flex justify-end mt-6">
            <button
              onClick={handleSave}
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 mr-4"
            >
              Save Progress
            </button>

            <button
              onClick={handleMarkAsCompleted}
              className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
            >
              Mark as Completed
            </button>
          </div>
        </div>
      ) : (
        <p>No assignment found</p>
      )}
    </div>
  );
}

export default ViewAssignment;
