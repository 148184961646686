import { handleApiErrors } from '../ErrorHandling/handleApiErrors';

const createAppointment = async (authorization, data) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_HOST}/api/appointment/createAppointment`,
    {
      credentials: 'include',
      method: 'POST',
      withCredentials: true,
      body: JSON.stringify({
        data,
      }),
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${authorization}`,
      },
    }
  );
  if (!response.ok) {
    await handleApiErrors(response);
  }
  return await response.json();
};

const getAppointments = async (authorization) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_HOST}/api/appointment/getAppointmentsOfLogedInClient`,
    {
      credentials: 'include',
      method: 'POST',
      withCredentials: true,
      headers: {
        authorization: `Bearer ${authorization}`,
      },
    }
  );
  if (!response.ok) {
    await handleApiErrors(response);
  }
  return await response.json();
};
const rescheduleAppointment = async (authorization, data) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_HOST}/api/appointment/rescheduleAppointment`,
    {
      credentials: 'include',
      method: 'POST',
      withCredentials: true,
      body: JSON.stringify({
        appointmentId: data.appointmentId,
        newstartTime: data.newstartTime,
      }),
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${authorization}`,
      },
    }
  );
  if (!response.ok) {
    await handleApiErrors(response);
  }
  return await response.json();
};

const getTherapistsOfLoggedInUser = async (authorization) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_HOST}/api/appointment/getTherapistsOfLoggedInUser`,
    {
      credentials: 'include',
      method: 'POST',
      withCredentials: true,
      headers: {
        authorization: `Bearer ${authorization}`,
      },
    }
  );
  if (!response.ok) {
    await handleApiErrors(response);
  }
  return await response.json();
};

const getAppointmentForTimeRange = async (authorization, data) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_HOST}/api/appointment/getAppointmentForTimeRange`,
    {
      credentials: 'include',
      method: 'POST',
      withCredentials: true,
      body: JSON.stringify({
        therapistId: data.therapistId,
        startTime: data.newStartTime,
        endTime: data.newEndTime,
      }),
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${authorization}`,
      },
    }
  );
  if (!response.ok) {
    await handleApiErrors(response);
  }
  return await response.json();
};

const cancelAppointment = async (authorization, id, paymentId) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_HOST}/api/appointment/cancelAppointment`,
    {
      credentials: 'include',
      method: 'POST',
      withCredentials: true,
      body: JSON.stringify({ appointmentId: id, paymentId: paymentId }),
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${authorization}`,
      },
    }
  );
  if (!response.ok) {
    await handleApiErrors(response);
  }
  return await response.json();
};

const getAppointmentById = async (authorization, id) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_HOST}/api/appointment/getAppointmentById`,
    {
      credentials: 'include',
      method: 'POST',
      withCredentials: true,
      body: JSON.stringify({ appointmentId: id }),
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${authorization}`,
      },
    }
  );
  if (!response.ok) {
    await handleApiErrors(response);
  }
  return await response.json();
};

export {
  createAppointment,
  getAppointments,
  getAppointmentForTimeRange,
  cancelAppointment,
  getAppointmentById,
  getTherapistsOfLoggedInUser,
  rescheduleAppointment,
};
