import React, { useEffect, useState } from "react";
import { Button, Input, Spin, Modal } from "antd";
import { getResourceByIdForClient } from "../Network/Resource";
import { EyeOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { handleError } from "../ErrorHandling/handleError";

function Resource() {
  const [isLoading, setIsLoading] = useState(false);
  const [resource, setResource] = useState(null);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewContent, setPreviewContent] = useState(null);

  const authorization = useSelector(
    (state) => state.combined.auth.authorization
  );
  const nav = useNavigate();
  const dispatch = useDispatch();
  const { resourceId } = useParams();

  useEffect(() => {
    if (resourceId) {
      const fetchResourceDetails = async () => {
        setIsLoading(true);
        try {
          const res = await getResourceByIdForClient(authorization, resourceId);
          if (res) {
            setResource(res);
          }
        } catch (error) {
          await handleError(error, nav, dispatch);
        } finally {
          setIsLoading(false);
        }
      };
      fetchResourceDetails();
    }
  }, [resourceId, authorization, nav, dispatch]);

  const handlePreview = (file) => {
    setPreviewContent(file);
    setPreviewVisible(true);
  };

  const closePreviewModal = () => {
    setPreviewVisible(false);
    setPreviewContent(null);
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100 p-6">
      {isLoading ? (
        <Spin tip="Loading" size="large">
          <div style={{ borderRadius: 4 }} />
        </Spin>
      ) : resource ? (
        <div className="w-full max-w-2xl bg-white shadow-md rounded-lg p-6">
          <h1 className="text-gray-800 text-xl font-bold mb-4">
            Resource Details
          </h1>

          <h2 className="text-black text-lg mt-4">Resource Title</h2>
          <Input className="mb-4" value={resource.title} readOnly />

          {resource.description && (
            <div className="mb-4">
              <h2 className="text-black text-lg">Resource Description</h2>
              <p className="text-gray-700">{resource.description}</p>
            </div>
          )}

          {resource.linkUrls?.length > 0 && (
            <div className="mb-4">
              <h2 className="text-black text-lg">Resource Link URLs</h2>
              <ul>
                {resource.linkUrls.map((link, index) => (
                  <li key={index}>
                    <a
                      href={link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 underline"
                    >
                      {link}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          )}

          {resource.media?.length > 0 && (
            <>
              <h2 className="text-black text-lg">Media</h2>
              <div className="mt-6">
                {resource.media.map((file, index) => (
                  <div
                    key={index}
                    className="flex items-center justify-between mb-2"
                  >
                    <div className="flex items-center">
                      {file.mediaType?.startsWith("image/") && (
                        <img
                          src={file.s3Url}
                          alt={file.name}
                          className="w-16 h-16 mr-2 rounded-md border"
                        />
                      )}
                      {file.mediaType?.startsWith("video/") && (
                        <video className="w-16 h-16 mr-2 rounded-md border">
                          <source src={file.s3Url} type={file.mediaType} />
                        </video>
                      )}
                      {file.mediaType?.startsWith("audio/") && (
                        <div className="w-16 h-16 mr-2 bg-gray-200 flex items-center justify-center rounded-md">
                          <span className="text-gray-600">Audio</span>
                        </div>
                      )}
                      {file.mediaType === "application/pdf" && (
                        <div className="w-16 h-16 mr-2 bg-gray-200 flex items-center justify-center rounded-md">
                          <span className="text-gray-600">PDF</span>
                        </div>
                      )}

                      <span className="truncate w-40">{file.name}</span>
                    </div>
                    <button
                      type="primary"
                      className="flex items-center justify-center w-10 h-10 border border-blue-300 rounded-md bg-blue-500 text-white hover:bg-blue-600"
                      onClick={() => handlePreview(file)}
                    >
                      <EyeOutlined />
                    </button>
                  </div>
                ))}
              </div>
            </>
          )}

          <Modal
            visible={previewVisible}
            onCancel={closePreviewModal}
            footer={null}
            width={800}
          >
            {previewContent && (
              <>
                {previewContent.mediaType.startsWith("image/") && (
                  <img
                    src={previewContent.s3Url}
                    alt={previewContent.name}
                    style={{ width: "60%" }}
                  />
                )}
                {previewContent.mediaType.startsWith("video/") && (
                  <video controls style={{ width: "100%" }}>
                    <source
                      src={previewContent.s3Url}
                      type={previewContent.mediaType}
                    />
                  </video>
                )}
                {previewContent.mediaType.startsWith("audio/") && (
                  <audio controls style={{ width: "100%" }}>
                    <source
                      src={previewContent.s3Url}
                      type={previewContent.mediaType}
                    />
                  </audio>
                )}
                {previewContent.mediaType === "application/pdf" && (
                  <iframe
                    src={previewContent.s3Url}
                    title={previewContent.name}
                    style={{ width: "100%", height: "500px" }}
                    frameBorder="0"
                  />
                )}
                {previewContent.mediaType ===
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document" && (
                  <iframe
                    src={previewContent.s3Url}
                    title={previewContent.name}
                    style={{ width: "100%", height: "500px" }}
                    frameBorder="0"
                  />
                )}
              </>
            )}
          </Modal>
        </div>
      ) : (
        <div className="w-full max-w-2xl bg-white shadow-md rounded-lg p-6">
          <p className="text-red-500 text-center">
            This resource is no longer active. Kindly contact your therapist.
          </p>
        </div>
      )}
    </div>
  );
}

export default Resource;
