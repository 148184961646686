import React from 'react';
import Header from './layout/LPHeader';
import ServiceCard from './ServiceCard';
import { useNavigate } from 'react-router-dom';
import { blocksData } from '../assets/data/LPData';
import { useDispatch } from 'react-redux';
import { clientCookieName } from '../constants';
import { setAuthorization } from '../redux/combinedSlice';
import { useEffect } from 'react';
import Cookies from 'js-cookie';

const Hero = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleSearchTherapists = () => {
    navigate('/search');
  };
  const getCookie = (data) => {
    const cookies = document.cookie.split('; ');
    let decodedCookie;
    cookies.forEach((cookie) => {
      if (cookie.startsWith(data)) {
        decodedCookie = decodeURIComponent(cookie.split('=')[1]);
      }
    });
    return decodedCookie;
  };

  useEffect(() => {
    const authCookie = getCookie(clientCookieName);
    const redirectCookie = getCookie('redirectTo');
    if (authCookie) {
      dispatch(setAuthorization(authCookie));
      if (redirectCookie) {
        Cookies.remove('redirectTo', { path: '/' });
        navigate(`/${redirectCookie}`);
      }
    }
    if (authCookie) {
      navigate('/profile');
    }
  }, []);

  return (
    <div className='bg-gradient-to-b from-green-100 to-slate-50 relative'>
      <Header />
      <div className=' mx-4 lg:mx-8 flex flex-col md:flex-row  gap-16 items-center pb-8 text-left pt-20 md:pt-24 md:pb-20 px-4'>
        <div className='md:w-1/2 flex flex-col items-start md:mb-10'>
          <h1 className='text-3xl lg:text-6xl mb-2 font-bold pb-0 lg:leading-normal leading-relaxed text-black libre-baskerville-bold'>
            Find yourself in Therapy
          </h1>
          <p className='font-lg md:w-9/12'>
            Find the best therapists and mental health professionals near you
            Find the best therapists and mental health professionals near you
          </p>
          <button
            onClick={handleSearchTherapists}
            className='bg-orange-600 mt-4 text-white px-6 py-3 rounded-full hover:bg-orange-500  hover:text-white transition duration-300'
          >
            Book you first session
          </button>
        </div>

        <div className='w-full md:w-1/2 grid grid-cols-1 md:grid-cols-2 gap-4'>
          {blocksData.map((block, index) => (
            <ServiceCard
              key={index}
              title={block.title}
              description={block.description}
              image={block.image}
              bgColor={block.bgColor}
              imageHeight={block.imageHeight}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Hero;
