import React, { useEffect, useState } from "react";
import { Button, Card, notification, Spin } from "antd";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  getFormById,
  getIntakeFormRequestById,
  saveResponse,
  uploadFile,
} from "../Network/IntakeForm";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserDetails } from "../Network/Client";
import Cookies from "js-cookie";
import { handleError } from "../ErrorHandling/handleError";
import Main from "../components/layout/Main";
import axios from "axios";
import Title from "antd/lib/skeleton/Title";
import Paragraph from "antd/lib/skeleton/Paragraph";
import PracflowLogo from "../assets/images/PracflowLogo.svg";

function IntakeForm() {
  const nav = useNavigate();
  const dispatch = useDispatch();
  const { formId } = useParams();
  const [searchParams] = useSearchParams();
  const [isLogin, setIsLogin] = useState(null);
  const authorization = useSelector(
    (state) => state.combined.auth.authorization
  );
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [countdown, setCountdown] = useState(30);

  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [responses, setResponses] = useState([]);
  const [userDetails, setUserDetails] = useState({});
  const [phonenumber, setPhonenumber] = useState("");
  const MAX_FILE_SIZE = 10 * 1024 * 1024;
  const handleWhatsAppSend = () => {
    const message = responses
      .map((response) => `${response.label}: ${response.response}`)
      .join("\n");
    const whatsappURL = `https://wa.me/${phonenumber}?text=${encodeURIComponent(
      message
    )}`;
    window.open(whatsappURL, "_blank");
  };
  useEffect(() => {
    const fetchFormData = async () => {
      try {
        setIsLoading(true);
        const res = await getFormById(formId);
        setFormData(res);
        // console.log(res.therapistId, "res");
        const initialResponses = res.questions.map((question) => ({
          componentType: question.componentType,
          key: question.key,
          label: question.label,
          placeholder: question.placeholder,
          options: question.options,
          response: "",
          isRequired: question.isRequired,
        }));
        setResponses(initialResponses);
      } catch (error) {
        setIsLoading(false);
        await handleError(error, nav, dispatch);
      } finally {
        setIsLoading(false);
      }
    };
    fetchFormData();
  }, [formId]);

  useEffect(() => {
    if (!authorization) {
      Cookies.set("redirectTo", `intakeFrom/${formId}`, { path: "/" });
      setIsLogin(false);
      // nav("/login");
    } else {
      const fetchUser = async () => {
        try {
          setIsLoading(true);
          const res = await fetchUserDetails(authorization);
          setUserDetails(res);
          setIsLogin(true);
        } catch (error) {
          setIsLoading(false);
          await handleError(error, nav, dispatch);
        } finally {
          setIsLoading(false);
        }
      };
      fetchUser();
    }
  }, [authorization]);

  const handleChange = (key, value) => {
    setResponses((prevResponses) =>
      prevResponses.map((response) =>
        response.key === key ? { ...response, response: value } : response
      )
    );
  };

  const handleFileUpload = (key, file) => {
    if (file?.size > MAX_FILE_SIZE) {
      addNotification("fail", "File size exceeds 5 MB");
      return;
    }
    handleChange(key, file);
  };

  const addNotification = (type, message) => {
    notification.open({
      message: type === "success" ? "Success" : "Failure",
      description: message,
      icon:
        type === "success" ? (
          <CheckCircleOutlined style={{ color: "#52c41a" }} />
        ) : (
          <CloseCircleOutlined style={{ color: "#f5222d" }} />
        ),
      duration: 3,
    });
  };
  const [clientId, setClientId] = useState(null);
  const handleSubmit = async () => {
    try {
      setIsLoading(true);

      const updatedResponses = await Promise.all(
        responses.map(async (response) => {
          if (response.isRequired && !response.response) {
            addNotification("fail", "Response missing: " + response.label);
            throw new Error(
              "response missing on mandatory question: " + response.label
            );
          }

          if (response.componentType === "fileUpload" && response.response) {
            const formData = new FormData();
            formData.append("media", response.response);
            const uploadedFile = await uploadFile(formData, authorization);
            return { ...response, response: uploadedFile[0] };
          } else {
            return response;
          }
        })
      );
      // console.log(clientId, "clientId");
      // const clientId = searchParams.get("clientId");
      const formResponse = {
        formId: formId,
        keyName: formData.keyName,
        therapistId: formData.therapistId,
        clientId: isLogin ? userDetails._id : formreqId && clientId,
        responses: updatedResponses,
      };
      await saveResponse(
        formResponse.formId,
        formResponse.therapistId,
        formResponse.clientId,
        formResponse
      );
      {
        isLogin && addNotification("success", "Response saved successfully");
      }
      setIsSubmitted(true);
    } catch (err) {
      addNotification("fail", "Response not saved");
      await handleError(err, nav, dispatch);
    } finally {
      setIsLoading(false);
    }
  };

  const renderInputField = (question) => {
    const currentResponse = responses.find(
      (response) => response.key === question.key
    );
    if (!currentResponse) return null;

    switch (question.componentType) {
      case "text":
        return (
          <input
            type="text"
            value={currentResponse.response}
            onChange={(e) => handleChange(question.key, e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        );
      case "multiple":
        return question.options.map((option, idx) => (
          <label key={idx} className="inline-flex items-center mt-3">
            <input
              type="checkbox"
              value={option}
              checked={currentResponse.response?.includes(option) || false}
              onChange={(e) => {
                const newValues = currentResponse.response?.includes(option)
                  ? currentResponse.response.filter((val) => val !== option)
                  : [...(currentResponse.response || []), option];
                handleChange(question.key, newValues);
              }}
              className="hover:cursor-pointer form-checkbox h-5 w-5 text-gray-600"
            />
            <span className="mx-2 text-gray-700">{option}</span>
          </label>
        ));
      case "linearScale":
        return (
          <input
            type="range"
            min="1"
            max="10"
            value={currentResponse.response}
            onChange={(e) => handleChange(question.key, e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        );
      case "dropdown":
        return (
          <select
            value={currentResponse.response}
            onChange={(e) => handleChange(question.key, e.target.value)}
            className="block hover:cursor-pointer appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
          >
            <option value="" disabled>
              Select an option
            </option>
            {question.options.map((option, idx) => (
              <option key={idx} value={option}>
                {option}
              </option>
            ))}
          </select>
        );
      case "checkbox":
        return question.options.map((option, idx) => (
          <label key={idx} className="inline-flex items-center mt-3">
            <input
              type="checkbox"
              value={option}
              checked={currentResponse.response?.includes(option) || false}
              onChange={(e) => {
                const newValues = currentResponse.response?.includes(option)
                  ? currentResponse.response.filter((val) => val !== option)
                  : [...(currentResponse.response || []), option];
                handleChange(question.key, newValues);
              }}
              className="hover:cursor-pointer form-checkbox h-5 w-5 text-gray-600"
            />
            <span className="ml-2 text-gray-700">{option}</span>
          </label>
        ));
      case "date":
        return (
          <input
            type="date"
            value={currentResponse.response}
            onChange={(e) => handleChange(question.key, e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        );
      case "time":
        return (
          <input
            type="time"
            value={currentResponse.response}
            onChange={(e) => handleChange(question.key, e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        );
      case "fileUpload":
        return (
          <div>
            <input
              type="file"
              onChange={(e) =>
                handleFileUpload(question.key, e.target.files[0])
              }
              className="mb-2"
            />
          </div>
        );
      default:
        return null;
    }
  };
  useEffect(() => {
    let timer;
    if (isSubmitted && countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else if (countdown === 0) {
      nav("/profile");
    }
    return () => clearInterval(timer);
  }, [isSubmitted, countdown, nav]);
  const formreqId = searchParams.get("formreqId"); // Extract formreqId
  useEffect(() => {
    const formreqId = searchParams.get("formreqId"); // Extract formreqId
    // console.log(formreqId);
    if (isLogin || formreqId) {
      const fetchFormData = async () => {
        try {
          const response = getIntakeFormRequestById(authorization, formreqId);
          setPhonenumber(response.phoneNumber.phone);
          setClientId(response.clientId);
          setIsLoading(false);
        } catch (error) {
          console.error("Error fetching form data:", error);
        }
      };

      if (formreqId) fetchFormData();
    }
    // Call API only if formreqId exists
  }, []);
  // Add searchParams to dependency array
  const ThankYouComponent = () => (
    <Card className="w-[95%] sm:w-full flex flex-col max-w-sm bg-white rounded-lg shadow-lg overflow-hidden mb-4 mx-5 sm:mx-auto">
      <div className="text-center p-4">
        <CheckCircleOutlined style={{ fontSize: "48px", color: "#52c41a" }} />
        <p className="mt-4 text-xl">Thank You!</p>
        <div className="text-xs mt-4">
          Your Response has been successfully submitted.
        </div>
        <div className="flex gap-1 text-xs">
          You will be redirected to your profile page in{" "}
          <span className="font-bold text-blue-600">{countdown}</span> seconds.
        </div>
      </div>
    </Card>
  );
  return (
    <div
      className={`${
        isSubmitted && "h-screen flex justify-center items-center"
      }`}
    >
      {isLoading ? (
        <div className="h-full content-center ">
          <Spin tip="Loading" size="large">
            <div
              style={{
                borderRadius: 4,
              }}
            />
          </Spin>
        </div>
      ) : isSubmitted && isLogin ? (
        <ThankYouComponent />
      ) : (
        <div className="flex flex-col justify-center items-center min-h-screen bg-gradient-to-r from-blue-300 to-blue-100 ">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              {
                (isLogin || formreqId) && handleSubmit();
              }
            }}
            className="w-[95%] sm:w-full flex flex-col max-w-lg bg-white rounded-lg shadow-lg overflow-hidden mb-4 p-5 min-h-96 relative mr-10 mx-5 sm:mx-0"
          >
            <span className="text-black font-semibold text-3xl mr-6 mb-6 text-center">
              Intake Form
            </span>
            {formData.questions &&
              formData.questions.map((question, idx) => (
                <div key={idx} className="mb-2 w-full px-5">
                  <label className="block text-gray-700 text-sm font-bold mb-1">
                    {question.label}
                    <span className="text-red-400 text-sm">
                      {question.isRequired ? "   (*mandatory)" : ""}
                    </span>
                  </label>
                  {renderInputField(question)}
                </div>
              ))}
            <div className="flex items-center justify-between px-5 absolute bottom-5 right-3">
              {isLogin == true && (
                <>
                  <button
                    type="submit"
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  >
                    Submit
                  </button>
                  <button
                    className="bg-green-500 text-white px-4 py-2 rounded ml-4 flex gap-2 items-center"
                    onClick={handleWhatsAppSend}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                      className="w-5 h-5"
                    >
                      <path
                        fill="#ffffff"
                        d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7 .9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"
                      />
                    </svg>
                    Send via WhatsApp
                  </button>
                </>
              )}
              {isLogin == false && (
                <button
                  type="submit"
                  className="bg-green-500 text-white px-5 py-2 rounded "
                  onClick={handleWhatsAppSend}
                >
                  Send via WhatsApp
                </button>
              )}
            </div>
          </form>

          <div className="mt-4 flex flex-col">
            <span
              className="ml-2 text-xs font-bold"
              style={{ color: "#270949" }}
            >
              Powered by
            </span>
            <img
              className="mx-auto w-auto bg-transparent"
              style={{ height: "50px", width: "auto" }}
              src={PracflowLogo}
              alt="Your Company"
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default IntakeForm;
