import { handleApiErrors } from '../ErrorHandling/handleApiErrors';

const createDraftAppointment = async (authorization, data) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_HOST}/api/draftappointment/createDraftAppointment`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authorization}`,
      },
      body: JSON.stringify({ data }),
    }
  );
  if (!response.ok) {
    await handleApiErrors(response);
  }
  const draftAppointment = await response.json();
  return draftAppointment;
};

const verifyPayment = async (authorization, paymentData) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_HOST}/api/draftappointment/verifyPayment`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authorization}`,
      },
      body: JSON.stringify(paymentData),
    }
  );

  if (!response.ok) {
    await handleApiErrors(response);
  }

  const verificationResponse = await response.json();
  return verificationResponse;
};

const handleFailedPayment = async ( paymentData , authorization) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_HOST}/api/draftappointment/handleFailedPayment`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authorization}`,
      },
      body: JSON.stringify(paymentData),
    }
  );

  if (!response.ok) {
    await handleApiErrors(response);
  }

  const result = await response.json();
  return result;
};

export { createDraftAppointment, verifyPayment, handleFailedPayment };
