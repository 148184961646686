import { handleApiErrors } from "../ErrorHandling/handleApiErrors";
const getResourceByIdForClient = async (authorization, resourceId) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_HOST}/api/library/getResourceByIdForClient`,
    {
      method: "POST",
      credentials: "include",
      withCredentials: true,
      body: JSON.stringify({ resourceId: resourceId }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authorization}`,
      },
    }
  );

  if (!response.ok) {
    await handleApiErrors(response);
  }

  return await response.json();
};

export { getResourceByIdForClient };
