import Cookies from 'js-cookie';
import { clientCookieName } from '../constants';
import { setAuthorization } from '../redux/combinedSlice';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { notification } from 'antd';

const addNotification = (type, message) => {
  notification.open({
    message: type === 'success' ? 'Success' : 'Failure',
    description: message,
    icon:
      type === 'success' ? (
        <CheckCircleOutlined style={{ color: '#52c41a' }} />
      ) : (
        <CloseCircleOutlined style={{ color: '#f5222d' }} />
      ),
    duration: 3,
  });
};

export const handleError = async (error, navigate, dispatch) => {
  if ([401, 403, 402, 400].includes(error.status)) {
    console.log('auth error', error.statusText);
    Cookies.remove(clientCookieName, { path: '/' });
    dispatch(setAuthorization(null));
    addNotification('Failure', 'Authentication expired or not authorized');
    navigate('/login');
  } else if ([404].includes(error.status)) {
    addNotification('Failure', error.statusText);
    navigate('/');
  } else {
    if (error.message) {
      addNotification('Failure', error.message);
      console.error('An error occurred:', error);
    } else {
      console.error('An error occurred:', error);
      addNotification('Failure', error.statusText);
    }
  }
};
