import { useEffect, useState } from 'react';
import { Dropdown, Modal, Space, Spin, Pagination } from 'antd';
import {
  DeleteOutlined,
  LinkOutlined,
  EllipsisOutlined,
  CalendarOutlined,
  UserOutlined,
  ClockCircleOutlined,
  CalendarTwoTone,
  VideoCameraOutlined,
  PhoneOutlined,
  CommentOutlined,
} from '@ant-design/icons';
import { cancelAppointment } from '../Network/Appointment';
import { handleError } from '../ErrorHandling/handleError';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const AppointmentHistory = ({
  appointments,
  isLoading,
  authorization,
  setRefresh,
  addNotification,
}) => {
  const nav = useNavigate();
  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = useState('upcoming');
  const [selectedStatus, setSelectedStatus] = useState('All');
  const [buttonHidden, setButtonHidden] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const optionsDate = { day: '2-digit', month: 'long', year: 'numeric' };
  const optionsTime = { hour: '2-digit', minute: '2-digit' };

  window.addEventListener('click', (e) => {
    e.stopPropagation();
    setButtonHidden(null);
  });

  const filteredAppointments = appointments.filter((appointment) => {
    if (selectedDate == 'upcoming') {
      if (selectedStatus == 'All') {
        return (
          new Date(appointment.startTime).toISOString() >
          new Date().toISOString()
        );
      }
      return (
        new Date(appointment.startTime).toISOString() >
          new Date().toISOString() &&
        appointment.status === selectedStatus.toLowerCase()
      );
    } else {
      if (selectedStatus == 'All') {
        return (
          new Date(appointment.startTime).toISOString() <
          new Date().toISOString()
        );
      }
      return (
        new Date(appointment.startTime).toISOString() <
          new Date().toISOString() &&
        appointment.status === selectedStatus.toLowerCase()
      );
    }
  });

  const optionsItems = [
    {
      label: 'Connect',
      key: 'connect',
      icon: <LinkOutlined />,
    },
    {
      label: 'Cancel',
      key: 'cancel',
      icon: <DeleteOutlined />,
    },
    {
      label: 'Reschedule',
      key: 'reschedule',
      icon: <CalendarOutlined />,
    },
  ];

  const handleMenuOnClick = (connectLink, id, paymentId, { key }) => {
    if (key == 'cancel') {
      showCancelConfirm(id, paymentId);
    } else if (key == 'reschedule') {
      nav(`/reschedule/${id}`);
    } else if (key == 'connect') {
      if (connectLink && connectLink != undefined) {
        window.location.href = connectLink;
      } else {
        addNotification('failure', 'No meet link found');
      }
    }
  };

  const handleCancelAppointment = async (id, paymentId) => {
    try {
      const res = await cancelAppointment(authorization, id, paymentId);
      setRefresh((prev) => {
        return !prev;
      });
      addNotification('success', 'Appointment is Cancelled');
    } catch (err) {
      addNotification('fail', 'Failed to cancel appointment');
      await handleError(err, nav, dispatch);
    }
  };

  const showCancelConfirm = (id, paymentId) => {
    Modal.confirm({
      title: 'Are you sure you want to cancel this appointment?',
      content: 'This action cannot be undone.',
      okText: 'Yes, Cancel it',
      cancelText: 'No, do not cancel',
      onOk() {
        handleCancelAppointment(id, paymentId);
      },
      okButtonProps: {
        className:
          'bg-red-600 border-red-600 text-white hover:bg-red-700 hover:border-red-700',
      },
      cancelButtonProps: {
        className:
          'bg-blue-600 border-blue-600 text-white hover:bg-blue-700 hover:border-blue-700',
      },
    });
  };

  const modeMap = {
    'in-person': <UserOutlined />,
    video: <VideoCameraOutlined />,
    audio: <PhoneOutlined />,
    text: <CommentOutlined />,
  };

  const appointmentsPerPage = 6;
  const indexOfLastAppointment = currentPage * appointmentsPerPage;
  const indexOfFirstAppointment = indexOfLastAppointment - appointmentsPerPage;
  const currentAppointments = filteredAppointments.slice(
    indexOfFirstAppointment,
    indexOfLastAppointment
  );
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      {isLoading ? (
        <div className='h-full justify-center content-center'>
          <Spin tip='Loading' size='large'>
            <div
              style={{
                borderRadius: 4,
              }}
            />
          </Spin>
        </div>
      ) : (
        <>
          <div
            className='w-full mx-auto p-2 md:p-4 pr-0 mt-auto'
            onClick={(e) => {
              e.stopPropagation();
              setButtonHidden('');
            }}
          >
            <h2 className='font-black mb-4 text-xl text-gray-700'>
              Appointments
            </h2>
            <div className='border-b border-gray-200 mb-4'>
              <nav className='flex space-x-4'>
                {['previous', 'upcoming'].map((tab) => (
                  <div
                    key={tab}
                    className={`cursor-pointer pb-2 text-lg ${
                      selectedDate === tab
                        ? 'border-blue-500 text-blue-600 border-b-2'
                        : 'text-gray-500 hover:text-gray-700'
                    }`}
                    onClick={() => setSelectedDate(tab)}
                  >
                    {tab.charAt(0).toUpperCase() + tab.slice(1)}
                  </div>
                ))}
              </nav>
            </div>
            <div className='flex flex-wrap gap-2 justify-start mb-4'>
              {['All', 'Scheduled', 'Cancelled', 'Completed'].map((status) => (
                <button
                  key={status}
                  className={`text-sm border border-gray-300 px-2 py-1 lg:px-4 lg:py-1 rounded-full ${
                    selectedStatus === status
                      ? 'bg-blue-500 text-white'
                      : 'text-gray-500 hover:bg-gray-100'
                  }`}
                  onClick={() => setSelectedStatus(status)}
                >
                  {status}
                </button>
              ))}
            </div>
            <div className=' flex mr-2  flex-wrap gap-2'>
              {currentAppointments.map((appointment, index) => (
                <div
                  className='lg:w-[450px] w-[380px] p-2 bg-white border-2 border-slate-200 rounded-lg hover:cursor-pointer'
                  key={index}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (buttonHidden == appointment._id) {
                      setButtonHidden('');
                    } else {
                      setButtonHidden(appointment._id);
                    }
                  }}
                >
                  <div className='text-sm mr-2 flex  mb-2 items-center justify-between'>
                    <div className='text-gray-600 flex mt-2 gap-2 items-center'>
                      {/* <b>Therapist : </b> */}
                      <span className=' text-sm lg:text-xl ml-2'>
                        {appointment.therapistName
                          ? appointment.therapistName
                          : 'Unknown'}
                      </span>
                      <span
                        className={`text-sm font-bold text-gray-600 mx-4 px-4 py-1 rounded ${
                          appointment.status === 'scheduled'
                            ? 'bg-green-500 rounded-full text-white'
                            : appointment.status === 'completed'
                            ? 'bg-green-500 text-white'
                            : 'bg-red-500 text-white'
                        }`}
                      >
                        {appointment.status.toUpperCase()}
                      </span>
                    </div>
                    {appointment.status !== 'cancelled' ? (
                      <div
                        className='flex'
                        onClick={(e) => e.stopPropagation()}
                      >
                        <Dropdown
                          className='hover:cursor-pointer'
                          menu={{
                            items: optionsItems,
                            onClick: (e) =>
                              handleMenuOnClick(
                                appointment?.eventDetails?.videoConfLink,
                                appointment._id,
                                appointment?.paymentDetails?.paymentId,
                                e
                              ),
                          }}
                          trigger={['click']}
                        >
                          <Space>
                            <EllipsisOutlined style={{ fontSize: '28px' }} />
                          </Space>
                        </Dropdown>
                      </div>
                    ) : null}
                  </div>
                  <div className='flex flex-col justify-start flex-wrap gap-4 ml-3 mb-3 md:flex-nowrap'>
                    <div className='lg:flex-row flex-col flex mt-2 gap-3'>
                      <div className='text-sm text-gray-600 flex items-center'>
                        <CalendarTwoTone style={{ marginRight: 4 }} />
                        {/* Date:{' '} */}
                        <span className='font-bold ml-1'>
                          {new Date(appointment.startTime).toLocaleDateString(
                            'en-US',
                            optionsDate
                          )}
                        </span>
                      </div>
                      <div className='text-sm text-gray-600 flex items-center'>
                        <ClockCircleOutlined style={{ marginRight: 4 }} />
                        {/* Time:{' '} */}
                        <span className='font-bold ml-1'>
                          {new Date(appointment.startTime).toLocaleTimeString(
                            'en-US',
                            optionsTime
                          )}
                        </span>
                      </div>
                      <div className='text-sm text-gray-600 flex items-center'>
                        {modeMap[appointment.appointmentType]}
                        <span className='font-bold ml-1'>
                          {appointment.appointmentType.charAt(0).toUpperCase() +
                            appointment.appointmentType.slice(1)}
                        </span>
                      </div>
                    </div>
                    <div className='lg:flex-row flex-col flex gap-2 mt-2 lg:mt-2'>
                      <div className='text-sm text-gray-600 flex items-center'>
                        <CalendarOutlined style={{ marginRight: 4 }} />
                        Booked On:{' '}
                        <span className='font-bold ml-1'>
                          {' '}
                          {new Date(appointment.bookingTime).toLocaleDateString(
                            'en-US',
                            optionsDate
                          )}
                        </span>
                      </div>
                      <div className='text-sm text-gray-600 flex items-center'>
                        <ClockCircleOutlined style={{ marginRight: 4 }} />
                        Booked At:{' '}
                        <span className='font-bold ml-2'>
                          {' '}
                          {new Date(appointment.bookingTime).toLocaleTimeString(
                            'en-US',
                            optionsTime
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  {appointment.paymentDetails ? (
                    <>
                      <div className='text-sm mr-2 lg:flex-row flex-col flex gap-2 ml-1 mt-4 lg:mt-4 '>
                        <div className='text-gray-600 flex gap-2 items-center'>
                          <span className='texl-sm ml-2'>ID: </span>
                          <span className='text-sm font-bold'>
                            {appointment.paymentDetails.paymentId || 'Unknown'}
                          </span>
                        </div>
                        <div className=' ml-2 text-sm text-gray-600 flex items-center'>
                          Status:
                          {/* Amount:{' '} */}
                          <span className='font-bold ml-1'>
                            {appointment.paymentDetails?.paymentStatus ===
                            'captured'
                              ? 'Paid'
                              : appointment.paymentDetails?.paymentStatus ===
                                'refunded'
                              ? 'Refunded'
                              : 'Pending'}{' '}
                            {appointment.paymentDetails?.amount / 100}{' '}
                            {/* {appointment.paymentDetails?.currency} */}
                          </span>
                        </div>
                        <div className=' ml-2 lg:flex-row flex '>
                          <div className='text-sm text-gray-600 flex'>
                            Via:
                            <span className='font-bold ml-1'>
                              {appointment.paymentDetails.paymentMethod.toUpperCase()}
                            </span>
                          </div>
                          <div className='text-sm text-gray-600 flex items-center'>
                            {/* <CalendarOutlined style={{ marginRight: 4 }} /> */}
                            <span className='font-bold ml-1'>
                              {appointment.paymentDetails.date}
                            </span>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className='text-sm mr-2 lg:flex-row flex-col flex gap-2 ml-1 mt-4 lg:mt-4'>
                        <div className='text-gray-600 ml-2 flex gap-2 items-center'>
                          <span className='texl-sm'>Payment ID: </span>
                          <span className='text-sm font-bold'>N/A</span>
                        </div>
                        <div className='text-sm ml-2 text-gray-600 flex items-center'>
                          Status:
                          {/* Amount:{' '} */}
                          <span className='font-bold ml-1'>Pending</span>
                        </div>
                        <div className='lg:flex-row ml-2 flex-col flex gap-3'>
                          <div className='text-sm  text-gray-600 flex'>
                            {/* <DollarCircleOutlined style={{ marginRight: 4 }} /> */}
                            Via:
                            <span className='font-bold ml-1'>Cash</span>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {buttonHidden == appointment._id ? (
                    <div className='text-center mt-5 mb-3'>
                      {appointment.eventDetails &&
                      appointment.status != 'cancelled' ? (
                        <a
                          href={appointment?.eventDetails?.videoConfLink}
                          rel='noreferrer'
                          target='_blank'
                          className='bg-blue-600 hover:text-white text-white px-4 py-2 rounded-lg m-4 w-36'
                        >
                          Connect
                        </a>
                      ) : (
                        <button className='bg-blue-600 text-white px-4 py-2 rounded-lg w-36 m-auto'>
                          Details
                        </button>
                      )}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              ))}
            </div>
          </div>
          <Pagination
            current={currentPage}
            pageSize={appointmentsPerPage}
            total={filteredAppointments.length}
            onChange={handlePageChange}
            className='mt-4 flex justify-center'
          />
        </>
      )}
    </>
  );
};

export default AppointmentHistory;
