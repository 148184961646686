import React from 'react';
import Header from '../components/layout/LPHeader'; // Import Header component
import Hero from '../components/Home'; // Import Hero component
import StatsSection from '../components/Stats';
import TherapistSection from '../components/LPFindTherapist';
import HowItWorks from '../components/HowItWorks';
import Testimonials from '../components/Testimonials';
import FAQ from '../components/FAQ';
import AboveFooterCTA from '../components/AboveFooterCTA';
import Footer from '../components/layout/LPFooter';

const LandingPage = () => {
  return (
    <div>
      <Hero />
      {/* <StatsSection /> */}
      <TherapistSection />
      <HowItWorks />
      {/* <Testimonials /> */}
      <FAQ />
      <AboveFooterCTA />
      <Footer />
    </div>
  );
};

export default LandingPage;
