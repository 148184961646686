// Therapist images (MY THERAPISTS SECTION)
import Therapist1 from '../images/therapist.png';
import Therapist2 from '../images/call.png';
import Therapist3 from '../images/videoCall.png';
import Therapist4 from '../images/chat.png';

// Feature images (LOGIN PAGE)
import featureImage1 from '../images/logintherapist.png';
import featureImage2 from '../images/flexibletiming.png';
import featureImage3 from '../images/logintherapist.png';

// Block images (HERO SECTION)
import Individual from '../images/individual.png';
import Couple from '../images/couple.png';
import Teen from '../images/teen.png';
import Family from '../images/family.png';

// Steps Data (HOW IT WORKS SECTION)
export const steps = [
  {
    title: 'Sign Up and Choose Your Plan',
    description:
      "Begin by signing up on our platform. Choose the plan, whether it's for individual therapy, couples counseling, or medication management.",
  },
  {
    title: 'Match with a Therapist',
    description:
      'Our platform will connect you with the best therapists suited to your specific needs and preferences.',
  },
  {
    title: 'Start Your Sessions',
    description:
      "Once matched, you can schedule and start your therapy sessions. Whether it's in-person or online, your therapist will guide you through the healing process.",
  },
];

// Therapists Data (MY THERAPISTS SECTION)
export const therapists = [
  {image: Therapist1, bgColor: 'bg-yellow-200', height: '24'},
  {image: Therapist2, bgColor: 'bg-orange-200', height: '56'},
  {image: Therapist3, bgColor: 'bg-green-200', height: '36'},
  {image: Therapist4, bgColor: 'bg-pink-200', height: '80'},
];

// Testimonials Data (TESTIMONIALS SECTION)
export const testimonialsData = [
  {
    id: 1,
    text: "This is the best therapy service I've ever used. Highly recommended!",
    name: 'Jane Doe',
    profilePic:
      'https://media.istockphoto.com/id/682897825/photo/confident-businesswoman-over-gray-background.jpg?s=612x612&w=0&k=20&c=6uF-aYj0erHJsIw4P54HsNh6S3TZiFH2T3mwwWHtbvk=',
    details: 'Therapist, New York',
  },
  {
    id: 2,
    text: 'An amazing experience! The therapists are very professional and caring.',
    name: 'John Smith',
    profilePic:
      'https://media.istockphoto.com/id/682897825/photo/confident-businesswoman-over-gray-background.jpg?s=612x612&w=0&k=20&c=6uF-aYj0erHJsIw4P54HsNh6S3TZiFH2T3mwwWHtbvk=',
    details: 'Counselor, Los Angeles',
  },
  {
    id: 3,
    text: 'I feel much better after using this service. The support is exceptional.',
    name: 'Emily Johnson',
    profilePic:
      'https://media.istockphoto.com/id/682897825/photo/confident-businesswoman-over-gray-background.jpg?s=612x612&w=0&k=20&c=6uF-aYj0erHJsIw4P54HsNh6S3TZiFH2T3mwwWHtbvk=',
    details: 'Psychologist, San Francisco',
  },
  {
    id: 4,
    text: 'Fantastic service with a compassionate team. Highly recommend!',
    name: 'Michael Brown',
    profilePic:
      'https://media.istockphoto.com/id/682897825/photo/confident-businesswoman-over-gray-background.jpg?s=612x612&w=0&k=20&c=6uF-aYj0erHJsIw4P54HsNh6S3TZiFH2T3mwwWHtbvk=',
    details: 'Therapist, Chicago',
  },
  {
    id: 5,
    text: 'Incredible support and highly skilled therapists. My experience was wonderful!',
    name: 'Sarah Lee',
    profilePic:
      'https://media.istockphoto.com/id/682897825/photo/confident-businesswoman-over-gray-background.jpg?s=612x612&w=0&k=20&c=6uF-aYj0erHJsIw4P54HsNh6S3TZiFH2T3mwwWHtbvk=',
    details: 'Counselor, Miami',
  },
];

// Stats Data (STATS SECTION)
export const stats = [
  {label: 'Therapists Available', value: 3000, unit: '+'},
  {label: 'Sessions Completed', value: 15000, unit: '+'},
  {label: 'Satisfied Clients', value: 12000, unit: '+'},
  {label: 'Supporting you since', value: 10, unit: 'years'},
];

// Blocks Data (HERO SECTION)
export const blocksData = [
  {
    title: 'Individual',
    description: 'One-on-one support for your mental health and growth.',
    image: Individual,
    bgColor: 'bg-slate-300',
    imageHeight: '24',
  },
  {
    title: 'Couples',
    description: 'Expert counseling to strengthen your connection.',
    image: Couple,
    bgColor: 'bg-pink-200',
    imageHeight: '24',
  },
  {
    title: 'Teen',
    description: 'Specialized support for teens’ emotional and mental health.',
    image: Teen,
    bgColor: 'bg-orange-300',
    imageHeight: '24',
  },
  {
    title: 'Family',
    description: 'Improve communication and resolve family conflicts.',
    image: Family,
    bgColor: 'bg-blue-200',
    imageHeight: '24',
  },
];

// Features Data (LOGIN PAGE)
export const featuresData = [
  {
    title: 'Expert Therapists',
    description: 'Qualified and experienced professionals.',
    image: featureImage1,
    bgColor: 'bg-slate-300',
    imageHeight: '24',
  },
  {
    title: 'Flexible Scheduling',
    description: 'Appointments that fit your schedule.',
    image: featureImage2,
    bgColor: 'bg-pink-200',
    imageHeight: '24',
  },
  {
    title: 'Confidential Sessions',
    description: 'Privacy and confidentiality guaranteed.',
    image: featureImage3,
    bgColor: 'bg-orange-300',
    imageHeight: '24',
  },
  {
    title: 'Family Support',
    description: 'Find family counselors and therapists near you.',
    image: Family,
    bgColor: 'bg-blue-200',
    imageHeight: '24',
  },
];

// FAQ data (FAQ SECTION)
export const faqData = [
  {
    id: 1,
    question: 'What services do you offer?',
    answer:
      'We offer a range of therapy services including individual therapy, couples therapy, and family therapy. Our therapists are trained in various modalities to address different needs.',
  },
  {
    id: 2,
    question: 'How can I book a session?',
    answer:
      'You can book a session through our website by selecting a therapist and scheduling an appointment.',
  },
  {
    id: 3,
    question: 'Do you accept insurance?',
    answer:
      'No, currently we are not accepting insurances.',
  },
  {
    id: 4,
    question: 'What are your therapy hours?',
    answer:
      'Therapy hours are dependent on each therapist availability, you can select therapist of your choice and check' +
      ' their schedule to find a suitable slot for you.',
  },
  {
    id: 5,
    question: 'How can I contact you?',
    answer:
      'You can contact us via email, phone, or through the contact form on our website. Our contact information is available on the Contact Us page.',
  },
];