import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createDraftAppointment,
  verifyPayment,
  handleFailedPayment,
} from '../Network/Payment';
import { createAppointment } from '../Network/Appointment';
import { useNavigate, useParams } from 'react-router-dom';
import { notification, Select } from 'antd';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  ClockCircleOutlined,
  UserOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import Cookies from 'js-cookie';
import { setBookingDetails } from '../redux/combinedSlice';
import { fetchUserDetails } from '../Network/Client';
import { handleError } from '../ErrorHandling/handleError';
import bookingImage from '../assets/images/bookingImage.png';

const { Option } = Select;

function Payment() {
  const [isPaymentProcessing, setIsPaymentProcessing] = useState(false);
  const [paymentRetries, setPaymentRetries] = useState(0);
  const [prefill, setPrefill] = useState();
  const [timeLeft, setTimeLeft] = useState(30);
  const [paymentMethod, setPaymentMethod] = useState('online');
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const bookingDetails = useSelector((state) => state.combined.bookingDetails);
  const authorization = useSelector(
    (state) => state.combined.auth.authorization
  );

  // Function to display notifications
  const addNotification = (type, message) => {
    let title, icon;

    switch (type) {
      case 'success':
        title = 'Success';
        icon = <CheckCircleOutlined style={{ color: '#52c41a' }} />;
        break;
      case 'failure':
        title = 'Failure';
        icon = <CloseCircleOutlined style={{ color: '#f5222d' }} />;
        break;
      case 'alert':
        title = 'Alert';
        icon = <ExclamationCircleOutlined style={{ color: '#faad14' }} />;
        break;
      default:
        title = 'Notification';
        icon = null;
        break;
    }

    notification.open({
      message: title,
      description: message,
      icon: icon,
      duration: 3,
    });
  };

  // Function to fetch user details
  const fetchUser = async () => {
    try {
      const allDetails = await fetchUserDetails(authorization);
      dispatch(
        setBookingDetails({ ...bookingDetails, clientId: allDetails._id })
      );
      return allDetails;
    } catch (error) {
      await handleError(error, navigate, dispatch);
    }
  };

  useEffect(() => {
    if (isPaymentProcessing && timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [timeLeft, isPaymentProcessing]);

  useEffect(async () => {
    if (!authorization) {
      Cookies.set('redirectTo', `payment/${id}`, { path: '/' });
      navigate('/login');
    } else {
      const userDetails = await fetchUser();
      setPrefill(await buildPrefill(userDetails));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePaymentMethodChange = (value) => {
    setPaymentMethod(value);
  };

  const pollPaymentStatus = async (paymentData) => {
    const intervalId = setInterval(async () => {
      try {
        const appointmentDetails = await verifyPayment(
          authorization,
          paymentData
        );
        if (
          appointmentDetails.status === 'scheduled' &&
          appointmentDetails.paymentDetails?.paymentStatus === 'captured'
        ) {
          addNotification('success', 'Payment captured. Booking confirmed.');
          clearInterval(intervalId);
          dispatch(setBookingDetails({}));
          navigate('/appointments');
        } else if (
          appointmentDetails.payment?.paymentStatus === 'failed' &&
          appointmentDetails.status === 'drafted'
        ) {
          clearInterval(intervalId);
          addNotification('failure', 'Payment failed. Please try again.');
          const { errorCode, errorDescription, errorReason } =
            appointmentDetails.payment;
          console.error(
            `Razorpay Error: ${errorCode} - ${errorDescription} - ${errorReason}`
          );
          addNotification('failure', `Payment failed: ${errorDescription}`);
          await handleFailedPayments({
            appointmentId: appointmentDetails._id,
            paymentId: appointmentDetails.payment.paymentId,
            orderId: appointmentDetails.order.orderId,
            errorDescription: errorDescription,
          });
        } else {
          setPaymentRetries((prev) => prev + 1);
          if (paymentRetries >= 3) {
            clearInterval(intervalId);
            addNotification(
              'alert',
              'Payment processing failed, initiating refund.'
            );
            // await initiateRefund(paymentData);
            setIsPaymentProcessing(false);
          }
        }
      } catch (error) {
        clearInterval(intervalId);
        addNotification('failure', 'Error processing payment.');
        await handleError(error, navigate, dispatch);
      }
    }, 30000);
  };

  const modifyDate = async (date) => {
    const [time, modifier] = bookingDetails?.startTime?.split(' ');
    let [hours, minutes] = time.split(':');
    if (modifier === 'PM' && hours !== '12') {
      hours = (parseInt(hours, 10) + 12).toString();
    } else if (modifier === 'AM' && hours === '12') {
      hours = '0';
    }
    date.setHours(parseInt(hours, 10));
    date.setMinutes(parseInt(minutes, 10));
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
  };

  const getDraftAppointment = async (authorization, data) => {
    try {
      const draftAppointment = await createDraftAppointment(
        authorization,
        data
      );
      if (!draftAppointment) {
        addNotification('failure', 'Error Initiating Payment! Try Again');
        return null;
      }
      return draftAppointment;
    } catch (error) {
      addNotification('failure', 'Error Initiating Payment! Try Again');
      throw error;
    }
  };

  const handleFailedPayments = async (paymentData) => {
    try {
      const res = await handleFailedPayment(paymentData, authorization);
    } catch (error) {
      console.error('Failed to update draft appointment:', error);
    }
  };

  const buildPrefill = async (user) => {
    const prefill = {};

    if (user) {
      // Combine first and last name to form the full name
      if (user.givenName || user.familyName) {
        prefill.name = `${user.givenName || ''} ${
          user.familyName || ''
        }`.trim();
      }

      // Assign email directly
      if (user.email) {
        prefill.email = user.email;
      }

      // Ensure contact is only the phone number without any country code prefix
      if (user.phoneNumber && user.phoneNumber.phone) {
        prefill.contact = user.phoneNumber.phone;
      }
    }
    return prefill;
  };

  const initiatePayment = async (draftAppointment) => {
    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY_ID,
      currency: draftAppointment.order.currency,
      order_id: draftAppointment.order.orderId,
      name: draftAppointment.merchantName,
      description: draftAppointment.description,

      prefill: prefill,

      handler: async function (response) {
        const paymentData = {
          appointmentId: draftAppointment._id,
          paymentId: response.razorpay_payment_id,
          orderId: response.razorpay_order_id,
          signature: response.razorpay_signature,
        };

        try {
          const verificationResponse = await verifyPayment(
            authorization,
            paymentData
          );
          if (
            verificationResponse.paymentDetails?.paymentStatus === 'captured' &&
            verificationResponse.status === 'scheduled'
          ) {
            addNotification('success', 'Booking confirmed');
            dispatch(setBookingDetails({}));
            navigate('/appointments');
          } else {
            addNotification(
              'alert',
              'Payment is still processing. Please wait.'
            );
            pollPaymentStatus(paymentData);
          }
        } catch (error) {
          addNotification('failure', 'Booking is not confirmed');
          await handleError(error, navigate, dispatch);
          setPaymentMethod('cash');
          setIsPaymentProcessing(false);
        }
      },
      modal: {
        ondismiss: () => {
          addNotification('alert', 'Payment was canceled.');
          setIsPaymentProcessing(false);
        },
      },
      theme: {
        color: '#3399cc',
      },
    };

    const rzp1 = new window.Razorpay(options);

    rzp1.on('payment.failed', function (response) {
      const { code, description, reason, metadata } = response.error;
      console.error(`Razorpay Error: ${code} - ${description} - ${reason}`);
      addNotification('failure', `Payment failed: ${description}`);
      setIsPaymentProcessing(false);
      setPaymentMethod('cash');
      rzp1.close();
      handleFailedPayments({
        appointmentId: draftAppointment._id,
        paymentId: metadata.payment_id,
        orderId: draftAppointment.order.orderId,
        errorDescription: description,
      });
    });

    rzp1.open();
  };

  const handleBookAppointment = async () => {
    if (
      paymentMethod &&
      bookingDetails.therapistId &&
      bookingDetails.clientId &&
      bookingDetails.appointmentType &&
      bookingDetails.appointmentLength
    ) {
      setIsPaymentProcessing(true);
      try {
        const date = new Date(bookingDetails.appointmentDate);
        const modifiedDate = await modifyDate(date);
        const draftAppointmentData = {
          therapistId: bookingDetails.therapistId,
          clientId: bookingDetails.clientId,
          appointmentType: bookingDetails.appointmentType,
          appointmentLength: bookingDetails.appointmentLength,
          startTime: modifiedDate,
          bookingTime: Date.now(),
        };

        if (paymentMethod === 'cash') {
          // Handle Cash Payment
          const res = await createAppointment(
            authorization,
            draftAppointmentData
          );
          addNotification('success', 'Booking is confirmed');
          dispatch(setBookingDetails({}));
          navigate('/appointments');
          setIsPaymentProcessing(false);
        } else {
          addNotification('alert', 'Initializing Payment');
          const draftAppointment = await getDraftAppointment(
            authorization,
            draftAppointmentData
          );
          if (!draftAppointment) {
            setIsPaymentProcessing(false);
            return;
          }
          await initiatePayment(draftAppointment);
        }
      } catch (error) {
        addNotification('failure', 'Booking is not confirmed');
        setIsPaymentProcessing(false);
        await handleError(error, navigate, dispatch);
      }
    } else {
      addNotification('failure', 'Please complete all required fields');
    }
  };

  const modeMap = {
    'in-person': 'In-Person',
    video: 'Video Call',
    audio: 'Phone Call',
    text: 'Chat',
  };

  return (
    <div className='flex flex-wrap-reverse h-full gap-0 lg:p-16 my-auto justify-center items-center'>
      <img
        className='lg:h-[33rem] hidden lg:flex'
        src={bookingImage}
        alt='bookingImage'
      />
      <div className='w-max container mx-auto py-4 bg-white rounded-xl'>
        <h1 className='text-2xl text-center p-2 font-bold'>
          Appointment Details
        </h1>
        {bookingDetails.amount !== undefined ? (
          <div>
            <div className='flex flex-col gap-4 px-2 w-full pt-4 rounded-lg'>
              <div className='flex flex-col mb-2 rounded-md'>
                <Select
                  className='payment-method-select'
                  placeholder='Select Payment Method'
                  onChange={handlePaymentMethodChange}
                  value={paymentMethod}
                >
                  <Option value='cash'>Cash</Option>
                  <Option value='online'>Online</Option>
                </Select>
              </div>
              <div className='flex justify-between'>
                <p className='text-lg h-fit m-0 font-medium'>
                  <UserOutlined /> Therapist
                </p>
                <p className='h-fit m-0 text-lg font-semibold'>
                  {bookingDetails?.therapistName}
                </p>
              </div>
              <hr />
              <div className='flex m-0 h-fit justify-between'>
                <p className='text-lg m-0 h-fit font-medium'>
                  <ClockCircleOutlined />
                  {'  '} Duration
                </p>
                <p className='text-lg m-0 h-fit font-semibold'>
                  {bookingDetails?.appointmentLength} mins
                </p>
              </div>
              <hr />
              <div className='flex justify-between'>
                <p className='text-lg m-0 h-fit font-medium'>Type</p>
                <p className='text-lg m-0 h-fit font-semibold'>
                  {modeMap[bookingDetails?.appointmentType]}
                </p>
              </div>
              <hr />
              <div className='flex justify-between'>
                <p className='text-lg m-0 h-fit font-medium'>Date</p>
                <p className='text-lg m-0 h-fit font-semibold'>
                  {new Date(bookingDetails?.appointmentDate)?.toDateString()}
                </p>
              </div>
              <hr />
              <div className='flex justify-between'>
                <p className='text-lg m-0 h-fit font-medium'>Amount Due</p>
                <p className='text-lg m-0 h-fit font-semibold'>
                  {bookingDetails?.amount} INR
                </p>
              </div>
              <hr />
              <div className='flex gap-8'>
                <input
                  className='border-2 border-slate-200 pr pl-4 rounded-lg py-2'
                  type='text'
                  placeholder='Discount Code'
                  // TODO: Handle discount code logic
                />
                <button className='discount px-6 py-2 rounded-lg text-white bg-blue-800 h-fit'>
                  Apply
                </button>
              </div>
              <hr />
              <button
                className='bg-slate-800 border-2 text-lg rounded-lg text-white font-semibold w-full hover:text-white duration-300 p-3'
                onClick={handleBookAppointment}
                disabled={isPaymentProcessing}
              >
                {isPaymentProcessing
                  ? `Processing... `
                  : paymentMethod === 'cash'
                  ? 'Confirm Appointment'
                  : `Pay ${bookingDetails.amount} INR`}
              </button>
              {isPaymentProcessing && (
                <div className='mt-2'>
                  <p className='font-bold p-2 text-center bg-red-200 border-red-400 rounded-lg border-2'>
                    Payment is processing, please wait... Don't close this
                    window
                  </p>
                  <div className='flex justify-between'>
                    {' '}
                    {/* <div className='flex gap-4'>
                      <p>Retry: {paymentRetries}/3</p>
                    <Spin
                        className='ml-4'
                        indicator={
                          <LoadingOutlined style={{ fontSize: 24 }} spin />
                        }
                      /> 
                    </div>
                    <p>Next attempt in: {timeLeft} seconds</p> */}
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className='flex flex-col items-center justify-center p-4'>
            <p>No Appointment details available.</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default Payment;
