import React, { useState, useEffect } from 'react';
import Logo from '../assets/images/logoOriginalTransparent.png';
import FeaturesCarousel from '../components/FeatureCard';
import Login from '../components/Login';
import Signup from '../components/Signup';
import { useDispatch } from 'react-redux';
import { clientCookieName } from '../constants';
import { setAuthorization } from '../redux/combinedSlice';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const Auth = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getCookie = (data) => {
    const cookies = document.cookie.split('; ');
    let decodedCookie;
    cookies.forEach((cookie) => {
      if (cookie.startsWith(data)) {
        decodedCookie = decodeURIComponent(cookie.split('=')[1]);
      }
    });
    return decodedCookie;
  };

  useEffect(() => {
    const authCookie = getCookie(clientCookieName);
    const redirectCookie = getCookie('redirectTo');
    if (authCookie) {
      dispatch(setAuthorization(authCookie));
      if (redirectCookie) {
        Cookies.remove('redirectTo', { path: '/' });
        navigate(`/${redirectCookie}`);
      }
    }
    if (authCookie) {
      navigate('/profile');
    }
  }, []);
  const [isLoginMode, setIsLoginMode] = useState(false);

  return (
    <div className=' flex flex-col justify-center bg-gradient-to-b from-green-100 to-slate-50'>
      <div className='w-full md:w-1/2 mx-auto flex flex-col gap-8 items-center justify-center'>
        <div className='self-center mx-auto p-4 md:mt-0 flex flex-col justify-center items-center w-full'>
          <img
            src={Logo}
            className='h-20 mt-8 items-center self-center mb-6'
            alt='Logo'
          />
          <h2 className='text-center libre-baskerville-bold text-xl md:text-2xl font-extrabold text-gray-900'>
            Book Your Therapy Sessions
          </h2>
          <div className='w-full self-center'>
            <h2 className='text-center text-lg ibre-baskerville-bold  font-serif opacity-80 text-gray-900'>
              {isLoginMode
                ? 'Welcome Back!'
                : 'Sign up to book a session with one of our licensed therapists.'}
            </h2>
            {isLoginMode && <Login />}
            {!isLoginMode && <Signup />}
            <div className='mt-4 text-center'>
              {isLoginMode ? (
                <p className='text-[12px]'>
                  Don't have an account?{' '}
                  <span
                    className='text-blue-500 underline cursor-pointer'
                    onClick={() => setIsLoginMode(false)}
                  >
                    Sign Up
                  </span>
                </p>
              ) : (
                <p className='text-[12px]'>
                  Already have an account?{' '}
                  <span
                    className='text-blue-500 underline cursor-pointer'
                    onClick={() => setIsLoginMode(true)}
                  >
                    Log In
                  </span>
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className='md:w-9/12  w-full m-6 mt-0  md:block  mx-12 mr-12 self-center rounded-lg'>
        <FeaturesCarousel />
      </div>
    </div>
  );
};

export default Auth;
