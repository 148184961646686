import { handleApiErrors } from "../ErrorHandling/handleApiErrors";

const getAssignmentRequestsForClient = async (authorization) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_HOST}/api/assignment/getAssignmentRequestsForClient`,
    {
      credentials: "include",
      method: "POST",
      withCredentials: true,
      body: JSON.stringify({}),
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${authorization}`,
      },
    }
  );
  if (!response.ok) {
    await handleApiErrors(response);
  }
  return await response.json();
};
const getAssignmentByIdForClient = async (authorization, assignmentId) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_HOST}/api/assignment/getAssignmentByIdForClient`,
    {
      method: "POST",
      credentials: "include",
      withCredentials: true,
      body: JSON.stringify({ assignmentId: assignmentId }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authorization}`,
      },
    }
  );

  if (!response.ok) {
    await handleApiErrors(response);
  }

  return await response.json();
};
const saveProgress = async (authorization, formData) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_HOST}/api/assignment/saveProgressByClient`,
    {
      method: "POST",
      credentials: "include",
      withCredentials: true,
      body: formData, 
      headers: {
        Authorization: `Bearer ${authorization}`, 
      },
    }
  );

  if (!response.ok) {
    await handleApiErrors(response);
  }

  return await response.json();
};
const submitAssignment = async (authorization, formData) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_HOST}/api/assignment/submitAssignmentByClient`,
    {
      method: "POST",
      credentials: "include",
      withCredentials: true,
      body: formData, 
      headers: {
        Authorization: `Bearer ${authorization}`, 
      },
    }
  );

  if (!response.ok) {
    await handleApiErrors(response);
  }

  return await response.json();
};
const getAssignmentResponseById = async (authorization, assignmentId) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_HOST}/api/assignment/getAssignmentResponseById`,
    {
      credentials: "include",
      method: "POST",
      withCredentials: true,
      body: JSON.stringify({ assignmentId }),
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${authorization}`,
      },
    }
  );
  if (response.status === 204 || !response.ok) {
    return null;
  }
  try {
    const data = await response.json();
    return data || null;
  } catch (error) {
    return null;
  }
};

export {
  getAssignmentRequestsForClient,
  getAssignmentByIdForClient,
  saveProgress,
  submitAssignment,
  getAssignmentResponseById,
};
