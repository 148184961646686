import { handleApiErrors } from "../ErrorHandling/handleApiErrors";

const createTextConversation = async (text, recieverId, authorization) => {
    const response = await fetch(
        `${process.env.REACT_APP_API_HOST}/api/conversations/createTextConversation`,
        {
            credentials: "include",
            method: "POST",
            withCredentials: true,
            body: JSON.stringify({ text: text, recieverId: recieverId }),
            headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${authorization}`,
            },
        }
    );
    if (!response.ok) {
        await handleApiErrors(response);
    }
    return await response.json();
};

const createMediaConversation = async (formData, authorization) => {
    const response = await fetch(
        `${process.env.REACT_APP_API_HOST}/api/conversations/createMediaConversation`,
        {
            credentials: "include",
            method: "POST",
            body: formData,
            headers: {
                authorization: `Bearer ${authorization}`,
            },
        }
    );
    if (!response.ok) {
        await handleApiErrors(response);
    }
    return await response.json();
};


const getConversations = async (recieverId, authorization) => {
    const response = await fetch(
        `${process.env.REACT_APP_API_HOST}/api/conversations/getConversations`,
        {
            credentials: "include",
            method: "POST",
            withCredentials: true,
            body: JSON.stringify({ recieverId: recieverId }),
            headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${authorization}`,
            },
        }
    );
    if (!response.ok) {
        await handleApiErrors(response)
    }
    return await response.json();
};
const markConversationRead = async (recieverId, authorization) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/api/conversations/markConversationRead`,
      {
        credentials: "include",
        method: "POST",
        withCredentials: true,
        body: JSON.stringify({ senderId: recieverId }),
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${authorization}`,
        },
      }
    );
    if (!response.ok) {
      await handleApiErrors(response)
    }
    return await response.json();
  };
  
  const getUnreadCount = async (authorization) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/api/conversations/getUnreadCount`,
      {
        credentials: "include",
        method: "POST",
        withCredentials: true,
        headers: {
          authorization: `Bearer ${authorization}`,
        },
      }
    );
    if (!response.ok) {
      await handleApiErrors(response)
    }
    return await response.json();
  };
export { createTextConversation, createMediaConversation, getConversations, markConversationRead, getUnreadCount }