import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  auth: {
    authorization: null,
  },
  bookingDetails: {
    therapistId: null,
    clientId: null,
    appointmentType: null,
    appointmentLength: null,
    appointmentDate: null,
    amount: null,
    patientName: null,
    therapistName: null,
    patientEmail: null,
    patientMobile: null,
    startTime: null,
  },
  updateCount: {},
  clientDetails: {
    therapistId: null,
  },
};

const combinedSlice = createSlice({
  name: "combined",
  initialState,
  reducers: {
    setAuthorization: (state, action) => {
      state.auth.authorization = action.payload;
    },
    setBookingDetails: (state, action) => {
      const {
        therapistId,
        clientId,
        appointmentType,
        appointmentDate,
        appointmentLength,
        amount,
        patientName,
        patientEmail,
        patientMobile,
        therapistName,
        startTime
      } = action.payload;

      state.bookingDetails.therapistId = therapistId;
      state.bookingDetails.clientId = clientId;
      state.bookingDetails.appointmentType = appointmentType;
      state.bookingDetails.appointmentDate = appointmentDate;
      state.bookingDetails.appointmentLength = appointmentLength;
      state.bookingDetails.amount = amount;
      state.bookingDetails.patientName = patientName;
      state.bookingDetails.therapistName = therapistName;
      state.bookingDetails.patientEmail = patientEmail;
      state.bookingDetails.patientMobile = patientMobile;
      state.bookingDetails.startTime = startTime;

    },
    setUpdateCount: (state, action) => {
      state.updateCount = action.payload;
    },
    setClientDetails: (state, action) => {
      const { clientId } = action.payload;
      state.clientDetails.clientId = clientId;
    },
  },
});

export const { setAuthorization, setBookingDetails, setUpdateCount, setClientDetails } = combinedSlice.actions;

export default combinedSlice.reducer;
