import { handleApiErrors } from "../ErrorHandling/handleApiErrors";

const fetchUserDetails = async (authorization) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_HOST}/api/client/getClientAllDetails`,
    {
      credentials: "include",
      method: "POST",
      headers: {
        authorization: `Bearer ${authorization}`,
      },
    }
  );
  if (!response.ok) {
    await handleApiErrors(response);
  }
  return await response.json();
};

const uploadProfilePhoto = async (authorization, formData) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_HOST}/api/client/uploadProfilePicture`,
    {
      method: "POST",
      credentials: "include",
      body: formData,
      headers: {
        authorization: `Bearer ${authorization}`,
      },
    }
  );
  if (!response.ok) {
    await handleApiErrors(response);
  }
  return await response.json();
};

const updateProfile = async (authorization, updatedDetails) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_HOST}/api/client/updateClient`,
    {
      credentials: "include",
      method: "POST",
      withCredentials: true,
      body: JSON.stringify({ client: updatedDetails }),
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${authorization}`,
      },
    }
  );
  if (!response.ok) {
    await handleApiErrors(response);
  }
  return await response.json();
};

export { fetchUserDetails, uploadProfilePhoto, updateProfile };
