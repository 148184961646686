import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import Main from '../components/layout/Main';
import { Avatar, Spin } from 'antd';
import { SearchOutlined, UserOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { clientCookieName } from '../constants';
import { setAuthorization, setClientDetails } from '../redux/combinedSlice';
import { fetchAllTherapists } from '../Network/Therapist';
import { handleError } from '../ErrorHandling/handleError';
import Footer from '../components/layout/Footer';
import { fetchUserDetails } from '../Network/Client';

function Home() {
  const dispatch = useDispatch();
  const nav = useNavigate();
  const [alltherapist, setAlltherapists] = useState([]);
  const [isLoading, setisLoading] = useState(true);

  const getCookie = (data) => {
    const cookies = document.cookie.split('; ');
    let decodedCookie;
    cookies.forEach((cookie) => {
      if (cookie.startsWith(data)) {
        decodedCookie = decodeURIComponent(cookie.split('=')[1]);
      }
    });
    return decodedCookie;
  };

  useEffect(() => {
    const authCookie = getCookie(clientCookieName);
    const redirectCookie = getCookie('redirectTo');
    if (authCookie) {
      dispatch(setAuthorization(authCookie));
      if (redirectCookie) {
        Cookies.remove('redirectTo', { path: '/' });
        nav(`/${redirectCookie}`);
      }
    }

    const getAllTherapsit = async () => {
      try {
        setisLoading(true);
        const therapists = await fetchAllTherapists();
        // const allDetails = await fetchUserDetails(authCookie);
        // dispatch(setClientDetails({ clientId: allDetails._id }));
        setAlltherapists(therapists);
      } catch (error) {
        setisLoading(false);
        await handleError(error, nav, dispatch);
      } finally {
        setisLoading(false);
      }
    };
    getAllTherapsit();
  }, []);

  return (
    <>
      <Main>
        {isLoading ? (
          <div className='h-full content-center'>
            <Spin tip='Loading' size='large'>
              <div
                style={{
                  borderRadius: 4,
                }}
              />
            </Spin>
          </div>
        ) : (
          <>
            <div className='pb-5 w-full mx-auto lg:px-10 bg-sky flex items-center'>
              <input
                className='w-full p-3 rounded-xl border-2 border-slate-200 focus:outline-none focus:border-gray-500 transition duration-300 ease-in-out'
                placeholder='Search therapists, Conditions, Speciality etc.'
                prefix={<SearchOutlined />}
              />
            </div>
            <div className='md:grid grid-cols-2 gap-4 pb-10 flex flex-wrap lg:gap-4 w-full lg:px-10'>
              {alltherapist?.map((therapist) => {
                return (
                  <div
                    className='w-full border-2 mb-0 md:lg:mb-0 border-slate-200 max-w-screen-lg p-6 bg-white rounded-xl'
                    key={therapist}
                  >
                    <div className='flex gap-4 wrap 4'>
                      <div className='col-span-1 flex flex-col items-center md:items-start'>
                        {therapist?.profilePictureUrl?.s3Url ? (
                          <img
                            className='w-24 h-24 rounded-2xl object-cover'
                            src={therapist?.profilePictureUrl?.s3Url}
                          ></img>
                        ) : (
                          <Avatar
                            size={80}
                            shape='circle'
                            icon={<UserOutlined />}
                          />
                        )}
                      </div>
                      <div className='col-span-3 gap-1 flex flex-col'>
                        <div className='text-xl lg:text-xl font-bold'>
                          {therapist.givenName} {therapist.familyName}
                          <span className='text-sm font-semibold text-gray-500 ml-5'>
                            {therapist?.experience
                              ? `${therapist.experience} Years Exp`
                              : ''}
                          </span>
                        </div>
                        <div className='text-gray-600 font-bold'>
                          {therapist?.businessName || ''}
                        </div>
                        <div className='text-md text-gray-600'>
                          <b>Specialty: </b>
                          {therapist?.specialty || ''}
                        </div>
                        {therapist?.profileHandle ? (
                          <button
                            className='bg-blue-700 w-fit mt-6 lg:mt-4 text-white px-4 py-2 rounded-lg hover:bg-blue-600'
                            onClick={() =>
                              nav(`/therapist/${therapist.profileHandle}`)
                            }
                          >
                            Book Appointment
                          </button>
                        ) : (
                          <button
                            className='bg-blue-700 w-fit mt-6 lg:mt-4 text-white px-4 py-2 rounded-lg hover:bg-blue-600'
                            onClick={() => nav(`/therapist/${therapist._id}`)}
                          >
                            Book Appointment
                          </button>
                        )}
                      </div>
                    </div>
                    <div className=''></div>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </Main>
      <Footer />
    </>
  );
}

export default Home;
