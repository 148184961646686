import { handleApiErrors } from "../ErrorHandling/handleApiErrors";

const fetchAllTherapists = async () => {
  const response = await fetch(
    `${process.env.REACT_APP_API_HOST}/api/therapist/getAllTherapistForCustomer`,
    {
      credentials: "include",
      method: "POST",
      withCredentials: true,
    }
  );
  if (!response.ok) {
    await handleApiErrors(response)
  }
  return await response.json();
};

const fetchTherapistById = async (therapistId) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_HOST}/api/therapist/getTherapistByIdForCustomer`,
    {
      credentials: "include",
      method: "POST",
      withCredentials: true,
      body: JSON.stringify({ therapistId: therapistId }),
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  if (!response.ok) {
    await handleApiErrors(response)
  }
  return await response.json();
};
const fetchTherapistByHandle = async (profileHandle) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_HOST}/api/therapist/getTherapistByProfileHandleForCustomer`,
    {
      credentials: "include",
      method: "POST",
      withCredentials: true,
      body: JSON.stringify({ profileHandle: profileHandle }),
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  if (!response.ok) {
    await handleApiErrors(response)
  }
  return await response.json();
};
export { fetchAllTherapists , fetchTherapistById, fetchTherapistByHandle };
