import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Main from "../components/layout/Main";
import { Spin, Table, Drawer, Button } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { getTherapistsOfLoggedInUser } from "../Network/Appointment";
import { handleError } from "../ErrorHandling/handleError";
import { getFormResponseForClient } from "../Network/IntakeForm";
import moment from "moment";

function MyResponse() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { therapistId, clientId } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const authorization = useSelector((state) => state.combined.auth.authorization);
    const [therapist, setTherapist] = useState(null);
    const [forms, setForms] = useState([]);
    const [isDrawerVisible, setIsDrawerVisible] = useState(false);
    const [selectedFormResponses, setSelectedFormResponses] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [limit, setLimit] = useState(10);

    const fetchTherapistName = async () => {
        try {
            const therapists = await getTherapistsOfLoggedInUser(authorization);
            const selectedTherapist = therapists.find((t) => t._id === therapistId);
            setTherapist(selectedTherapist);
        } catch (error) {
            handleError(error, navigate, dispatch);
            setIsLoading(false);
        }
    };

    const fetchFormData = async (page, limit) => {
        try {
            const data = {
                therapistId: therapistId,
                clientId: clientId,
                page: page,
                limit: limit,
            };
            const { formResponses, totalPages } = await getFormResponseForClient(authorization, data);
            setForms(formResponses);
            setTotalPages(totalPages);
        } catch (error) {
            handleError(error, navigate, dispatch);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const initializeData = async () => {
            await fetchTherapistName();
            await fetchFormData(1, limit);
            setIsLoading(false);
        };
        initializeData();
    }, [authorization, therapistId, clientId, limit]);

    const openDrawer = (formResponses) => {
        setSelectedFormResponses(formResponses);
        setIsDrawerVisible(true);
    };

    const closeDrawer = () => {
        setIsDrawerVisible(false);
        setSelectedFormResponses([]);
    };

    const columns = [
        {
            title: "Therapist Name",
            dataIndex: "therapistName",
            key: "therapistName",
        },
        {
            title: "Form Title",
            dataIndex: "formTitle",
            key: "formTitle",
        },
        {
            title: "Submitted At",
            dataIndex: "createdAt",
            key: "createdAt",
            render: (createdAt) => moment(createdAt).format('YYYY-MM-DD, h:mm a'),
        },
        {
            title: "Action",
            key: "action",
            render: (_, record) => (
                <Button icon={<EyeOutlined />} onClick={() => openDrawer(record.responses)}>
                    View Responses
                </Button>
            ),
        },
    ];

    const tableData = forms.map((form) => ({
        therapistName: form.therapistName ? form.therapistName : "N/A",
        createdAt: form.createdAt,
        key: form._id,
        formTitle: form.formTitle ? form.formTitle : "N/A",
        responses: form.responses,
    }));

    const handleTableChange = (pagination) => {
        const { current, pageSize } = pagination;
        setLimit(pageSize);
        fetchFormData(current, pageSize);
    };

    return (
        <Main>
            {isLoading ? (
                <div className="h-full flex justify-center items-center">
                    <Spin tip="Loading" size="large" />
                </div>
            ) : (
                <div className="layout-content flex flex-col w-96 md:w-9/12 h-152 m-auto relative overflow-x-hidden bg-white shadow-lg rounded-xl p-8">
                    <h3 className="text-2xl font-bold text-gray-800 mb-6">
                        Therapist Name: {therapist?.givenName} {therapist?.familyName}
                    </h3>
                    <div className="w-full overflow-x-auto">
                        <Table
                            columns={columns}
                            dataSource={tableData}
                            pagination={{
                                total: totalPages * limit,
                                pageSize: limit,
                                defaultCurrent: 1,
                            }}
                            onChange={handleTableChange}
                            className="w-full"
                        />
                    </div>
                    <Drawer
                        title="Form Responses"
                        placement="right"
                        onClose={closeDrawer}
                        open={isDrawerVisible}
                        width={400}
                    >
                        {selectedFormResponses.length > 0 ? (
                            selectedFormResponses.map((response, index) => (
                                <div key={index} className="mb-4 break-words">
                                    <p>
                                        <b>Question: </b> {response.label}
                                    </p>
                                    <p>
                                        <b>Answer: </b>{" "}
                                        {Array.isArray(response.response)
                                            ? response.response.join(", ")
                                            : response.response}
                                    </p>
                                </div>
                            ))
                        ) : (
                            <p>No responses available.</p>
                        )}
                    </Drawer>
                </div>
            )}
        </Main>
    );
}

export default MyResponse;
