import React from 'react';
import { therapists } from '../assets/data/LPData';
import { useNavigate } from 'react-router-dom';
const TherapistSection = () => {
  const navigate = useNavigate();
  const handleAboutClick = () => {
    navigate('/about');
  };
  return (
    <div className='mx-8 md:mx-12 py-10 pb-20 gap-6 flex flex-col md:flex-row justify-between items-center'>
      <div className='grid grid-cols-2 grid-rows-2 gap-3 relative w-full md:w-1/2'>
        {therapists.map((therapist, index) => (
          <div
            key={index}
            className={`relative ${therapist.bgColor} rounded-xl p-4 flex justify-center items-center`}
          >
            <img
              src={therapist.image}
              alt={`Therapist ${index + 1}`}
              className={`md:h-40 h-30 object-cover`}
            />
          </div>
        ))}
      </div>
      <div className='md:w-1/2 mt-10 md:mt-0 md:pl-12'>
        <h2 className='text-4xl libre-baskerville-bold mb-4 text-left'>
          Meet Our Therapists
        </h2>
        <p className='text-lg text-left mb-4'>
          Our therapists are licensed, experienced, and ready to help you
          overcome your mental health challenges. They specialize in a wide
          range of areas to support your needs.
        </p>
        <p className='text-lg text-left mb-6'>
          Whether you're looking for individual therapy, couples counseling, or
          medication management, you'll find compassionate care here.
        </p>
        <button
          onClick={handleAboutClick}
          className='bg-orange-600 text-white px-6 py-3 rounded-full hover:bg-orange-500 hover:text-white transition duration-300 inline-block'
        >
          Learn More
        </button>
      </div>
    </div>
  );
};

export default TherapistSection;
