import React, { useState } from 'react';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { faqData } from '../assets/data/LPData';

const FAQ = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div id='faq' className='mx-8 mt-12 md:mx-12 pt-4 pb-0'>
      <h2 className='text-2xl md:text-4xl font-bold text-left libre-baskerville-bold mb-12'>
        Frequently Asked Questions
      </h2>
      <div className='space-y-4'>
        {faqData.map((faq, index) => (
          <div key={faq.id} className='bg-gray-100 rounded-2xl'>
            <div
              className='flex justify-between items-center p-4 cursor-pointer hover:bg-gray-100 transition'
              onClick={() => handleToggle(index)}
            >
              <h3 className='text-lg p-0 m-0 font-semibold libre-baskerville-bold'>
                {faq.question}
              </h3>
              {openIndex === index ? (
                <MdKeyboardArrowUp className='text-xl text-gray-600' />
              ) : (
                <MdKeyboardArrowDown className='text-xl text-gray-600' />
              )}
            </div>
            <div
              className={`overflow-hidden transition-max-height duration-300 ease-in-out ${
                openIndex === index ? 'max-h-screen p-4' : 'max-h-0'
              }`}
            >
              <p className='text-sm md:text-base text-gray-700'>{faq.answer}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
