import React from 'react';
import HowItWorksImage from '../assets/images/session.jpg'; 
import { steps } from '../assets/data/LPData';

const HowItWorks = () => {
  return (
    <div className='px-8 md:px-12 bg-green-900 py-16 gap-6 flex flex-col md:flex-row justify-between items-center'>
      <div className='md:w-1/2'>
        <h2 className='text-4xl text-white libre-baskerville-bold mb-8 text-left'>
          How it Works
        </h2>

        {steps.map((step, index) => (
          <div key={index} className='mb-8'>
            <h3 className='text-xl libre-baskerville-bold text-white font-semibold text-left'>
              {step.title}
            </h3>
            <p className='text-lg text-gray-300 text-left mt-2'>{step.description}</p>
            <hr className='border-t-1 libre-baskerville-bold text-white border-gray-300 my-4' />
          </div>
        ))}
      </div>

      <div className='md:w-1/2 mt-4 md:mt-0 flex justify-center'>
        <img
          src={HowItWorksImage}
          alt='How it works illustration'
          className='w-full md:h-100 rounded-xl max-w-md'
        />
      </div>
    </div>
  );
};

export default HowItWorks;
