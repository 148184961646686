import React, { useState, useEffect, useRef } from 'react';
import io from 'socket.io-client';
import Main from '../components/layout/Main';
import { Spin, Modal } from 'antd';
import { PaperClipOutlined, CloseOutlined, FileOutlined, VideoCameraOutlined, SoundOutlined } from '@ant-design/icons';
import {
    createMediaConversation,
    createTextConversation,
    getConversations,
    getUnreadCount,
    markConversationRead,
} from '../Network/Conversations';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { handleError } from '../ErrorHandling/handleError';
import moment from 'moment';
import { getTherapistsOfLoggedInUser } from '../Network/Appointment';
import { fetchUserDetails } from '../Network/Client';
import { socket } from '../components/layout/Sidenav';
import { setUpdateCount } from '../redux/combinedSlice';

function Conversations() {
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [conversations, setConversations] = useState([]);
    const [selectedFile, setSelectedFile] = useState();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedMedia, setSelectedMedia] = useState(null);
    const [client, setClient] = useState(null);
    const [clientId, setClientId] = useState(null);
    const [therapist, setTherapist] = useState(null);
    const [convserSationId, setConversationId] = useState('')
    const authorization = useSelector((state) => state.combined.auth.authorization);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const messagesEndRef = useRef(null);
    const fileInputRef = useRef(null);
    const { therapistId } = useParams();

    const scrollToBottom = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => {
        const loadConversations = async () => {
            setIsLoading(true);
            try {
                const res = await getConversations(therapistId, authorization);
                const loggedInClientDetails = await fetchUserDetails(authorization);
                setClient(loggedInClientDetails);
                setClientId(loggedInClientDetails?._id)
                setConversations(res?.msgs || []);
                setConversationId(`${res?._id}`)
                socket.emit('joinroom', { conversationId: `${res?._id}`, therapistId, authorization });
                socket.emit("updateCountRoom", {id: therapistId})
            } catch (error) {
                handleError(error, navigate, dispatch);
            } finally {
                setIsLoading(false);
            }
        };

        const fetchTherapist = async () => {
            try {
                const therapists = await getTherapistsOfLoggedInUser(authorization);
                const selectedTherapist = therapists.find(t => t._id === therapistId);
                setTherapist(selectedTherapist);
            } catch (error) {
                handleError(error, navigate, dispatch);
            }
        };
        fetchTherapist();
        loadConversations();
        scrollToBottom();
    }, [therapistId, authorization]);

    useEffect(() => {
        const timer = setTimeout(() => {
            scrollToBottom();
        }, 100);
        return () => clearTimeout(timer);
    }, [conversations]);
    useEffect(() => {
        const fetchData = async () => {
          try {
            await markConversationRead(therapistId, authorization);
            const res = await getUnreadCount(authorization);
            dispatch(setUpdateCount(res));
          } catch (error) {
            handleError(error, navigate, dispatch);
          }
        };
      
        fetchData();
      }, [conversations]); 

    useEffect(() => {
        socket.on('newMessage', (message) => {
            markConversationRead(therapistId, authorization);
            setConversations(prevConversations => [...prevConversations, message]);
            scrollToBottom();
        });

        socket.on('newMedia', (media) => {
            markConversationRead(therapistId, authorization);
            setConversations(prevConversations => [...prevConversations, media]);
            scrollToBottom();
        });

        return () => {
            socket.off('newMessage');
            socket.off('newMedia');
        };
    }, [socket]);


    // useEffect(() => {
    //     if (client?._id && therapistId) {
    //         socket.emit('joinroom', { conversationId: `${therapistId}-${client._id}` });
    //         console.log(`Joined room: ${therapistId}-${client._id}`);
    //     }
    // }, [client, therapistId, socket]);

    const handleSendMessage = async () => {
        try {
            if (!message.trim() && !selectedFile) return;
            if (selectedFile) {
                const file = selectedFile;
                const formData = new FormData();
                formData.append('media', file);
                formData.append('recieverId', therapistId);
                formData.append('senderId', clientId)
                const mediaresponse = await createMediaConversation(formData, authorization);
                socket.emit('updateCount', {id: therapistId, count:true})
                socket.emit('newMedia', { mediaresponse, convserSationId, senderId: clientId, recieverId: therapistId, authorization });
                setSelectedFile(null);
                if (fileInputRef.current) {
                    fileInputRef.current.value = null;
                }
            } else {
                await createTextConversation(message, therapistId, authorization);
                socket.emit('updateCount', {id: therapistId, count:true})
                socket.emit('newMessage', { message, senderId: clientId, recieverId: therapistId, convserSationId, authorization });
                setMessage('');
            }

            const updatedConversations = await getConversations(therapistId, authorization);
            setConversations(updatedConversations?.msgs || []);
        } catch (error) {
            handleError(error, navigate, dispatch);
        }
    };


    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    };

    const handleAttachmentClick = () => {
        document.getElementById('fileInput').click();
    };

    const handleRemoveImage = () => {
        setSelectedFile(null);
        if (fileInputRef.current) {
            fileInputRef.current.value = null;
        }
    };

    const handleMediaClick = (media) => {
        setSelectedMedia(media);
        setIsModalVisible(true);
    };

    const handleModalClose = () => {
        setIsModalVisible(false);
        setSelectedMedia(null);
    };

    return (
        <Main>
            {isLoading ? (
                <div className="h-full content-center">
                    <Spin tip="Loading" size="large">
                        <div style={{ borderRadius: 4 }} />
                    </Spin>
                </div>
            ) : (
                <div className="layout-content flex flex-col w-96 md:w-9/12 h-152 m-auto relative overflow-x-hidden bg-white shadow-lg rounded-xl p-8">
                    <div className="flex flex-col items-start gap-6 w-full">
                        <span className="text-black items-center font-semibold text-4xl mr-6">
                            Conversations
                        </span>
                        <div className="flex flex-col gap-4 w-full h-96 overflow-y-auto border border-gray-300 rounded-lg p-4 bg-gray-50">
                            {conversations.length <= 0 ? (
                                <div className="text-center text-gray-500">
                                    No conversations yet, please send a message to start.
                                </div>
                            ) : (conversations.map((conv, index) => (
                                <div
                                    key={index}
                                    className={`flex ${conv.senderId === client?._id ? 'justify-end' : 'justify-start'}`}
                                >
                                    <div
                                        className={`p-3 flex gap-4 max-w-xs rounded-lg ${conv.senderId === client?._id ? 'bg-green-100 text-justify' : 'bg-blue-100 text-justify'
                                            }`}
                                    >
                                        <div>
                                            <p>
                                                <p className="text-xs text-gray-400">
                                                    {moment(conv.createdAt).format('MMM D, h:mm A')}
                                                </p>
                                                <strong>{conv?.senderId === client?._id ? `${client.givenName}: ` : `${therapist.givenName}: `}</strong> {conv.text}
                                            </p>
                                        </div>
                                        {conv.media && conv.media.map((mediaItem, idx) => (
                                            <div key={idx} className="mt-2">
                                                {mediaItem.mediaType.startsWith("image/") && (
                                                    <img
                                                        src={mediaItem.s3Url}
                                                        alt={mediaItem.name}
                                                        className="w-16 h-16 cursor-pointer"
                                                        onClick={() => handleMediaClick(mediaItem)}
                                                    />
                                                )}
                                                {mediaItem.mediaType.startsWith("video/") && (
                                                    <div
                                                        className="w-16 h-16 cursor-pointer flex items-center justify-center bg-gray-200"
                                                        onClick={() => handleMediaClick(mediaItem)}
                                                    >
                                                        <VideoCameraOutlined className="text-xl text-gray-600" />
                                                    </div>
                                                )}
                                                {mediaItem.mediaType.startsWith("audio/") && (
                                                    <div
                                                        className="w-16 h-16 cursor-pointer flex items-center justify-center bg-gray-200"
                                                        onClick={() => handleMediaClick(mediaItem)}
                                                    >
                                                        <SoundOutlined className="text-xl text-gray-600" />
                                                    </div>
                                                )}
                                                {mediaItem.mediaType === "application/pdf" && (
                                                    <div
                                                        className="w-16 h-16 cursor-pointer flex items-center justify-center bg-gray-200"
                                                        onClick={() => handleMediaClick(mediaItem)}
                                                    >
                                                        <FileOutlined className="text-xl text-gray-600" />
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )))}
                            <div ref={messagesEndRef} />
                        </div>
                        <div className="flex items-center w-full mt-auto">
                            <input
                                id="fileInput"
                                type="file"
                                ref={fileInputRef}
                                style={{ display: 'none' }}
                                onChange={handleFileChange}
                            />
                            <button
                                className="text-gray-500 hover:text-gray-700 focus:outline-none mr-2"
                                onClick={handleAttachmentClick}
                            >
                                <PaperClipOutlined className="text-xl" />
                            </button>
                            <div className="flex-grow relative">
                                {selectedFile && (
                                    <div className="absolute top-0 left-0 w-full h-full flex items-center justify-between p-2 border border-gray-300 rounded-lg mb-2 bg-gray-100">
                                        <span className="truncate">{selectedFile.name}</span>
                                        <button
                                            className="text-red-500 hover:text-red-700 focus:outline-none ml-2"
                                            onClick={handleRemoveImage}
                                        >
                                            <CloseOutlined />
                                        </button>
                                    </div>
                                )}
                                <input
                                    type="text"
                                    className="w-full p-3 border border-gray-300 rounded-l-lg focus:outline-none focus:border-blue-500"
                                    placeholder="Type a message..."
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    onKeyDown={(e) => e.key === 'Enter' && handleSendMessage()}
                                />
                            </div>

                            <button
                                className="bg-blue-500 text-white px-6 py-3 rounded-r-lg hover:bg-blue-600 focus:outline-none"
                                onClick={handleSendMessage}
                            >
                                Send
                            </button>
                        </div>
                    </div>
                    <Modal
                        visible={isModalVisible}
                        footer={null}
                        onCancel={handleModalClose}
                        width={800}
                        bodyStyle={{ padding: 0 }}
                    >
                        {selectedMedia && (
                            <div className="flex justify-center items-center h-full p-4">
                                {selectedMedia.mediaType.startsWith("image/") && (
                                    <img
                                        src={selectedMedia.s3Url}
                                        alt={selectedMedia.name}
                                        className="w-full h-full"
                                    />
                                )}
                                {selectedMedia.mediaType.startsWith("video/") && (
                                    <video className="w-full h-auto" controls>
                                        <source
                                            src={selectedMedia.s3Url}
                                            type={selectedMedia.mediaType}
                                        />
                                    </video>
                                )}
                                {selectedMedia.mediaType.startsWith("audio/") && (
                                    <audio className="w-full" controls>
                                        <source
                                            src={selectedMedia.s3Url}
                                            type={selectedMedia.mediaType}
                                        />
                                        Your browser does not support the audio element.
                                    </audio>
                                )}
                                {selectedMedia.mediaType === "application/pdf" && (
                                    <iframe
                                        src={selectedMedia.s3Url}
                                        className="w-full h-96"
                                        title={selectedMedia.name}
                                    />
                                )}
                                {selectedMedia.mediaType === "text/plain" && (
                                    <pre className="w-full h-96 overflow-auto p-4 bg-gray-100 text-gray-800">
                                        <iframe
                                            src={selectedMedia.s3Url}
                                            className="w-full h-96"
                                            title={selectedMedia.name}
                                        />
                                    </pre>
                                )}
                            </div>
                        )}
                    </Modal>
                </div>
            )}
        </Main>
    );
}

export default Conversations;
