import React from 'react';
import { Carousel } from 'antd';
import { featuresData } from '../assets/data/LPData';

const FeatureCard = ({ title, description, image, bgColor, imageHeight }) => {
  return (
    <div
      className={`flex md:mx-2 mx-3 h-9/12 flex-col md:flex-row justify-between items-center p-6 px-4 ${bgColor} rounded-2xl`}
    >
      <div className='text-left'>
        <h3 className='libre-baskerville-bold text-xl mb-1 font-bold text-gray-800'>
          {title}
        </h3>
        <p className='text-black md:text-[12px] mt-2 text-sm mb-4'>
          {description}
        </p>
      </div>
      <img
        src={image}
        className={`h-${imageHeight} md:self-end self-center mt-0 md:mt-0 md:ml-4`}
        alt={title.toLowerCase()}
      />
    </div>
  );
};
const FeaturesCarousel = () => {
  return (
    <div className='h-full'>
      <Carousel
        autoplay
        autoplaySpeed={3000}
        dots={false}
        infinite
        slidesToShow={3}
        slidesToScroll={1}
        responsive={[
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
            },
          },
        ]}
        className='h-full'
      >
        {featuresData.map((feature, index) => (
          <FeatureCard
            key={index}
            title={feature.title}
            description={feature.description}
            image={feature.image}
            bgColor={feature.bgColor}
            imageHeight={feature.imageHeight}
          />
        ))}
      </Carousel>
    </div>
  );
};

export default FeaturesCarousel;
